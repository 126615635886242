@charset "UTF-8";
@import url("../vendors/fontawesome-pro/css/all.css");
@media (max-width: 479px) {
  .heading,
  .hp-navigation__box--content h2 {
    font-size: 25px;
  }
}

@media (max-width: 991px) {
  .heading-secondary h2 {
    font-size: 27px;
  }
}

.header__balance--top span,
.heading-modal,
.modal--change-fourth .modal__text h1,
.modal--change .modal__text p,
.remodal h2 {
  font-size: 20px;
}

.footer-mobile__item--count span,
.text-count {
  font-size: 12px;
}

.btn {
  border: 2px solid transparent;
  background: transparent;
  color: var(--iq-black);
  display: inline-block;
  position: relative;
  border-radius: 5px;
  padding: 15px 20px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform-origin: center top;
  transform-origin: center top;
  overflow: hidden;
  text-transform: uppercase;
}

.btn:focus {
  box-shadow: none;
}

.btn:disabled,
.btn:disabled:hover {
  background-color: var(--iq-dullgrey) !important;
  border: none;
}

.btn:disabled:hover {
  border-color: var(--iq-black);
}

.btn.disabled,
.btn.disabled:hover {
  background-color: var(--iq-dullgrey) !important;
  border: none;
}

.btn.disabled:hover {
  border-color: var(--iq-black);
}

.btn--primary {
  border-color: transparent;
  background-color: var(--iq-pink) !important;
  padding: 15px 20px 12px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 48px;
  color: var(--iq-white);
}

.btn--dark {
  border-color: transparent;
  background-color: var(--iq-pink);
  padding: 15px 5px 12px;
  color: var(--iq-white);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 34px;
}

.btn--yellow {
  border-color: transparent;
  background-color: var(--iq-yellow);
  padding: 15px 5px 12px;
  color: var(--iq-black);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 34px;
}

.btn--blue {
  border: 2px solid var(--iq-orange);
  background-color: transparent;
  padding: 15px 5px 12px;
  color: var(--iq-pink);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: -webkit-box;
  display: flex;
  color: var(--iq-white);
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 34px;
}

.btn--modal {
  text-transform: uppercase;
}

.btn--secondary {
  border-color: transparent;

  padding: 15px 15px 12px;
  color: var(--iq-pink);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 48px;
}

.btn--arrow {
  overflow: hidden;
}

.btn--arrow i {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 1;
}

.btn--arrow:hover i {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
  opacity: 0;
}

.btn--secondary-border {
  background-color: transparent;
  padding: 15px 20px 12px;
  color: var(--iq-orange);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 48px;
}

.btn--terciary {
  border-color: transparent;
  padding: 15px 20px 12px;
  color: var(--iq-white);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 40px;
}

.btn--white-border {
  border-color: var(--iq-white);
  background-color: transparent;
  padding: 15px 20px 12px;
  color: var(--iq-white);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 48px;
}

.btn--white-border:hover {
  border-color: var(--iq-yellow);
  color: var(--iq-yellow);
}

.btn--white {
  border-color: var(--iq-white);
  background-color: var(--iq-white);
  padding: 15px 20px 12px;
  color: var(--iq-pink);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 48px;
  border-radius: 15px;
}

.btn--filebtn {
  position: relative;
  cursor: pointer;
  width: 200px;
  height: 48px;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 479px) {
  .btn--filebtn {
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 575px) {
  .grecaptcha-badge {
    bottom: 64px !important;
    box-shadow: none !important;
  }
}

.btn--filebtn:hover:before {
  border-color: var(--iq-black);
}

.btn--filebtn:before {
  width: 200px;
  height: 48px;
  font-size: 17px;
  line-height: 32px;
  display: inline-block;
  background: var(--iq-light-pink) !important;
  border: 2px solid transparent;
  padding: 8px 10px;
  text-align: center;
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: attr(data-title);
}

@media (max-width: 479px) {
  .btn--filebtn:before {
    width: 100%;
    max-width: 100%;
  }
}

.btn--filebtn input[type="file"] {
  position: absolute;
  right: 0;
  opacity: 0;
}

@media (max-width: 479px) {
  .btn--filebtn input[type="file"] {
    width: 100%;
    max-width: 100%;
  }
}

.page-rtl .btn--dark {
  padding: 15px 5px;
  color: var(--iq-white);
}

body {
  -webkit-font-smoothing: antialiased;
}

body.menu_lock {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
}

html {
  background-color: var(--iq-white);
  scroll-behavior: smooth;
}

p {
  margin-bottom: 0;
}

a {
  color: var(--iq-black);
}

a:hover {
  text-decoration: unset;
  color: unset;
}

* {
  outline: none;
}

.cookie__bar {
  display: block;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1266px;
  }
}

@media (max-width: 575px) {
  .dev_be_call_log_block,
  .sm-d-none-iq {
    display: none;
  }
}

#paymentiframe {
  border: none;
}

@media (max-width: 575px) {
  .main {
    padding-top: 90px;
  }
}

.main.blank {
  padding-top: 0;
}

.error-text p {
  font-size: 14px;
  color: var(--iq-darkred) !important;
  margin-top: 10px;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 28px;
  height: 28px;
  -webkit-box-flex: 0;
  flex: 0 0 28px;
  border-radius: 0;
  background-color: var(--iq-white);
  border: 1px solid var(--iq-dullgrey);
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
}

input[type="checkbox"]:after {
  content: "";
  position: absolute;
  left: 6px;
  top: 6px;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  background-color: var(--iq-orange);
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

input[type="checkbox"]:checked:after {
  opacity: 1;
}

input:not([type="checkbox"]):not([type="button"]):not([type="submit"]):not(
    [type="file"]
  ) {
  width: 100%;
  height: 50px;
  border: 1px solid var(--iq-orange);
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: 0 0;
  box-shadow: none;
  background-color: var(--iq-white);
  padding: 20px 20px 15px;
  font-size: 17px;

  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input:not([type="checkbox"]):not([type="button"]):not([type="submit"]):not(
    [type="file"]
  )
  :focus,
input:not([type="checkbox"]):not([type="button"]):not([type="submit"]):not(
    [type="file"]
  )
  :hover {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type="file"] {
  cursor: pointer;
  width: 200px;
  height: 48px;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

input[type="file"]:hover:before {
  border-color: var(--iq-black);
}

input[type="file"]:before {
  width: 200px;
  height: 48px;
  font-size: 17px;
  line-height: 32px;
  display: inline-block;
  background: #fce300;
  border: 2px solid transparent;
  padding: 8px 10px;
  text-align: center;
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}

textarea {
  color: var(--iq-darkgrey);
  width: 100%;
  height: 163px;
  resize: none;
  border: 1px solid var(--iq-dullgrey);
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--iq-white);
  box-shadow: none;
  padding: 20px;
  font-size: 17px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

textarea::-webkit-input-placeholder,
textarea::-moz-placeholder,
textarea:-ms-input-placeholder,
textarea::-ms-input-placeholder,
textarea::placeholder {
  color: var(--iq-darkgrey);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type="number"]:focus,
input[type="number"]:hover {
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
}

button,
input,
select,
textarea {
  outline: none;
}

button:focus {
  outline: 0;
}

#milvid > div {
  height: 215px !important;
}
lite-youtube {
  max-width: 100% !important;
}
@media (max-width: 1200px) {
  #milvid > div {
    height: auto !important;
  }
}

.footer {
  background-color: var(--iq-brown);
  position: relative;
}

.footer__nav {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 30px 0px;
  border-bottom: 1px solid var(--iq-pink);
}

@media (max-width: 991px) {
  .footer__nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

@media (max-width: 575px) {
  .footer__nav {
    padding: 15px 0 2px;
  }

  .footer__socials--platforms a img {
    height: 25px !important;
    width: 25px !important;
  }
}

@media (max-width: 991px) {
  .footer__nav--img {
    margin: 0 0 20px;
  }
}

@media (max-width: 991px) {
  .footer__nav--text {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    text-align: center;
    margin-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .footer__nav--text.desktop {
    display: none;
  }
}

.footer__nav--text.mobile {
  margin-bottom: 0;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid var(--iq-pink);
}

@media (min-width: 576px) {
  .footer__nav--text.mobile {
    display: none;
  }
}

.footer__nav--text.mobile ul {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.footer__nav--text.mobile ul li:not(:last-child) {
  margin: 0 0 20px;
}

@media (max-width: 576px) {
  .footer__nav--text.mobile ul li:not(:last-child),
  .page-rtl .footer__nav--text.mobile ul li {
    margin: 0 20px 10px 0;
    position: relative;
  }

  .footer__nav--text.mobile ul li:not(:last-child):after,
  .page-rtl .footer__nav--text.mobile ul li:after {
    content: "";
    position: absolute;
    right: -10px;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: var(--iq-white);
  }

  .page-rtl .footer__nav--text.mobile ul li:first-child:after {
    display: none;
  }

  .page-rtl .footer__nav--text.mobile ul li:first-child {
    margin: 0;
  }
}

@media (max-width: 575px) {
  .footer__nav--text.mobile ul li a {
    line-height: 1;
    font-size: 12px !important;
  }
}

@media (max-width: 575px) {
  .footer__nav--text.mobile ul {
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-align: start;
    align-items: flex-start;
  }
}

.footer__nav--text ul {
  display: -webkit-box;
  display: flex;
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  margin-bottom: 0;
  list-style-type: none;
}

@media (max-width: 991px) {
  .footer__nav--text ul {
    -webkit-box-pack: center;
    justify-content: center;
  }
}

.footer__nav--text ul li {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.footer__nav--text ul li:not(:last-child) {
  margin-right: 22px;
}

.footer__nav--text ul li:hover a {
  color: var(--iq-orange);
}

.footer__nav--text ul li:hover a:after {
  opacity: 1;
}

@media (max-width: 575px) {
  .footer__nav--text ul li:hover a:after {
    opacity: 0;
  }
}

.footer__nav--text ul li a {
  color: var(--iq-white);
  text-transform: uppercase;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
}

.footer__socials {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

@media (max-width: 991px) {
  .footer__socials {
    -webkit-box-pack: center;
    justify-content: center;
  }
}

@media (max-width: 575px) {
  .footer__socials {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse;
  }
}

.footer__socials p {
  text-transform: uppercase;
  color: var(--iq-white);
  margin-right: 20px;
}
.footer__socials p:last-child {
  margin-right: 0;
}
.page-rtl .footer__socials p:last-child {
  margin-left: 0;
}

@media (max-width: 575px) {
  .footer__socials p {
    display: block;
    margin: 0 0 8px;
  }
}

.footer__socials--item {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

@media (max-width: 575px) {
  .footer__socials--item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

@media (max-width: 991px) {
  .footer__socials--item:first-of-type {
    margin-right: 15px;
  }
}

@media (max-width: 575px) {
  .footer__socials--item:first-of-type {
    margin-top: 5px;
    margin-right: 0;
  }
}

.footer__socials--platforms,
.footer__socials--platforms a {
  display: -webkit-box;
  display: flex;
}

.footer__socials--platforms a {
  width: 35px;
  height: 35px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: var(--iq-white);
}

.footer__socials--platforms p:not(:last-child) {
  margin-right: 15px;
}

.footer__socials--platforms a i {
  color: var(--iq-white);
  font-size: 19px;
}

.footer__socials--platforms img {
  height: 19px;
}

.footer__bottom {
  padding: 30px 0;
  text-align: center;
}

@media (max-width: 767px) {
  .footer__bottom {
    padding: 30px 0;
  }
}

@media (max-width: 575px) {
  .footer__bottom {
    padding: 10px 0 5px;
  }
}

.footer__bottom p,
.footer__bottom p span {
  color: var(--iq-white);
  text-transform: uppercase;
  font-size: 17px;
}

@media (max-width: 575px) {
  .footer__bottom p,
  .footer__bottom p span {
    font-size: 12px;
  }
}

.remodal {
  padding: 75px 60px 60px;
  position: relative;
  border-radius: 5px;
}

@media only screen and (min-width: 641px) {
  .remodal {
    max-width: 541px;
  }
}

@media (max-width: 541px) {
  .remodal {
    padding: 55px 25px 25px;
  }
}

.remodal h2 {
  color: var(--iq-darkblack);
  margin-bottom: 30px;
}

@media (max-width: 575px) {
  .remodal h2 {
    font-size: 16px;
  }
}

.remodal .remodal-close {
  width: 50px;
  height: 50px;
  border: none;
  color: var(--iq-darkgrey);
  margin-bottom: 0;
  left: unset;
  right: 0;
  z-index: 3;
}

.remodal .remodal-close:hover i {
  color: var(--iq-orange);
}

.remodal .remodal-close i {
  font-size: 20px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.remodal .remodal-close:before {
  content: unset;
}

.remodal form .form-group {
  margin-bottom: 18px;
  text-align: left;
}

.remodal form .form-group label {
  line-height: normal;
  margin-bottom: 15px;
}

.remodal form .form-group label sup {
  color: #b61414;
}

.remodal form .form-group__buttons {
  display: -webkit-box;
  display: flex;
  margin-bottom: 0;
}

.remodal form .form-group__buttons .btn {
  width: 100%;
  margin-bottom: 0;
  height: 51px;
}

.remodal form .form-group__buttons .btn:first-child {
  margin-right: 10px;
}

.remodal form .form-group__buttons .btn--modal {
  margin-right: 0 !important;
  text-transform: uppercase;

  font-size: 20px;
  border-radius: 15px;
  height: 40px;
}

@media (max-width: 479px) {
  .remodal form .form-group__buttons .btn--modal {
    font-size: 18px;
    height: 30px;
  }
}

.remodal form .form-group__buttons a {
  text-decoration: none;
}

.remodal button {
  height: 48px;
  width: 100%;
}

.remodal textarea {
  margin-bottom: 27px;
}

.remodal p {
  color: var(--iq-black);
  font-size: 14px;
  margin-bottom: 0;
}

.remodal a {
  text-decoration: underline;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.remodal__numberlist {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

@media (max-width: 575px) {
  .remodal__numberlist {
    flex-wrap: nowrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.remodal__numberlist--number {
  height: 48px;
  max-width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-flex: 0;
  flex: 0 0 48%;
  width: 48%;
  background-color: #f6f6f6;
  border: 1px solid var(--iq-pink);
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 575px) {
  .remodal__numberlist--number {
    width: 100%;
    -webkit-box-flex: 0;
    flex: none;
    margin-right: 0;
  }
}

.remodal__numberlist--number.active {
  background-color: var(--iq-pink);
}

.remodal__numberlist--number.active p {
  color: var(--iq-white);
}

.remodal__numberlist--number:hover {
  background-color: var(--iq-pink);
}
.remodal__numberlist--number:hover p {
  color: var(--iq-white);
}

.remodal__numberlist--number:nth-child(2n) {
  margin-right: 0;
}

.remodal__numberlist--number:not(:last-child) {
  margin-bottom: 10px;
}

.remodal__numberlist--number p {
  color: var(--iq-darkblack);
  font-size: 14px;
  padding: 0 20px;
  margin-top: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.modal__heading.disabled {
  display: none;
}

.modal__img-big {
  max-width: 350px;
}

@media (max-width: 640px) {
  .modal__img-big {
    margin-bottom: 25px;
  }
}

@media (max-width: 479px) {
  .modal__img-big {
    max-width: 200px;
    margin-bottom: 20px;
  }
}

.modal__img-big img {
  max-width: 100%;
}

.modal--fav-numbers {
  padding-top: 70px;
}

.modal--fav-numbers .modal__heading {
  margin-bottom: 42px;
}

@media (max-width: 479px) {
  .modal--fav-numbers .modal__heading {
    margin-bottom: 20px;
  }
}

.modal--manage-account {
  padding: 50px 25px 60px;
}

@media only screen and (min-width: 641px) {
  .modal--manage-account {
    max-width: 633px;
  }
}

.modal--manage-account .modal__img {
  max-width: 64px;
  margin: 0 auto 18px;
}

.modal--manage-account .modal__img img {
  max-width: 100%;
}

.modal--manage-account h2 {
  margin-bottom: 12px;
}

.modal--manage-account p {
  color: var(--iq-darkgrey);
  margin-bottom: 25px;
}

.modal--manage-account form {
  max-width: 440px;
  margin: 0 auto;
}

@media only screen and (min-width: 641px) {
  .modal--favourite-numbers {
    max-width: 600px;
  }
}

.modal--favourite-numbers .modal__img {
  max-width: 92px;
  margin: 0 auto 20px;
}

.modal--favourite-numbers .modal__img img {
  max-width: 100%;
}

.modal--favourite-numbers a {
  width: 215px;
  max-width: 100%;
  margin: 0 auto;
  text-decoration: none;
}

.modal--verification {
  padding: 100px 30px 57px;
}

@media only screen and (min-width: 641px) {
  .modal--verification {
    max-width: 695px;
  }
}

@media (max-width: 575px) {
  .modal--verification {
    padding: 60px 23px 25px;
  }
}

.modal--verification p {
  text-align: center;
}

.modal--verification p:nth-child(2) {
  font-size: 17px;
  line-height: 30px;
  color: var(--iq-darkblack);
  margin-bottom: 15px;
}

@media (max-width: 575px) {
  .modal--verification p:nth-child(2) {
    font-size: 14px;
  }
}

.modal--verification p:nth-child(3) {
  font-size: 13px;
  line-height: 24px;
  color: var(--iq-darkgrey);
  margin-bottom: 30px;
}

@media (max-width: 575px) {
  .modal--verification p:nth-child(3) {
    font-size: 13px;
    margin-bottom: 20px;
  }
}

.modal--verification form .form-group__buttons {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}

.modal--verification form .form-group__buttons a:hover {
  color: var(--iq-black);
}

.modal--verification form .form-group__buttons .btn--filebtn {
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
  height: 51px;
}

.modal--verification form .form-group__buttons .btn--filebtn:before {
  width: 100%;
  max-width: 100%;
  height: 51px;
}

.modal--verification
  form
  .form-group__buttons
  .btn--filebtn
  input[type="file"] {
  width: 100%;
  max-width: 100%;
}

.modal--rules {
  padding: 75px 120px 60px;
}

@media (max-width: 541px) {
  .modal--rules {
    padding: 55px 25px 25px;
  }
}

@media only screen and (min-width: 641px) {
  .modal--rules {
    max-width: 695px;
  }
}

.modal--rules .modal__heading {
  margin-bottom: 20px;
}

.modal--rules p {
  color: var(--iq-darkgrey);
  line-height: 30px;
  margin-bottom: 20px;
}

.modal--rules p a {
  color: var(--iq-pink);
  text-decoration: none;
}

.modal--rules .btn {
  margin-right: 0;
}

.modal--withdrawal {
  padding: 50px 25px 55px;
}

@media only screen and (min-width: 633px) {
  .modal--withdrawal {
    max-width: 633px;
  }
}

@media (max-width: 479px) {
  .modal--withdrawal {
    padding: 50px 25px 25px;
  }
}

.modal--withdrawal .modal__img {
  max-width: 64px;
  margin: 0 auto 18px;
}

.modal--withdrawal .modal__img img {
  max-width: 100%;
}

.modal--withdrawal p {
  color: var(--iq-darkgrey);
  margin-bottom: 25px;
}

.modal--withdrawal .form-group__buttons {
  max-width: 435px;
  margin: 0 auto;
}

@media (max-width: 479px) {
  .modal--withdrawal .form-group__buttons {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

@media (max-width: 479px) {
  .modal--withdrawal .form-group__buttons .btn:first-child {
    margin-right: 0;
    margin-bottom: 5px;
  }
}

@media only screen and (min-width: 641px) {
  .modal--change {
    max-width: 800px;
  }
}

.modal--change .form-group__buttons a {
  text-decoration: none;
}

.modal--change .modal__inner {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

@media (max-width: 640px) {
  .modal--change .modal__inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
  }
}

.modal--change .modal__img {
  max-width: 250px;
}

@media (max-width: 640px) {
  .modal--change .modal__img {
    margin-bottom: 25px;
  }
}

@media (max-width: 479px) {
  .modal--change .modal__img {
    max-width: 200px;
    margin-bottom: 20px;
  }
}

.modal--change .modal__img img {
  max-width: 100%;
}

.modal--change .modal__text {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  max-width: 385px;
}

.modal--change .modal__text p {
  color: var(--iq-pink);
  font-size: 26px;
  line-height: 34px;
  margin-bottom: 25px;
}

@media (max-width: 479px) {
  .modal--change .modal__text p {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 641px) {
  .modal--change-secondary {
    max-width: 1000px;
  }
}

.modal--change-secondary .modal__text {
  max-width: 545px;
}

@media (max-width: 479px) {
  .modal--change-secondary .modal__text p {
    font-size: 13px;
    line-height: 16px;
  }
}

.modal--change-secondary .form-group__buttons {
  width: 230px;
  max-width: 100%;
}

@media (max-width: 479px) {
  .modal--change-secondary .form-group__buttons {
    width: 155px;
  }
}

.modal--change-secondary .form-group__buttons button {
  border-radius: 10px;
}

@media (max-width: 479px) {
  .modal--change-secondary .form-group__buttons button {
    height: 33px;
  }
}

.modal--change-terciary .modal__text p {
  font-size: 35px;
  line-height: 47px;
}

@media (max-width: 479px) {
  .modal--change-terciary .modal__text p {
    font-size: 20px;
    line-height: 28px;
  }
}

.modal--change-terciary .modal__text form {
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}

.modal--change-terciary .modal__text form .form-group__buttons {
  width: 220px;
}

@media (max-width: 479px) {
  .modal--change-terciary .modal__text form .form-group__buttons {
    width: 170px;
  }
}

.modal--change-terciary .modal__text form .form-group__buttons .btn--modal {
  font-size: 30px;
  height: 48px;
  padding: 20px 20px 12px;
}

@media (max-width: 480px) {
  .modal--change-terciary .modal__text form .form-group__buttons .btn--modal {
    font-size: 20px;
    height: 35px;
    padding: 18px 20px 12px;
  }
}

.modal--change-fourth .modal__text {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  text-align: left;
}

.modal--change-fourth .modal__text h1 {
  font-size: 35px;
  line-height: unset;
  margin-bottom: 0;
  color: var(--iq-pink);
}

@media (max-width: 479px) {
  .modal--change-fourth .modal__text h1 {
    font-size: 20px;
    line-height: 28px;
  }
}

.modal--change-fourth .modal__text p {
  font-size: 17px;
}

@media (max-width: 479px) {
  .modal--change-fourth .modal__text p {
    font-size: 12px;
  }
}

.modal--change-fourth .modal__text p:nth-child(2) {
  margin-bottom: 0;
}

.modal--change-fourth .modal__text ul {
  padding-left: 17px;
  max-width: 345px;
}

@media (max-width: 479px) {
  .modal--change-fourth .modal__text ul {
    padding-left: 14px;
  }
}

.modal--change-fourth .modal__text ul li {
  font-size: 17px;
  color: var(--iq-pink);
}

@media (max-width: 479px) {
  .modal--change-fourth .modal__text ul li {
    font-size: 13px;
  }
}

.modal--change-fourth .modal__text .form-group__buttons a {
  width: 195px;
  border-radius: 10px;
  height: 45px;
}

.modal--change-fourth .modal__text .form-group__buttons p {
  color: var(--iq-black);
  font-size: 14px;
}

.modal--find {
  padding: 40px 30px 25px;
}

@media only screen and (min-width: 641px) {
  .modal--find {
    max-width: 620px;
  }
}

.modal--find iframe {
  max-width: 100%;
}
.modal--numberpicker {
  padding: 25px 20px;
  border-radius: 15px;
  border: 1px solid var(--iq-pink);
  max-width: 625px;
}

@media (max-width: 479px) {
  .modal--numberpicker {
    padding: 20px 10px;
  }
}

.modal--numberpicker .modal__heading {
  color: #3f3f3f;
  margin-bottom: 20px;
}

.modal--numberpicker .modal__numberpicker--numberpicker {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  margin-bottom: 30px;
}

@media (max-width: 479px) {
  .modal--numberpicker .modal__numberpicker--numberpicker {
    margin-bottom: 10px;
  }
}

.modal--numberpicker .modal__numberpicker--numberpicker .number-picker__picker {
  margin: 0 0 10px;
  width: 475px;
}

.modal--numberpicker
  .modal__numberpicker--numberpicker
  .number-picker__numbers {
  max-width: 310px;
  margin: 0 auto;
  padding: 30px 20px;
}

@media (max-width: 575px) {
  .modal--numberpicker
    .modal__numberpicker--numberpicker
    .number-picker__numbers {
    width: 315px;
  }
}

@media (max-width: 380px) {
  .modal--numberpicker
    .modal__numberpicker--numberpicker
    .number-picker__numbers {
    max-width: 100%;
    width: 240px;
  }
}

.modal--numberpicker
  .modal__numberpicker--numberpicker
  .number-picker__numbers
  .betNum:nth-child(5n) {
  margin: 0 7px 7px 0;
}

@media (max-width: 380px) {
  .modal--numberpicker
    .modal__numberpicker--numberpicker
    .number-picker__numbers
    .betNum:nth-child(5n) {
    margin-right: 0;
  }
}

.modal--numberpicker
  .modal__numberpicker--numberpicker
  .number-picker__numbers
  .betNum:nth-child(7n) {
  margin-right: 0;
}

@media (max-width: 380px) {
  .modal--numberpicker
    .modal__numberpicker--numberpicker
    .number-picker__numbers
    .betNum:nth-child(7n) {
    margin: 0 7px 7px 0;
  }
}

.modal--numberpicker .modal__numberpicker--button {
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}

.modal--numberpicker .modal__numberpicker--button a.btn {
  width: 250px;
  max-width: 100%;
  height: 30px;
  text-decoration: none;
}

@media (max-width: 575px) {
  .modal--numberpicker .modal__numberpicker--button a.btn {
    width: 130px;
  }
}

.modal--numberpicker .modal__numberpicker--button button.btn {
  width: 250px;
  height: 30px;
}

@media (max-width: 575px) {
  .modal--numberpicker .modal__numberpicker--button button.btn {
    width: 130px;
  }
}

.modal--numberpicker .modal__selectboxes {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  margin-bottom: 30px;
}

@media (max-width: 575px) {
  .modal--numberpicker .modal__selectboxes {
    margin-bottom: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.modal--numberpicker .modal__selectboxes--select {
  width: 100%;
  max-width: 100%;
  margin-right: 0;
}

.modal--numberpicker .modal__selectboxes--select:not(:last-child) {
  margin-right: 10px;
}

@media (max-width: 575px) {
  .modal--numberpicker .modal__selectboxes--select:not(:last-child) {
    margin-right: 0;
  }
}

.modal--numberpicker .modal__selectboxes--select .selectize .selectize-input {
  font-size: 12px !important;
  height: 40px;
  text-align: left;
}

.modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-input:after {
  line-height: 43px;
}

.modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-input.dropdown-active
  input {
  top: -7px;
}

.modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-input.dropdown-active:after {
  margin-top: -3px;
}

.modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-dropdown {
  font-size: 12px !important;
}

.modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-dropdown
  .option {
  text-align: left;
}

.modal--numberpicker .modal__selectboxes--select .selectize .selectize-control {
  height: 40px;
}

.modal--numberpicker .modal__selectboxes--select .selectize input,
.modal--numberpicker
  .modal__selectboxes--select
  .selectize
  input::-webkit-input-placeholder {
  font-size: 12px !important;
}

.modal--important-update {
  background-color: #7bb8de;
}

@media only screen and (min-width: 641px) {
  .modal--important-update {
    max-width: 560px;
  }
}

.modal--important-update h2,
.modal--important-update p {
  color: var(--iq-white);
}

.modal--important-update p:last-of-type {
  margin-bottom: 40px;
}

@media (max-width: 479px) {
  .modal--important-update p:last-of-type {
    margin-bottom: 20px;
  }
}

.modal--important-update .remodal-close i {
  color: var(--iq-white);
}

.modal--important-update .form-group__buttons {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}

.modal--important-update .form-group__buttons a {
  text-decoration: none;
  width: 230px;
}

@media (max-width: 479px) {
  .modal--important-update .form-group__buttons a {
    width: 100%;
  }
}
.modal--449 {
  background: transparent;
  padding: 0;
  max-width: 380px;
}

.modal--449 .remodal-close {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #d5d8df;
  color: var(--iq-white);
  right: 10px;
  top: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 575px) {
  .modal--449 .remodal-close {
    right: -5px;
  }
}

.modal--449 .remodal-close:hover i {
  color: var(--iq-white);
}

.modal--449 .remodal-close i {
  font-size: 17px;
}

.modal--449 a {
  text-decoration: none;
}

.modal--449 img {
  max-width: 100%;
}

.page-rtl .modal--449 .remodal-close {
  right: unset;
  left: 10px;
}

body .selectize-control,
body .selectize-country-control {
  width: 100%;
  height: 50px;
}

body .selectize-control.rtl .selectize-input:after,
body .selectize-country-control.rtl .selectize-input:after {
  right: unset !important;
  left: 30px !important;
}

body .selectize-country .selectize-input,
body .selectize .selectize-input {
  font-size: 17px !important;

  line-height: 30px !important;
  color: var(--iq-darkgrey) !important;
  background: #f6f6f6;
  border: 1px solid var(--iq-orange);
  padding: 10px;
  border-radius: 5px;
  width: 70%;
  height: 50px;
  display: block;
  outline: 0 !important;
  box-shadow: none;
  cursor: pointer !important;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

body .selectize-country .selectize-input:after,
body .selectize .selectize-input:after {
  content: "\f078" !important;
  font-family: "Font Awesome 5 Pro";
  font-size: 17px;
  position: absolute !important;
  right: 30px !important;
  line-height: 53px;
  top: 0 !important;
  bottom: 0;
  margin: auto;
  color: var(--iq-black);
  display: block;
  z-index: 2;
  pointer-events: none;
  border: none !important;
}

body.page-rtl .selectize.drawSelectize .selectize-input:after {
  left: -70px !important;
  right: auto;
}

body .selectize-country .selectize-input input,
body .selectize .selectize-input input {
  cursor: pointer !important;
  font-size: 17px !important;

  height: 29px !important;
  line-height: 29px !important;
  pointer-events: none;
}

body .selectize-country .selectize-input input[disabled],
body .selectize .selectize-input input[disabled] {
  opacity: 1;
}

body .selectize-country .selectize-input input::-webkit-input-placeholder,
body .selectize .selectize-input input::-webkit-input-placeholder,
body .selectize-country .selectize-input input::-moz-placeholder,
body .selectize .selectize-input input::-moz-placeholder,
body .selectize-country .selectize-input input:-ms-input-placeholder,
body .selectize .selectize-input input:-ms-input-placeholder,
body .selectize-country .selectize-input input::-ms-input-placeholder,
body .selectize .selectize-input input::-ms-input-placeholder,
body .selectize-country .selectize-input input::placeholder,
body .selectize .selectize-input input::placeholder,
.hp-navigation__info--box p:first-child,
.form .form-group--download a:hover,
.number-picker__select .selectize .selectize-input:after,
.total-balance__bottom a:first-child:hover,
.verification__progress ul li.active,
.login__new .form a.forgot-pw:hover,
.login__new .login__content--link:hover,
.skin_449
  .hp-navigation__box.box-draw-info
  .countdown__pricebubble--content
  h5:not(:first-child),
.skin_449 .previous-drawn__text h2,
.skin_449.page-rtl
  .hp-navigation__box.box-draw-info
  .countdown__pricebubble--content
  h5:not(:first-child),
.header__login-menu.logged ul li.highlighted a,
.header__login-menu.logged ul li:hover > a,
.header__login-menu--mobile nav ul li .accordion-top.accordion-logged a,
.header__login-menu--button.primary a,
.header__balance--top span,
.header__nav--menu li:hover .droplink,
.header__nav--menu li a:hover,
.header__currency:hover .droplink,
.header__currency a:hover .countdown .base-timer__path-color {
  color: var(--iq-pink);
}

body .selectize-country .selectize-dropdown,
body .selectize .selectize-dropdown {
  font-size: 17px;
  border: 1px solid var(--iq-orange) !important;
  color: var(--iq-pink);
}

body .selectize-country .selectize-dropdown .subflex,
body .selectize .selectize-dropdown .subflex {
  font-size: 17px;
  color: var(--iq-darkgrey);
}

body .selectize-country .selectize-dropdown .option,
body .selectize-country .selectize-dropdown .subflex,
body .selectize .selectize-dropdown .option,
body .selectize .selectize-dropdown .subflex {
  padding: 10px;
}

body .selectize-country .selectize-dropdown .option sup,
body .selectize-country .selectize-dropdown .subflex sup,
body .selectize .selectize-dropdown .option sup,
body .selectize .selectize-dropdown .subflex sup {
  color: #eb1c23;
}

body .selectize-country .selectize-dropdown .active,
body .selectize .selectize-dropdown .active {
  background-color: var(--iq-pink) !important;
  color: var(--iq-white) !important;
}

body .selectize-country .selectize-dropdown .active .subflex,
body .selectize .selectize-dropdown .active .subflex {
  color: var(--iq-white) !important;
}

body .selectize-country .selectize-dropdown [data-selectable] .highlight,
body .selectize .selectize-dropdown [data-selectable] .highlight {
  position: unset;
  background-color: none;
  width: unset;
  height: unset;
  background: rgba(125, 168, 208, 0.2);
  display: inline-block;
  color: var(--iq-darkgrey);
}

body .selectize-country .selectize-input {
  background-color: #f7f7f7;
  border-right: 0;
  border-radius: 5px 0 0 5px;
  width: 85px;
}

body .selectize-country .selectize-input.dropdown-active {
  border: 5px 5px 0 0;
}

body .selectize-country .selectize-dropdown-content > div {
  padding: 5px;
}

body .selectize-country .selectize-dropdown {
  width: 165px !important;
}

body .selectize-country .flag {
  margin-bottom: 5px;
}

.flag-picker .selectize-input {
  height: 50px;
  display: -webkit-box !important;
  display: flex !important;
  -webkit-box-align: center;
  align-items: center;
}

.flag-picker .selectize-input input::-webkit-input-placeholder {
  color: transparent !important;
}

.flag-picker .selectize-input input::-moz-placeholder {
  color: transparent !important;
}

.flag-picker .selectize-input input:-ms-input-placeholder {
  color: transparent !important;
}

.flag-picker .selectize-input input::-ms-input-placeholder {
  color: transparent !important;
}

.flag-picker .selectize-input input::placeholder {
  color: transparent !important;
}

.flag-picker .selectize-input > * {
  color: var(--iq-darkblack);
}

.flag-picker .selectize-dropdown [data-selectable] {
  color: var(--iq-darkblack);
  padding: 5px 0 5px 5px;
}

.flag-picker .selectize-dropdown [data-selectable] .highlight {
  position: unset;
  background-color: none;
  width: unset;
  height: unset;
  background: rgba(125, 168, 208, 0.2);
  display: inline-block;
  color: var(--iq-darkgrey);
}

.flag-picker .flag {
  height: 18px;
}

.download {
  margin-bottom: 90px;
}

@media (max-width: 575px) {
  .download {
    margin-bottom: 50px;
  }
}

@media (max-width: 479px) {
  .download {
    margin-bottom: 30px;
  }
}

.download__platforms {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

@media (max-width: 479px) {
  .download__platforms {
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: unset;
    align-items: unset;
    position: absolute;
    bottom: 60px;
    width: 100%;
    max-width: 100%;
    padding-right: 20px;
  }
}

@media (max-width: 375px) {
  .download__platforms {
    bottom: 67px;
  }
}

@media (max-width: 479px) {
  .download__platforms p {
    margin-right: 0;
    margin-bottom: 10px;
    text-align: center;
    padding-right: 20px;
  }
}

@media (max-width: 375px) {
  .download__platforms p {
    margin-bottom: 0;
    font-size: 18px;
  }
}

.download__buttons {
  display: -webkit-box;
  display: flex;
}

@media (max-width: 479px) {
  .download__buttons {
    width: 100%;
    max-width: 100%;
    padding-right: 20px;
  }
}

@media (max-width: 375px) {
  .download__buttons {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

@media (max-width: 479px) {
  .download__buttons a {
    width: 50%;
  }
}

@media (max-width: 375px) {
  .download__buttons a {
    width: 100%;
  }
}

.download__buttons a:first-child {
  margin-right: 20px;
}

@media (max-width: 479px) {
  .download__buttons a:first-child {
    margin: 0 15px 0 0;
  }
}

@media (max-width: 375px) {
  .download__buttons a:first-child {
    margin: 0 0 5px;
  }
}

.download__buttons a h3 strong {
  font-size: 18px;
}

@media (max-width: 479px) {
  .download__buttons a h3 strong {
    font-size: 17px;
  }
}

@media (max-width: 479px) {
  .download__buttons a > div {
    text-align: left;
    width: 100%;
  }
}

.download__buttons a > div img {
  max-width: 100%;
  margin-right: 13px;
  width: 30px;
  height: 33px;
}

@media (max-width: 479px) {
  .download__buttons a > div img {
    margin-right: 5px;
    width: 25px;
    height: 28px;
  }
}

.downloadbanner {
  margin-bottom: 28px;
}

@media (max-width: 991px) {
  .downloadbanner {
    margin-bottom: 20px;
  }
}

.downloadbanner__inner {
  position: relative;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.downloadbanner__inner:hover {
  opacity: 0.9;
}

.downloadbanner__img img {
  max-width: 100%;
}

@media (max-width: 479px) {
  .downloadbanner__img.desktop {
    display: none;
  }
}

@media (min-width: 480px) {
  .downloadbanner__img.mobile {
    display: none;
  }
}

.downloadbanner__buttons {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.downloadbanner__buttons a {
  width: 50%;
  height: 100%;
  display: block;
}

.slick-slider {
  margin-bottom: 25px;
}

.login {
  margin: 93px 0 73px;
}

@media (max-width: 575px) {
  .login {
    margin: 30px 0 70px;
  }
}

.login__content {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}

.login__content .form {
  width: 621px;
  max-width: 100%;
}

@media (max-width: 575px) {
  .login__content .form h1 {
    text-align: left;
    margin-bottom: 30px;
  }
}

@media (max-width: 479px) {
  .login__content .form h1 {
    font-size: 18px;
  }
}

.login__content .form form .form-group:first-of-type {
  margin-bottom: 30px;
}

@media (max-width: 479px) {
  .login__content .form form .form-group:first-of-type {
    margin-bottom: 15px;
  }
}

@media (max-width: 575px) {
  .login__content .form form .form-group .input-row .info-icon,
  .login__content .form form .form-group .input-row span {
    top: 106px;
  }
}

@media (max-width: 479px) {
  .login__content .form form {
    padding: 20px 15px;
  }
}

@media (max-width: 479px) {
  .login__content .form form .form-group label {
    font-size: 14px;
  }
}

.login__content .form form button[type="submit"] {
  width: 236px;
  height: 60px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
}

@media (max-width: 479px) {
  .login__content .form form button[type="submit"] {
    width: 100%;
    max-width: 100%;
  }
}

.login__content .form form .alert__img img {
  max-width: 81px;
}

@media (max-width: 479px) {
  .login__content .form form .alert__img img {
    max-width: 67px;
  }
}

.login__content--link {
  font-size: 17px;
  display: block;
  color: var(--iq-pink);
  margin-top: 10px;
}

.register {
  margin: 77px 0 73px;
}

@media (max-width: 575px) {
  .register {
    margin: 30px 0 70px;
  }
}

.register__content .form form .form-group {
  margin-bottom: 30px;
}

@media (max-width: 479px) {
  .register__content .form form .form-group {
    margin-bottom: 15px;
  }
}

.register__content .form form .form-group.check,
.register__content .form form .form-group.last {
  margin-bottom: 20px;
}

.register__content .form form .form-group .information-text {
  font-size: 13px;
  color: var(--iq-darkgrey);
  line-height: 23px;
  margin-top: 13px;
}

.register__content .form form .form-group .information-text.secondary {
  margin-top: 0;
  margin-bottom: 5px;
}

@media (max-width: 479px) {
  .register__content .form form {
    padding: 20px 15px;
  }
}

.register__content .form form .info-box {
  background: hsla(0, 0%, 78.4%, 0.25);
  padding: 15px 20px;
  border-radius: 5px;
  margin-bottom: 40px;
}

@media (max-width: 479px) {
  .register__content .form form .info-box {
    margin-bottom: 20px;
  }
}

.register__content .form form .info-box p {
  font-size: 14px;
  line-height: 25px;
  color: var(--iq-darkblack);
}

.register__content .form form .alert__img img {
  max-width: 124px;
}

@media (max-width: 479px) {
  .register__content .form form .alert__img img {
    max-width: 87px;
  }
}

.form {
  max-width: 100%;
}

.form h1,
.form h2 {
  color: var(--iq-darkblack);
  text-align: center;
  margin-bottom: 40px;
}

.form form {
  border: 1px solid var(--iq-dullgrey);
  padding: 40px 47px;
  border-radius: 5px;
}

.form form.style-reset {
  padding: 0;
  border: none;
  border-radius: none;
}

.form form .error-text {
  display: block;
}

.form form .error-text p {
  font-size: 14px;
  color: var(--iq-darkred);
}

.form form .information-text {
  font-size: 13px;
  color: var(--iq-darkgrey);
  line-height: 23px;
  margin-top: 13px;
}

.form form .information-text.secondary {
  margin-top: 0;
  margin-bottom: 5px;
}

.form form#form_myprofile .form-group {
  margin-bottom: 30px;
}

@media (max-width: 479px) {
  .form form#form_myprofile .form-group {
    margin-bottom: 18px;
  }
}

.form form#form_myprofile #form_myprofile_email-lang,
.form form#form_myprofile #form_myprofile_newsletter {
  margin-bottom: 25px;
}

.form form#form_myprofile button {
  width: 200px;
}

.form form#form_changepasswd {
  max-width: 520px;
}

.form form#form_changepasswd .form-group {
  margin-bottom: 30px;
}

@media (max-width: 479px) {
  .form form#form_changepasswd .form-group {
    margin-bottom: 18px;
  }
}

.form form#form_manage-account .form-group {
  padding-bottom: 25px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--iq-dullgrey);
}

.form form#form_manage-account .form-group.success {
  padding-bottom: 0;
  border: none;
}

.form form#form_manage-account .form-group.last {
  padding-bottom: 0;
  margin: 30px 0;
  border-bottom: none;
  display: -webkit-box;
  display: flex;
}

@media (max-width: 479px) {
  .form form#form_manage-account .form-group.last {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.form form#form_manage-account .form-group.last input {
  width: 283px;
  margin-right: 15px;
}

@media (max-width: 479px) {
  .form form#form_manage-account .form-group.last input {
    width: 100%;
    max-width: 100%;
    margin-bottom: 14px;
  }
}

.form form#form_manage-account .form-group.last a {
  width: 200px;
}

@media (max-width: 479px) {
  .form form#form_manage-account .form-group.last a {
    width: 100%;
    max-width: 100%;
  }
}

.form form#form_manage-account .form-group__bottom {
  margin: 15px 0 0 38px;
  display: none;
}
.page-rtl .form form#form_manage-account .form-group__bottom {
  margin-right: 38px;
}

.form form#form_manage-account .form-group__bottom--text {
  font-size: 14px;
  line-height: 25px;
  color: var(--iq-darkgrey);
  margin-bottom: 13px;
}

.form form#form_manage-account .form-group__bottom label {
  margin-bottom: 10px;
  -webkit-box-align: center;
  align-items: center;
}

@media (max-width: 479px) {
  .form form#form_manage-account .form-group__bottom label {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: start;
    align-items: flex-start;
  }
}

.form
  form#form_manage-account
  .form-group__bottom
  label
  input[type="checkbox"] {
  width: 19px;
  height: 19px;
  -webkit-box-flex: 0;
  flex: 0 0 19px;
}

.form
  form#form_manage-account
  .form-group__bottom
  label
  input[type="checkbox"]:after {
  width: 7px;
  height: 7px;
  left: 5px;
  top: 5px;
}

@media (max-width: 479px) {
  .form
    form#form_manage-account
    .form-group__bottom
    label
    input[type="checkbox"] {
    margin-top: 4px;
  }
}

.form form#form_manage-account .form-group__bottom--options {
  margin-top: 25px;
}

@media (max-width: 767px) {
  .form form#form_manage-account .form-group__bottom--options {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.form form#form_manage-account .form-group__bottom.active {
  display: block;
}

.form form#form_manage-account textarea {
  margin-top: 35px;
  border-radius: 5px;
}

@media (max-width: 575px) {
  .form form#form_changepasswd button {
    width: 100%;
    max-width: 100%;
  }
}

.form form #form_deposit_amount .deposit--left,
.form form #form_deposit_amount .payment--box {
  -webkit-box-align: start;
  align-items: flex-start;
}

@media (max-width: 479px) {
  .form form #form_deposit_amount .input-box {
    margin-right: 10px;
  }
}

.form form #form_deposit_amount .input-box p {
  top: 16px;
}

.form form__close-account {
  padding-bottom: 35px;
}

.form form#form_new-password {
  border: none;
  padding: 0;
}

.form .form-group {
  position: relative;
}

.form .form-group.disabled input {
  opacity: 0.5;
}

.form .form-group label {
  color: var(--iq-darkblack);
  font-family: "Product-Sans-Bold";
}

.favourite-numbers__content .form .form-group label {
  color: #434343;
}

.form .form-group label sup {
  color: var(--iq-darkred);
}

.form .form-group .info-icon {
  position: absolute;
  right: 15px;
  top: 45px;
}

.form .form-group span {
  width: 103px;
  height: 32px;
  color: var(--iq-white);
  background-color: var(--iq-orange);
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  position: absolute;
  top: 43px;
  right: 40px;
  padding: 10px 0 5px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.form .form-group span:hover {
  background-color: var(--iq-orange);
}

@media (max-width: 575px) {
  .form .form-group span {
    width: 82px;
  }

  .page-rtl .form .form-group label {
    font-size: 14px;
  }

  .favourite-numbers__content .form .form-group label {
    color: #212529;
  }
}

.form .form-group .captcha-wrapper {
  margin-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.form .form-group .captcha-wrapper,
.form .form-group .captcha-wrapper .send_otp {
  margin-top: 20px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.form .form-group .captcha-wrapper .send_otp {
  width: 100%;
  max-width: 100%;
  height: 50px;
  background-color: var(--iq-pink);
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: unset;
  top: unset;
  right: unset;
  left: unset;
}

@media (max-width: 767px) {
  .form .form-group .captcha-wrapper .send_otp {
    margin-left: 0;
  }
}

.form .form-group .captcha-wrapper .send_otp p {
  color: var(--iq-white);
}

.form .form-group .captcha-wrapper .send_otp.disabled {
  background-color: var(--iq-dullgrey);
  cursor: default;
  pointer-events: none;
}

.form .form-group .captcha-wrapper .send_otp.disabled:hover {
  background-color: #797979;
}

.form .form-group .captcha-wrapper .send_otp_timer {
  display: none;
}

.form .form-group .captcha-wrapper .send_otp span {
  width: unset;
  height: unset;
  display: inline-block;
  background-color: unset;
  top: unset;
  right: unset;
  left: unset;
  padding: 0;
  position: unset;
  border-radius: 0;
  cursor: default;
}

.form .form-group.check label {
  display: -webkit-box;
  display: flex;
  cursor: pointer;
}

.form .form-group.check label p {
  font-size: 14px;
  line-height: 25px;
  color: var(--iq-darkgrey);
}

.form .form-group.check label a {
  color: #fa9b63;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.form .form-group.check label a:hover {
  color: #fa9b63;
}

.form .form-group.check label .check-text h3 {
  color: var(--iq-darkblack);
  margin: 5px 0;
}

.form .form-group.check label .check-text p:not(:last-child) {
  margin-bottom: 10px;
}

.form .form-group.error input {
  border-color: var(--iq-darkred) !important;
}

.form .form-group.error .input-box input {
  border-color: var(--iq-dullgrey);
}

.form .form-group.error .selectize-input {
  border-color: var(--iq-darkred);
}

.form .form-group.error .flag-picker .selectize-input {
  border-color: #d0d0d0;
}

.form .form-group.error .error-text {
  display: block;
}

.form .form-group.error .error-text p {
  font-size: 14px;
  color: var(--iq-darkred);
  margin-top: 15px;
}

.form .form-group.success .success-text {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.form .form-group .error-text {
  display: none;
}

.form .form-group .success-text {
  display: none;
  padding: 10px 20px;
  background-color: rgba(132, 189, 0, 0.25);
  margin-top: 15px;
}

.form .form-group .success-text p {
  font-size: 14px;
  color: #84bd00;
}

.form .form-group .success-text-secondary {
  padding: 0;
  background-color: unset;
  margin-bottom: 10px;
}

.form .form-group .success-text-secondary p {
  font-size: 14px;
  color: #84bd00;
}

.form .form-group .input-row {
  display: -webkit-box;
  display: flex;
}

@media (max-width: 575px) {
  .form .form-group .input-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.form .form-group .input-row .flag-picker {
  min-width: 165px;
  width: 165px;
  max-width: 100%;
  margin-right: 15px;
  position: relative;
}

@media (max-width: 575px) {
  .form .form-group .input-row .flag-picker {
    margin-bottom: 13px;
  }
}

.form .form-group .input-row .flag-picker-number {
  width: 81px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 5px 5px 0;
  padding: 0 10px;
}

.form
  .form-group
  .input-row
  .flag-picker
  .selectize-control.single
  .selectize-input.input-active {
  cursor: pointer;
}

.form .form-group .flag-picker-input {
  max-width: 100%;
}

@media (max-width: 575px) {
  .form .form-group#form_myprofile_phone .info-icon,
  .form .form-group#form_myprofile_phone span {
    top: 106px;
  }
}

.form .form-group--download {
  margin-bottom: 0;
  padding-top: 10px;
  border-top: 1px solid var(--iq-dullgrey);
}

.form .form-group__bottom--options {
  display: -webkit-box;
  display: flex;
}

.form .form-group__bottom--select {
  max-width: 285px;
}

.form .form-group__options h4 {
  font-size: 14px !important;
  color: var(--iq-darkblack);
}

.form .form-group__options:first-child {
  margin-right: 140px;
}

@media (max-width: 575px) {
  .form .form-group__options:first-child {
    width: 100%;
    max-width: 100%;
  }
}

.form .form-group__options ul {
  padding-left: 0;
  list-style-type: none;
}

.form .form-group__options ul li {
  font-size: 14px;
  color: var(--iq-darkgrey);
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.form .form-group__options ul li:not(:last-child) {
  margin-bottom: 7px;
}

.form .form-group__options--red {
  background-color: var(--iq-darkred);
}
.form .form-group__options--green {
  background-color: green;
}

.form .form-group__options--green,
.form .form-group__options--red {
  width: 17px;
  height: 17px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 6px;
}

.form .form-group .input-box {
  width: 170px;
  max-width: 100%;
  position: relative;
}

@media (max-width: 479px) {
  .form .form-group .input-box {
    width: 170px;
  }
}

.form .form-group .input-box input {
  text-align: right;
  height: 43px;
}

.form .form-group .input-box p {
  color: var(--iq-darkblack);
  position: absolute;
  top: 16px;
  left: 10px;
}

@media (max-width: 479px) {
  .form .form-group .input-box p {
    font-size: 14px;
  }
}

.form .form-group .input-box--currency {
  display: none;
  font-size: 14px;
}

@media (max-width: 479px) {
  .form .form-group .input-box--currency {
    font-size: 12px;
  }
}

.form .form-group .input-box--currency.active {
  display: block;
}

@media (max-width: 991px) {
  .form .form-group .input-box--currency {
    display: none;
  }
}

.form .form-group .deposit--left {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

@media (max-width: 991px) {
  .form .form-group .deposit--left {
    margin-right: 0;
  }
}

.form .form-group .deposit--left p.input-box--currency {
  margin-top: 5px;
  font-size: 12px;
  font-style: italic;
}

.form a.forgot-pw {
  font-size: 17px;
  display: block;
  color: var(--iq-pink);
  margin: 10px 0 30px;
}

.form .alert,
.form .login-alert {
  display: -webkit-box;
  display: flex;
  padding: 25px;
  background: var(--iq-white);
  border-radius: 5px;
  margin-top: 40px;
  display: none;
}

@media (max-width: 575px) {
  .form .alert,
  .form .login-alert {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 25px 15px;
    margin-top: 20px;
  }
}

.form .alert__img,
.form .login-alert__img {
  margin-right: 40px;
}

@media (max-width: 575px) {
  .form .alert__img,
  .form .login-alert__img {
    margin-right: 0;
  }
}

.form .alert__text,
.form .login-alert__text {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
}

@media (max-width: 575px) {
  .form .alert__text,
  .form .login-alert__text {
    margin-top: 15px;
  }
}

.form .alert__text p,
.form .login-alert__text p {
  color: var(--iq-brown);
  margin-bottom: 20px;
}

@media (max-width: 575px) {
  .form .alert__text p,
  .form .login-alert__text p {
    font-size: 17px;
  }
}

.form .alert__text--button,
.form .login-alert__text--button {
  width: 145px;
}

@media (max-width: 575px) {
  .form .alert__text--button,
  .form .login-alert__text--button {
    width: 100%;
  }
}

.form .password-alert {
  display: -webkit-box;
  display: flex;
  margin-top: 18px;
}

.form .password-alert .alert__text p {
  font-size: 14px;
  margin-bottom: 0;
}

.forgotpw {
  margin: 77px 0 73px;
}

@media (max-width: 575px) {
  .forgotpw {
    margin: 30px 0 70px;
  }
}

.forgotpw__reset {
  margin-top: 10px;
}

.forgotpw__content,
.forgotpw__reset {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}

.forgotpw__content .form {
  width: 621px;
  max-width: 100%;
}

.forgotpw__content .form h1 {
  margin-bottom: 15px;
  font-weight: normal;
}

@media (max-width: 575px) {
  .forgotpw__content .form h1 {
    text-align: center;
  }
}

.forgotpw__content .form p {
  color: var(--iq-darkblack);
  line-height: 28px;
  text-align: center;
  margin-bottom: 38px;
}

@media (max-width: 479px) {
  .forgotpw__content .form p {
    font-size: 14px;
    margin-bottom: 30px;
  }
}

.forgotpw__content .form p.phone-labeltext {
  color: #797979;
  font-size: 12px;
  margin-bottom: 10px;
  line-height: unset;
  text-align: unset;
}

.forgotpw__content .form form #form_forgotpw_email span {
  width: 185px;
  height: 50px;
  position: unset;
  right: unset;
}

.forgotpw__content .form form #form_forgot_mobile-number {
  margin-bottom: 23px;
}

.forgotpw__content .form form .form-divider {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  margin: 15px 0;
}

.forgotpw__content .form form .form-divider:before {
  content: "";
  width: 100%;
  height: 2px;
  display: block;
  border-bottom: 1px dashed var(--iq-darkblack);
  position: absolute;
  z-index: 0;
  top: 10px;
}

.forgotpw__content .form form .form-divider span {
  color: var(--iq-pink);
  text-align: center;
  padding: 0 10px;
  position: relative;
  z-index: 1;
  background-color: var(--iq-white);
}

.forgotpw__content .form form .form-group .error-text p {
  text-align: left;
  margin-bottom: 0;
}

.forgotpw__content .form form .form-group .success-text p {
  margin-bottom: 0;
}

.forgotpw__content .form form button[type="submit"] {
  margin-top: 30px;
  width: 236px;
  height: 60px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

@media (max-width: 479px) {
  .forgotpw__content .form form button[type="submit"] {
    width: 100%;
    max-width: 100%;
  }
}

.number-picker {
  margin-bottom: 80px;
}

@media (max-width: 479px) {
  .number-picker {
    margin-bottom: 50px;
  }
}

.number-picker__content {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  justify-content: center;
}

.number-picker .error-text {
  background-color: rgba(213, 0, 50, 0.1);
  border-radius: 5px;
  padding: 15px 20px;
  display: none;
}

@media (max-width: 479px) {
  .number-picker .error-text {
    padding: 15px 12px;
  }
}

.number-picker .error-text.active {
  display: block;
}

.number-picker .error-text p {
  font-size: 14px;
  color: var(--iq-darkred);
}

.number-picker .blue-box {
  background-color: var(--iq-mildgrey);
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 15px 20px;
}

@media (max-width: 479px) {
  .number-picker .blue-box {
    padding: 15px 12px;
  }
}

@media (max-width: 479px) {
  .number-picker .blue-box p {
    font-size: 14px;
  }
}

.number-picker__picker.pickerFull .number-picker__numbers {
  background-image: url(../img/background-check.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-position-y: 44%;
}

.number-picker__picker.disabled .number-picker__picker--name {
  background-color: var(--iq-dullgrey);
}

.number-picker__picker.disabled .number-picker__picker--bottom {
  border-color: var(--iq-dullgrey);
  background: var(--iq-dullgrey);
  display: none;
}

.number-picker__picker.disabled .number-picker__numbers div {
  cursor: auto;
}

.number-picker__picker.disabled .number-picker__numbers div:hover {
  border-color: rgba(0, 0, 0, 0.1);
}

.number-picker__picker--name {
  background-color: var(--iq-pink);
  display: -webkit-box;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 12px 0;
}

.number-picker__picker--name p,
.number-picker__picker--name span {
  color: var(--iq-white);
  text-transform: uppercase;
}

.number-picker__picker--name span {
  margin-left: 5px;
}

.number-picker__picker--bottom {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 10px 20px;
  background: rgba(0, 169, 224, 0.24);
  border-top: 2px solid var(--iq-pink);
}

.number-picker__picker--bottom div {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}

.number-picker__picker--bottom div:hover,
.number-picker__picker--favourite-numbers:hover,
.number-picker__picker--favourites:hover {
  opacity: 0.7;
}

.number-picker__numbers {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

@media (max-width: 575px) {
  .number-picker__numbers {
    width: 293px;
    padding: 20px 10px;
    margin: 0 auto;
  }
}

.number-picker__numbers .betNum:nth-child(5n) {
  margin-right: 0;
}

@media (max-width: 575px) {
  .number-picker__numbers .betNum:nth-child(5n) {
    margin-right: 7px;
  }
}

.number-picker__numbers .betNum:hover {
  border-color: #fce300;
}

.number-picker__line {
  height: 1px;
  width: 100%;
  max-width: 100%;
  background-color: var(--iq-dullgrey);
  margin: 40px 0 25px;
}

@media (max-width: 575px) {
  .number-picker__line.desktop {
    display: none;
  }
}

.number-picker__line.mobile {
  margin: 20px 0;
}

@media (min-width: 576px) {
  .number-picker__line.mobile {
    display: none;
  }
}

.number-picker__bottom {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  align-items: flex-end;
}

@media (max-width: 1199px) {
  .number-picker__bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    align-items: flex-start;
  }
}

.number-picker__bottom--right.tablet {
  width: 100%;
  max-width: 100%;
}

@media (min-width: 992px) {
  .number-picker__bottom--right.tablet {
    display: none;
  }
}

@media (max-width: 575px) {
  .number-picker__bottom--left {
    width: 100%;
    max-width: 100%;
  }
}

.number-picker .selectize-input.items.not-full.has-options input {
  color: var(--iq-orange);
}

.number-picker__select {
  width: 260px;
  max-width: 100%;
}

@media (max-width: 1199px) {
  .number-picker__select {
    margin-bottom: 20px;
  }
}

.number-picker__select p {
  color: var(--iq-orange);
  margin-bottom: 5px;
  text-transform: uppercase;
  font-size: 18px;
}
.number-picker__select p span {
  font-size: 18px;
}

@media (max-width: 575px) {
  .number-picker__select.desktop,
  .number-picker__select .selectize {
    width: 100%;
    max-width: 100%;
  }
}

.number-picker__buttons {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

@media (max-width: 991px) {
  .number-picker__buttons {
    flex-wrap: wrap;
  }
}

.number-picker__buttons span {
  cursor: pointer;
  margin-right: 8px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 767px) {
  .number-picker__buttons span {
    margin-bottom: 10px;
  }
}

@media (max-width: 575px) {
  .number-picker__buttons span {
    width: 100% !important;
    -webkit-box-flex: 0 !important;
    flex: none !important;
    margin-right: 0;
    text-align: center;
  }
}

.number-picker__buttons span:hover {
  opacity: 0.9;
}

.number-picker__buttons span img {
  margin-right: 14px;
}

@media (max-width: 575px) {
  .number-picker__buttons span img {
    display: none;
  }
}

@media (max-width: 991px) {
  .number-picker__buttons span:first-child {
    -webkit-box-flex: 0;
  }
}

@media (max-width: 767px) {
  .number-picker__buttons span:first-child {
    -webkit-box-flex: 0;
    flex: 0 0 49%;
    width: 49%;
  }
}

.number-picker__buttons span:nth-child(2) {
  background-color: var(--iq-pink);
  color: var(--iq-white);
}

@media (max-width: 991px) {
  .number-picker__buttons span:nth-child(2) {
    -webkit-box-flex: 0;
    flex: 0 0 33%;
    width: 33%;
  }
}

@media (max-width: 767px) {
  .number-picker__buttons span:nth-child(2) {
    -webkit-box-flex: 0;
    flex: 0 0 49%;
    width: 49%;
    margin-right: 0;
  }
}

.number-picker__buttons span:nth-child(3) {
  background-color: #dedede;
  color: var(--iq-black);
}

@media (max-width: 991px) {
  .number-picker__buttons span:nth-child(3) {
    -webkit-box-flex: 0;
    flex: 0 0 31%;
    width: 31%;
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .number-picker__buttons span:nth-child(3) {
    -webkit-box-flex: 0;
    flex: 0 0 49%;
    width: 49%;
    margin-right: 8px;
    margin-bottom: 0;
  }
}

.number-picker__buttons button {
  padding: 15px 20px;
  height: unset;
}

@media (max-width: 575px) {
  .number-picker__buttons button {
    margin: 0 0 8px;
    width: 100%;
    max-width: 100%;
  }
}

.number-picker__buttons a {
  padding: 15px 20px;
  height: unset;
  margin-right: 8px;
}

@media (max-width: 575px) {
  .number-picker__buttons a {
    margin: 0 0 8px;
    width: 100%;
    max-width: 100%;
  }
}

.number-picker__buttons--submit {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 4px 4px 4px 15px;
  border: 1px solid var(--iq-dullgrey);
  border-radius: 5px;
}

@media (max-width: 991px) {
  .number-picker__buttons--submit {
    max-width: 100%;
    margin-top: 20px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-flex: 0;
    flex: 0 0 35%;
    width: 35%;
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .number-picker__buttons--submit {
    -webkit-box-flex: 0;
    flex: 0 0 49%;
    width: 49%;
    margin-top: 0;
  }
}

@media (max-width: 575px) {
  .number-picker__buttons--submit {
    width: 100%;
    -webkit-box-flex: 0;
    flex: none;
    border: none;
    padding: 0;
  }
}

.number-picker__buttons--submit p {
  margin-right: 16px;
  margin-top: 5px;
}

@media (max-width: 575px) {
  .number-picker__buttons--submit p {
    padding: 0 15px;
    border: 1px solid var(--iq-dullgrey);
    border-radius: 5px;
    height: 48px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin-top: 0;
    min-width: 110px;
  }
}

.number-picker__buttons--submit p span {
  background-color: transparent !important;
  color: initial !important;
  margin-left: 0;
  margin-right: 5px;
}

@media (max-width: 575px) {
  .number-picker__buttons--submit button {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 575px) {
  .number-picker #number-picker__price {
    min-width: 140px;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
}

@media (max-width: 575px) {
  .number-picker #number-picker__price span {
    max-width: 50px;
    margin-bottom: 0;
  }
}

.loading-wrapper {
  display: none;
  position: fixed;
  z-index: 10010;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading-wrapper:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.loading-wrapper.active {
  display: block;
}

.loading {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 10px solid var(--iq-white);
  border-top-color: var(--iq-pink);
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  z-index: 999;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.loading-secondary {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: var(--iq-white);
  display: none;
  z-index: 10010;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading-secondary:before {
  content: none;
}

.loading-secondary.active {
  display: block;
}

.loading-secondary .loading {
  position: absolute;
}

.shopping-cart {
  margin: 0 0 100px;
}

@media (max-width: 991px) {
  .shopping-cart {
    margin: 0 0 50px;
  }
}

.shopping-cart iframe {
  border: none;
}

.shopping-cart__content--top {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 64px;
}

@media (max-width: 991px) {
  .shopping-cart__content--top {
    margin-bottom: 20px;
  }
}

.shopping-cart__content--top h1 {
  color: var(--iq-darkblack);
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .shopping-cart__content--top h1 {
    font-size: 18px;
  }
}

.shopping-cart__content--top-img img {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.shopping-cart__content--top-img img:not(:last-child) {
  margin-right: 18px;
}

@media (max-width: 380px) {
  .shopping-cart__content--top-img img:not(:last-child) {
    margin-right: 8px;
  }
}

@media (max-width: 991px) {
  .shopping-cart__content--top-img img:first-child {
    width: 34px;
  }

  .shopping-cart__content--top-img img:nth-child(2) {
    width: 27px;
  }

  .shopping-cart__content--top-img img:nth-child(3) {
    width: 34px;
  }
}

@media (max-width: 360px) {
  .shopping-cart__content--top-img img:first-child {
    width: 28px;
  }

  .shopping-cart__content--top-img img:nth-child(3) {
    width: 70px;
    width: 28px;
  }
}

.shopping-cart__content--bottom p {
  color: var(--iq-black);
  text-align: center;
}

@media (max-width: 479px) {
  .shopping-cart__content--bottom p {
    font-size: 14px;
  }
}

.shopping-cart__content--btn {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}

.shopping-cart__content--btn a {
  width: 135px;
  max-width: 100%;
  height: 30px;
  padding: 15px 20px;
}

@media (max-width: 991px) {
  .shopping-cart__content .cart {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  .shopping-cart__content .cart > div {
    -webkit-box-ordinal-group: 3;
    order: 2;
  }

  .shopping-cart__content .cart > div:last-child {
    -webkit-box-ordinal-group: 4;
    order: 3;
  }

  .shopping-cart__content .cart > div.cart__purchase-note {
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
}

.shopping-cart__content .cart .error-text {
  margin-top: 15px;
}

.shopping-cart__content .cart .error-text p {
  font-size: 13px;
}

@media (max-width: 575px) {
  .shopping-cart__content .cart .error-text {
    margin-top: 5px;
  }
}

.shopping-cart__content .cart__top {
  display: -webkit-box;
  display: flex;
  padding-bottom: 17px;
  border-bottom: 1px solid var(--iq-dullgrey);
}

@media (max-width: 991px) {
  .shopping-cart__content .cart__top {
    display: none;
  }
}

.shopping-cart__content .cart__top--title {
  position: relative;
}

.shopping-cart__content .cart__top--title:first-child {
  width: 25%;
}

.shopping-cart__content .cart__top--title:nth-child(2) {
  width: 45%;
}

.shopping-cart__content .cart__top--title:nth-child(3) {
  width: 15%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.shopping-cart__content .cart__top--title:last-child {
  width: 15%;
}

.shopping-cart__content .cart__top--title h3 {
  color: #797979;
  margin-bottom: 0;
}

.shopping-cart__content .cart__top--title span {
  color: #797979;
  margin-bottom: 0;
  width: 15px;
  height: 15px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border: 1px solid #797979;
  border-radius: 50%;
  font-size: 11px;
  padding-top: 4px;
  margin-left: 5px;
  cursor: pointer;
}

.shopping-cart__content .cart__top--title span:hover + .cart__top--titlehover {
  display: block;
}

.shopping-cart__content .cart__top--titlehover {
  position: absolute;
  background-color: var(--iq-white);
  width: 310px;
  text-align: center;
  z-index: 2;
  border: 1px solid #797979;
  border-radius: 5px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  top: 22px;
  left: -144px;
  display: none;
}

.shopping-cart__content .cart__top--titlehover p {
  color: #797979;
  line-height: 20px;
  padding: 10px 0;
}

.shopping-cart__content .cart__content {
  display: -webkit-box;
  display: flex;
  padding: 23px 0;
  border-bottom: 1px solid var(--iq-dullgrey);
}

@media (max-width: 991px) {
  .shopping-cart__content .cart__content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    border-bottom: none;
    padding: 20px 0 0;
    background-color: var(--iq-white);
    box-shadow: 0 0 15px rgba(15, 15, 15, 0.09);
    margin-bottom: 20px;
  }
}

.shopping-cart__content .cart__details {
  width: 25%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
}

@media (max-width: 991px) {
  .shopping-cart__content .cart__details {
    width: 100%;
    max-width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: start;
    align-items: flex-start;
    margin-bottom: 16px;
    padding: 0 18px;
  }
}

.shopping-cart__content .cart__details--wrapper {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.shopping-cart__content .cart__details--heading {
  font-size: 14px;
  color: #797979;
  margin-bottom: 15px;
}

@media (min-width: 992px) {
  .shopping-cart__content .cart__details--heading {
    display: none;
  }
}

.shopping-cart__content .cart__details--img {
  width: 95px;
  height: 95px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 50%;
}

.shopping-cart__content .cart__details--img img {
  max-width: 100%;
  width: 35px;
}

@media (max-width: 991px) {
  .shopping-cart__content .cart__details--img {
    width: 70px;
    height: 70px;
    margin-right: 15px;
  }

  .shopping-cart__content .cart__details--img img {
    width: 27px;
  }
}

.shopping-cart__content .cart__details--ticket {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.shopping-cart__content .cart__details--ticket p {
  color: var(--iq-black);
  margin-bottom: 0;
  margin-right: 5px;
}

.shopping-cart__content .cart__details--ticket span {
  color: var(--iq-black);
}

.shopping-cart__content .cart__numbers {
  width: 60%;
}

@media (max-width: 991px) {
  .shopping-cart__content .cart__numbers {
    width: 100%;
    max-width: 100%;
    padding: 0 18px;
  }
}

.shopping-cart__content .cart__numbers--heading {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.shopping-cart__content .cart__numbers--heading h3 {
  font-size: 14px;
  color: #797979;
  margin-bottom: 15px;
}

@media (max-width: 400px) {
  .shopping-cart__content .cart__numbers--heading h3 {
    font-size: 12px;
  }
}

.shopping-cart__content .cart__numbers--heading .cart__top--title {
  width: unset;
  display: -webkit-box;
  display: flex;
}

.shopping-cart__content .cart__numbers--heading .cart__top--titlehover {
  z-index: 2;
}

@media (min-width: 992px) {
  .shopping-cart__content .cart__numbers--heading {
    display: none;
  }
}

.shopping-cart__content .cart__numbers--row {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.shopping-cart__content .cart__numbers--numbers {
  display: -webkit-box;
  display: flex;
}

@media (max-width: 991px) {
  .shopping-cart__content .cart__numbers--numbers {
    -webkit-box-pack: center;
    justify-content: center;
  }
}

.shopping-cart__content .cart__numbers--numbers:not(:last-child) {
  margin-bottom: 5px;
}

.shopping-cart__content .cart__numbers--numbers .betNum {
  margin-bottom: 0;
}

.shopping-cart__content .cart__numbers--numbers .betNum:not(:last-child) {
  margin-right: 5px;
}

.shopping-cart__content .cart__numbers--number-row {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding-right: 90px;
}

@media (max-width: 575px) {
  .shopping-cart__content .cart__numbers--number-row {
    padding-right: 65px;
  }
}

@media (max-width: 400px) {
  .shopping-cart__content .cart__numbers--number-row {
    padding-right: 0;
  }
}

.shopping-cart__content .cart__selectboxes {
  display: -webkit-box;
  display: flex;
  margin-top: 10px;
}

@media (max-width: 575px) {
  .shopping-cart__content .cart__selectboxes {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.shopping-cart__content .cart__selectboxes--select {
  width: 190px;
}

@media (max-width: 575px) {
  .shopping-cart__content .cart__selectboxes--select {
    width: 100%;
    max-width: 100%;
  }
}

.shopping-cart__content
  .cart__selectboxes--select.error
  .selectize
  .selectize-input {
  border-color: var(--iq-darkred);
}

.shopping-cart__content .cart__selectboxes--select .selectize-control {
  height: 30px;
}

.shopping-cart__content .cart__selectboxes--select .selectize .selectize-input {
  font-size: 12px !important;
  height: 30px;
  border: 1px solid var(--iq-pink);
  color: var(--iq-pink) !important;
}

.shopping-cart__content
  .cart__selectboxes--select
  .selectize
  .selectize-input.input-active {
  display: -webkit-box;
  display: flex;
}

.shopping-cart__content
  .cart__selectboxes--select
  .selectize
  .selectize-input:after {
  line-height: 30px;
  color: var(--iq-pink);
  content: "" !important;
  font-size: 22px;
}

.shopping-cart__content
  .cart__selectboxes--select
  .selectize
  .selectize-dropdown {
  font-size: 12px !important;
  color: var(--iq-pink);
}

.shopping-cart__content
  .cart__selectboxes--select
  .selectize
  .selectize-control {
  height: 30px;
}

.shopping-cart__content .cart__selectboxes--select .selectize input,
.shopping-cart__content
  .cart__selectboxes--select
  .selectize
  input::-webkit-input-placeholder {
  font-size: 12px !important;
}

.shopping-cart__content .cart__selectboxes--select:not(:last-child) {
  margin-right: 5px;
}

@media (max-width: 575px) {
  .shopping-cart__content .cart__selectboxes--select:not(:last-child) {
    margin-right: 0;
    margin-bottom: 5px;
  }
}

.shopping-cart__content .cart__purchase--check {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}

.shopping-cart__content .cart__purchase--check input {
  margin-right: 0;
}

.shopping-cart__content .cart__purchase-note {
  padding: 23px 0;
  border-bottom: 1px solid var(--iq-dullgrey);
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

@media (max-width: 575px) {
  .shopping-cart__content .cart__purchase-note {
    display: inline-block;
  }
}

.shopping-cart__content .cart__purchase-note p {
  color: var(--iq-black);
  display: inline;
}

@media (max-width: 479px) {
  .shopping-cart__content .cart__purchase-note p {
    font-size: 14px;
  }
}

.shopping-cart__content .cart__purchase-note--btn {
  margin-left: 10px;
}

@media (max-width: 575px) {
  .shopping-cart__content .cart__purchase-note--btn {
    display: inline-block;
    margin: 0;
  }
}

.shopping-cart__content .cart__purchase-note--btn a {
  height: 30px;
  padding: 15px 10px 12px;
}

@media (max-width: 479px) {
  .shopping-cart__content .cart__purchase-note--btn a {
    font-size: 14px;
  }
}

.shopping-cart__content .cart__edit {
  width: 15%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
}

@media (max-width: 991px) {
  .shopping-cart__content .cart__edit {
    width: 100%;
    max-width: 100%;
    padding: 5px 18px 12px;
    background-color: var(--iq-white);
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin-top: 0;
  }
}

.shopping-cart__content .cart__edit--buttons {
  display: -webkit-box;
  display: flex;
}

.shopping-cart__content .cart__edit button {
  background-color: transparent;
  border: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.shopping-cart__content .cart__edit button:hover {
  opacity: 0.8;
}

.shopping-cart__content .cart__info {
  padding: 22px 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid var(--iq-dullgrey);
  position: relative;
}

@media (max-width: 991px) {
  .shopping-cart__content .cart__info {
    padding: 18px 0 0;
    border-bottom: 0;
    -webkit-box-pack: end;
    justify-content: flex-end;
  }
}

.shopping-cart__content .cart__info--img {
  display: none;
  width: 107px;
  height: 35px;
  position: absolute;
  top: 10px;
  left: 0;
}

.shopping-cart__content .cart__info--img img {
  max-width: 100%;
}

@media (max-width: 991px) {
  .shopping-cart__content .cart__info--img {
    display: block;
  }
}

.shopping-cart__content .cart__info--text {
  max-width: 533px;
}

@media (max-width: 991px) {
  .shopping-cart__content .cart__info--text {
    display: none;
  }
}

.shopping-cart__content .cart__info--text p {
  font-size: 13px;
  color: #797979;
  line-height: 26px;
}

@media (max-width: 991px) {
  .shopping-cart__content .cart__info--text p {
    font-size: 12px;
  }
}

.shopping-cart__content .cart__info--text p a {
  color: var(--iq-orange);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.shopping-cart__content .cart__info--text p a:hover {
  color: var(--iq-orange);
}

.shopping-cart__content .cart__summary {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: end;
  align-items: flex-end;
}

@media (max-width: 991px) {
  .shopping-cart__content .cart__summary {
    width: 100%;
    max-width: 100%;
  }
}

.shopping-cart__content .cart__summary--desktop {
  margin-top: 27px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-pack: end;
  justify-content: flex-end;
  position: relative;
}

@media (min-width: 991px) {
  .shopping-cart__content .cart__summary--desktop {
    display: none;
  }
}

.shopping-cart__content .cart__summary--desktop .cart__summary--img {
  width: 107px;
  height: 35px;
  position: absolute;
  top: -7px;
  left: 0;
}

.shopping-cart__content .cart__summary--desktop .cart__summary--img img {
  max-width: 100%;
}

.shopping-cart__content .cart__summary > div {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.shopping-cart__content .cart__summary > div:not(:last-child) {
  margin-bottom: 12px;
}

.shopping-cart__content .cart__summary > div:first-child {
  margin-bottom: 5px;
}

.shopping-cart__content .cart__summary--price {
  -webkit-box-align: end;
  align-items: flex-end;
}

.shopping-cart__content .cart__summary--price p,
.shopping-cart__content .cart__summary--price span {
  color: var(--iq-darkblack);
}

.shopping-cart__content .cart__summary--discount {
  -webkit-box-align: end;
  align-items: flex-end;
}

.shopping-cart__content .cart__summary--discount p {
  color: var(--iq-darkblack);
  margin-right: 5px;
}

.shopping-cart__content .cart__summary--discount span {
  color: var(--iq-darkblack);
}

.shopping-cart__content .cart__summary--total {
  -webkit-box-align: end;
  align-items: flex-end;
}

.shopping-cart__content .cart__summary--total p {
  color: var(--iq-darkblack);
  margin-right: 5px;
}

.shopping-cart__content .cart__summary--total span {
  color: var(--iq-darkblack);
}

.shopping-cart__content .cart__summary--balance {
  -webkit-box-align: center;
  align-items: center;
}

.shopping-cart__content .cart__summary--balance p,
.shopping-cart__content .cart__summary--balance span {
  font-size: 14px;
  color: var(--iq-darkgrey);
}

.shopping-cart__content .cart__summary--balance p {
  margin-right: 5px;
}

.shopping-cart__content .cart__summary--topup a {
  font-size: 14px;
  color: var(--iq-pink);
  border-bottom: 1px solid var(--iq-pink);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 991px) {
  .shopping-cart__content .cart__summary--topup a {
    text-align: right;
    border-bottom: 0;
  }
}

.shopping-cart__content .cart__summary--tbpaid {
  -webkit-box-align: end;
  align-items: flex-end;
}

.shopping-cart__content .cart__summary--tbpaid span {
  color: var(--iq-darkblack);
}

.shopping-cart__content .cart__summary--voucher {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 195px;
  max-width: 100%;
}

@media (max-width: 991px) {
  .shopping-cart__content .cart__summary--voucher {
    width: 100%;
    max-width: 100%;
  }
}

.shopping-cart__content .cart__summary--voucher.error .error-text,
.shopping-cart__content .cart__summary--voucher.success .success-text {
  display: block;
}

.shopping-cart__content .cart__summary--voucher input {
  padding: 10px !important;
  height: 40px !important;
  margin-bottom: 10px;
  text-align: center;
}

.shopping-cart__content
  .cart__summary--voucher
  input::-webkit-input-placeholder {
  opacity: 0.5;
  font-size: 13px;
  text-align: left;
}

.shopping-cart__content .cart__summary--voucher input::-moz-placeholder {
  opacity: 0.5;
  font-size: 13px;
  text-align: left;
}

.shopping-cart__content .cart__summary--voucher input:-ms-input-placeholder {
  opacity: 0.5;
  font-size: 13px;
  text-align: left;
}

.shopping-cart__content .cart__summary--voucher input::-ms-input-placeholder {
  opacity: 0.5;
  font-size: 13px;
  text-align: left;
}

.shopping-cart__content .cart__summary--voucher input::placeholder {
  opacity: 0.5;
  font-size: 13px;
  text-align: left;
}

.shopping-cart__content .cart__summary--voucher button {
  height: 35px;
  min-width: 100px;
  background-color: var(--iq-orange);
  color: var(--iq-white);
}

@media (max-width: 991px) {
  .shopping-cart__content .cart__summary--voucher button {
    width: 100%;
    max-width: 100%;
  }
}

.shopping-cart__content .cart__summary--voucher .success-text {
  display: none;
  margin-top: 10px;
}

@media (max-width: 991px) {
  .shopping-cart__content .cart__summary--voucher .error-text {
    width: 100%;
    max-width: 100%;
    text-align: center;
  }
}

.shopping-cart__content .cart__summary--voucher .error-text p {
  font-size: 14px;
  color: var(--iq-darkred);
}

.shopping-cart__content .cart__summary--voucherinput {
  position: relative;
  width: 100%;
  max-width: 100%;
}

.shopping-cart__content .cart__summary--voucherinput .cart__summary--delete {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.shopping-cart__content .cart__summary--voucherinput i {
  color: grey;
  font-size: 21px;
}

.shopping-cart__content .cart__summary--wrapper {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.shopping-cart__content .cart__summary--wrapper > div {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.shopping-cart__content .cart__summary--wrapper > div:not(:last-child) {
  margin-bottom: 12px;
}

.shopping-cart__content .cart__summary--wrapper > div:first-child {
  margin-bottom: 5px;
}

.shopping-cart__content .cart__bottom {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 34px;
}

@media (max-width: 991px) {
  .shopping-cart__content .cart__bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    margin-top: 27px;
  }
}

.shopping-cart__content .cart__bottom--buttons {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
}

@media (max-width: 991px) {
  .shopping-cart__content .cart__bottom--buttons {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse;
    margin-bottom: 15px;
  }
}

.shopping-cart__content .cart__bottom--checkout {
  display: -webkit-box;
  display: flex;
}

.shopping-cart__content .cart__bottom--checkout button {
  width: 180px;
  max-width: 100%;
  margin-left: 15px;
}
.shopping-cart__content .cart__bottom--checkout button:disabled {
  pointer-events: none;
  background-color: var(--iq-dullgrey) !important;
}
@media (max-width: 991px) {
  .shopping-cart__content .cart__bottom--checkout button {
    margin-left: 0;
    width: 100%;
    margin-bottom: 7px;
  }
}

@media (max-width: 991px) {
  .shopping-cart__content .cart__bottom--img {
    display: none;
  }
}

.shopping-cart__content .cart__bottom--text {
  max-width: 533px;
}

@media (min-width: 992px) {
  .shopping-cart__content .cart__bottom--text {
    display: none;
  }
}

.shopping-cart__content .cart__bottom--text p {
  font-size: 13px;
  color: #797979;
  line-height: 26px;
}

@media (max-width: 991px) {
  .shopping-cart__content .cart__bottom--text p {
    font-size: 12px;
  }
}

.shopping-cart__content .cart__bottom--text p a {
  color: var(--iq-orange);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.shopping-cart__content .cart__bottom--text p a:hover {
  color: var(--iq-orange);
}

.shopping-cart__card .shopping-cart__box {
  -webkit-box-pack: unset;
  justify-content: unset;
}

.shopping-cart__card .shopping-cart__box--success {
  margin-bottom: 40px;
}

@media (max-width: 479px) {
  .shopping-cart__card .shopping-cart__box--success {
    margin-bottom: 20px;
  }
}

.shopping-cart__recurring {
  max-width: 950px;
  margin: 0 auto;
}

.shopping-cart__recurring .shopping-cart__box {
  margin-bottom: 40px;
}

@media (max-width: 479px) {
  .shopping-cart__recurring .shopping-cart__box {
    margin-bottom: 20px;
  }
}

.shopping-cart__recurring .shopping-cart__content--bottom p {
  margin-bottom: 40px;
}

@media (max-width: 479px) {
  .shopping-cart__recurring .shopping-cart__content--bottom p {
    margin-bottom: 20px;
  }
}

.shopping-cart__box {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 13px 35px;
}

@media (max-width: 991px) {
  .shopping-cart__box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    padding: 15px;
  }
}

.shopping-cart__box--img img {
  max-width: 100%;
}

.shopping-cart__box--empty .shopping-cart__box--img {
  max-width: 55px;
}

@media (max-width: 991px) {
  .shopping-cart__box--empty .shopping-cart__box--img {
    margin-bottom: 15px;
  }
}

.shopping-cart__box--empty .shopping-cart__box--right {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

@media (max-width: 991px) {
  .shopping-cart__box--empty .shopping-cart__box--right {
    width: 100%;
    max-width: 100%;
  }
}

.shopping-cart__box--empty .shopping-cart__box--right a {
  width: 163px;
  max-width: 100%;
}

@media (max-width: 991px) {
  .shopping-cart__box--empty .shopping-cart__box--right a {
    width: 100%;
    max-width: 100%;
  }
}

.shopping-cart__box--empty p {
  text-transform: uppercase;
  font-size: 30px;
  line-height: 35px;
  color: var(--iq-white);
  text-align: center;
}

@media (max-width: 991px) {
  .shopping-cart__box--empty p {
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    margin-bottom: 20px;
  }
}

.shopping-cart__box--empty a {
  color: var(--iq-white);
  font-size: 18px;
  letter-spacing: 0.5px;
  width: unset;
  border-radius: 15px;
  background: none;
  border: 1px solid var(--iq-white);
  text-transform: uppercase;
  padding: 9px 21px;
  margin: 15px 20px;
}

@media (max-width: 991px) {
  .shopping-cart__box--empty a {
    margin: 0;
    font-size: 20px;
    padding: 4px 15px;
  }
}

@media (max-width: 991px) {
  .shopping-cart__box--success .shopping-cart__box--img {
    margin-bottom: 20px;
  }
}

.shopping-cart__box--success .shopping-cart__box--right {
  display: -webkit-box;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

@media (max-width: 991px) {
  .shopping-cart__box--success .shopping-cart__box--right {
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
    max-width: 100%;
  }
}

.shopping-cart__box--success .shopping-cart__box--right a {
  width: 237px;
  max-width: 100%;
}

@media (max-width: 991px) {
  .shopping-cart__box--success .shopping-cart__box--right a {
    width: 100%;
  }
}

.shopping-cart__box--success .shopping-cart__box--right p {
  font-size: 30px;
  line-height: 35px;
  color: var(--iq-black);
  margin-bottom: 11px;
  text-align: center;
}

@media (max-width: 991px) {
  .shopping-cart__box--success .shopping-cart__box--right p {
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    margin-bottom: 20px;
  }
}

.shopping-cart__box--success .shopping-cart__box--right p span {
  color: var(--iq-yellow);
}

.shopping-cart__box--success .shopping-cart__box--right a {
  color: var(--iq-white);
  font-size: 16px;
  letter-spacing: 0.5px;
  width: unset;
  border-radius: 15px;
  background: none;
  border: 1px solid var(--iq-white);
  text-transform: uppercase;
  padding: 9px 21px;
  margin: 15px 20px;
}

@media (max-width: 991px) {
  .shopping-cart__box--success .shopping-cart__box--right a {
    margin: 0;
    font-size: 16px;
    padding: 4px 15px;
  }
}

@media (max-width: 575px) {
  .shopping-cart__box--success.secondary {
    padding: 15px 0 0;
  }
}
.shopping-cart__box .shopping-cart__box--right p.description {
  font-size: 18px;
}
.shopping-cart__box--right a {
  background-color: var(--iq-orange) !important;
}

.shopping-cart__box--withdraw-success {
  max-width: 950px;
  margin: 0 auto;
  background-color: var(--iq-mildgrey);
  border-radius: 32px;
  padding: 35px 60px 20px;
}

@media (max-width: 991px) {
  .shopping-cart__box--withdraw-success {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    padding: 15px;
  }
}

.shopping-cart__box--withdraw-success .shopping-cart__box--img {
  max-width: 155px;
}

@media (max-width: 991px) {
  .shopping-cart__box--withdraw-success .shopping-cart__box--img {
    margin-bottom: 20px;
  }
}

.shopping-cart__box--withdraw-success .shopping-cart__box--right {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

@media (max-width: 991px) {
  .shopping-cart__box--withdraw-success .shopping-cart__box--right {
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
  }
}

.shopping-cart__box--withdraw-success .shopping-cart__box--right a {
  width: 237px;
  max-width: 100%;
}

@media (max-width: 991px) {
  .shopping-cart__box--withdraw-success .shopping-cart__box--right a {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .shopping-cart__box--withdraw-success .shopping-cart__box--right p {
    font-size: 17px;
    max-width: 315px;
    text-align: center;
    margin-bottom: 20px;
    font-size: 17px;
  }
}

.shopping-cart__box--buttons {
  display: -webkit-box;
  display: flex;
}

@media (max-width: 575px) {
  .shopping-cart__box--buttons {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    background-color: var(--iq-white);
    width: 100%;
    padding-top: 15px;
  }
}

.shopping-cart__box--buttons a:first-child {
  margin-right: 30px;
}

@media (max-width: 575px) {
  .shopping-cart__box--buttons a:first-child {
    margin-bottom: 15px;
    margin-right: 0;
  }
}

.betNum {
  width: 32px;
  height: 32px;
  background-color: var(--iq-white);
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  margin: 0 7px 7px 0;
  cursor: pointer;
}

.betNum:hover {
  border-color: #fce300;
}

.betNumMarked {
  background-image: url(../img/yellow-ball-small.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  border-color: var(--iq-black);
}

.my-profile__nav {
  border-bottom: 1px solid var(--iq-orange);
}

@media (max-width: 575px) {
  .my-profile__nav {
    display: none;
  }
}

.my-profile__nav ul {
  padding-left: 0;
  list-style-type: none;
  display: -webkit-box;
  display: flex;
  margin-bottom: 0;
}

.my-profile__nav ul li {
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.my-profile__nav ul li:first-child a {
  padding-left: 35px;
}

.my-profile__nav ul li a {
  color: var(--iq-darkgrey);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  padding: 17px 20px 16px;
  display: block;
  border-bottom: 1px solid transparent;
}

.my-profile__nav ul li a.active,
.my-profile__nav ul li a:hover {
  color: var(--iq-white);
  background-color: var(--iq-orange);
}
.my-profile__nav ul li a:hover {
  color: var(--iq-darkblack) !important;
}
.my-profile__content {
  padding: 35px 35px 60px;
}

@media (max-width: 575px) {
  .my-profile__content {
    padding: 15px;
  }
}

.my-profile__content--bottom-text {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid var(--iq-dullgrey);
}

.my-profile__content--bottom-text p {
  font-size: 14px;
  color: var(--iq-darkgrey);
  line-height: 25px;
  margin-bottom: 15px !important;
}

.my-profile__content--bottom-text a {
  color: var(--iq-pink);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.myaccount {
  margin: 60px 0 145px;
}

@media (max-width: 992px) {
  .myaccount {
    margin: 10px 0 70px;
  }

  .myaccount__wrapper h2,
  .myaccount__content--heading h3 {
    font-size: 20px;
  }

  .myaccount__wrapper h2 {
    margin-bottom: 25px;
  }
}

@media (max-width: 575px) {
  .myaccount {
    margin: 25px 0 60px;
  }
}

.myaccount__content {
  border-radius: 0 4px 4px 0;
  min-height: 800px;
}

@media (max-width: 991px) {
  .myaccount__content {
    min-height: unset;
  }
}

@media (max-width: 575px) {
  .myaccount__content {
    border: none !important;
  }
}

.myaccount__content--heading h3 {
  color: #434343;
  margin-bottom: 33px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--iq-orange);
  font-size: 24px;
}

.myaccount__wrapper h2 {
  color: var(--iq-black);
  margin-bottom: 20px;
  text-transform: capitalize;
}

@media (max-width: 575px) {
  .myaccount__content--heading h3 {
    display: none;
    font-size: 18px;
    margin-bottom: 18px;
  }

  .myaccount__wrapper h2 {
    display: none;
  }
}

.myaccount__wrapper .myaccount--col:first-child {
  padding-right: 0;
}

@media (max-width: 991px) {
  .myaccount__wrapper .myaccount--col:first-child {
    display: none;
  }
}

.myaccount__wrapper .myaccount--col:last-child {
  padding-left: 0;
}

@media (max-width: 991px) {
  .myaccount__wrapper .myaccount--col:last-child {
    padding-left: 15px;
  }
}

@media (max-width: 575px) {
  .myaccount__wrapper .myaccount--col.mobile:first-child {
    display: block;
    padding-right: 15px;
  }
}

@media (min-width: 576px) {
  .myaccount__heading {
    display: none;
  }
}

.myaccount__heading h2 {
  color: var(--iq-black);
  font-size: 18px;
  display: block;
  margin-bottom: 30px;
}

.myaccount__sidebar {
  background: #f6f6f6;
  border-radius: 4px 0 0 4px;
  height: 100%;
  max-height: 100%;
  border: 1px solid var(--iq-orange);
  border-right: none;
}

.myaccount__sidebar ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.myaccount__sidebar ul li:first-child a {
  border-top-left-radius: 4px;
}

.myaccount__sidebar ul li a {
  color: var(--iq-darkblack);
  padding: 21px 20px 14px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-transform: capitalize;
  border-right: 1px solid var(--iq-white);
}

.myaccount__sidebar ul li a.active {
  color: var(--iq-white);
  background-color: var(--iq-orange);
  border-bottom: 1px solid var(--iq-white);
  padding: 21px 20px 14px;
}

.myaccount__sidebar ul li a.active:after {
  opacity: 1;
}

.myaccount__sidebar ul li a:hover {
  color: var(--iq-darkblack);
  background-color: var(--iq-orange);
  padding: 21px 20px 14px;
}

html[lang="ar"] .myaccount__sidebar ul li a {
  border-left: 1px solid var(--iq-white);
}

.myaccount__sidebar ul li a:hover:after {
  opacity: 1;
}

.myaccount__sidebar.mobile {
  border: none;
  background-color: var(--iq-white);
  height: unset;
}

.myaccount__sidebar.mobile ul li {
  border-bottom: none;
}

.myaccount__sidebar.mobile ul li a {
  background-color: var(--iq-white);
  border-bottom: 1px solid var(--iq-dullgrey);
}

.myaccount__content {
  border: 1px solid var(--iq-orange);
}

.myaccount__page-top {
  background-color: #f7f7f7;
  padding: 25px 20px 20px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 4px 4px 0 0;
}

.myaccount__page-top h3 {
  font-size: 18px;
  color: var(--iq-darkgrey);
  margin-bottom: 10px;
}

.myaccount__page-top h3 span {
  font-size: 18px;
  margin-left: 5px;
}

.myaccount__page-top p {
  font-size: 18px;
  color: var(--iq-darkblack);
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;
}

.myaccount__page-top p span {
  font-size: 18px;
  color: var(--iq-pink);
}

.manage-account .my-profile__content--bottom-text {
  border-top: none;
  padding-top: 0;
}

.manage-account .my-profile__content--bottom-text p:not(:last-child) {
  margin-bottom: 15px;
}

.ticket-history {
  padding: 32px 40px 40px;
}

.ticket-history .myaccount__content--heading h3 {
  margin-bottom: 0;
}

@media (max-width: 575px) {
  .ticket-history {
    padding: 20px 12px;
  }
}

@media (max-width: 575px) {
  .ticket-history--empty {
    padding: 0;
    border: none;
  }
}

.ticket-history__item {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 25px 0;
}

@media (max-width: 1199px) {
  .ticket-history__item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

@media (max-width: 575px) {
  .ticket-history__item {
    padding: 20px 0;
  }
}

.ticket-history__item:first-child {
  border-bottom: 1px solid var(--iq-dullgrey);
}

.ticket-history__item:not(:last-child) {
  border-bottom: 1px solid var(--iq-dullgrey);
}

.ticket-history__raffle {
  text-align: center;
}

@media (max-width: 1199px) {
  .ticket-history__raffle {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.ticket-history__raffle h4 {
  color: var(--iq-black);
  margin-bottom: 15px;
}

@media (max-width: 370px) {
  .ticket-history__raffle h4 {
    font-size: 13px;
  }
}

@media (max-width: 370px) {
  .ticket-history__raffle--col {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
  }
}

.ticket-history__raffle--col .ticket-history__raffle--raffleid {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 4px 10px;
  border: 2px solid var(--iq-white);
  border-radius: 50%;
}

.ticket-history__raffle--col
  .ticket-history__raffle--raffleid:not(:last-child) {
  margin-bottom: 18px;
}

.ticket-history__raffle--col .ticket-history__raffle--raffleid.raffleid-win {
  border: 2px solid var(--iq-black);
}

.ticket-history__raffle--col p {
  color: var(--iq-black);
  letter-spacing: 1px;
  font-size: 17px;
}

.ticket-history__details {
  display: -webkit-box;
  display: flex;
}

@media (max-width: 1199px) {
  .ticket-history__details {
    margin-bottom: 20px;
  }
}

.ticket-history__details--img {
  width: 95px;
  height: 95px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 18px;
}

.ticket-history__details--img img {
  width: 85px;
}

@media (max-width: 575px) {
  .ticket-history__details--img img {
    width: 60px;
  }
}

.ticket-history__details--img img img {
  max-width: 100%;
}

@media (max-width: 479px) {
  .ticket-history__details--img {
    width: 70px;
    height: 70px;
    margin-right: 15px;
  }
}

.ticket-history__details--ticket-wrapper h4 {
  color: var(--iq-pink);
  margin-bottom: 15px;
}

.ticket-history__details--ticket {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.ticket-history__details--ticket.ticket-id {
  margin: 15px 0;
}

@media (max-width: 350px) {
  .ticket-history__details--ticket.ticket-id span {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.ticket-history__details--ticket p {
  color: var(--iq-black);
  margin-bottom: 0;
  margin-right: 5px;
}

@media (max-width: 370px) {
  .ticket-history__details--ticket p {
    font-size: 13px;
  }
}

.ticket-history__details--ticket span {
  color: var(--iq-black);
}

@media (max-width: 370px) {
  .ticket-history__details--ticket span {
    font-size: 13px;
  }
}

.ticket-history__details--ticket span.win {
  color: #84bd00;
}

.ticket-history__details--ticket a {
  font-size: 14px;
  color: var(--iq-pink);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 370px) {
  .ticket-history__details--ticket a {
    font-size: 13px;
  }
}

.ticket-history__details--ticket a.btn {
  width: 224px;
  max-width: 100%;
  height: 40px;
  padding: 10px 20px 8px;
  font-size: 17px;
  color: var(--iq-white);
}

@media (max-width: 370px) {
  .ticket-history__details--ticket a.btn {
    width: 197px;
  }
}

@media (max-width: 350px) {
  .ticket-history__details--ticket a.btn {
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .ticket-history__numbers {
    margin-bottom: 20px;
  }
}

.ticket-history__numbers h4 {
  color: var(--iq-black);
  text-align: center;
  margin-bottom: 15px;
}

@media (max-width: 370px) {
  .ticket-history__numbers h4 {
    font-size: 13px;
  }
}

.ticket-history__numbers--row {
  display: -webkit-box;
  display: flex;
}

.ticket-history__numbers--row:not(:last-child) {
  margin-bottom: 20px;
}

.ticket-history__numbers--row .betNum {
  margin-bottom: 0;
}

.ticket-history__numbers--row .betNum:not(:last-child) {
  margin-right: 5px;
}

.ticket-history__number-wrapper {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 46%;
}

@media (max-width: 1199px) {
  .ticket-history__number-wrapper {
    width: 100%;
    max-width: 100%;
  }
}

.ticket-history__bottom {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin-top: 40px;
}

@media (max-width: 575px) {
  .ticket-history__bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

@media (max-width: 575px) {
  .ticket-history__bottom--text {
    margin-top: 20px;
  }
}

.ticket-history__bottom--text p {
  color: var(--iq-black);
}

@media (max-width: 479px) {
  .ticket-history__bottom--text p {
    font-size: 13px;
  }
}

.ticket-history__box {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 13px;

  background: var(--iq-pink);
  border-radius: 5px;
  margin-top: 40px;
}

@media (max-width: 575px) {
  .ticket-history__box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    padding: 15px;
    margin-top: 0;
  }
}

.ticket-history__box p {
  font-size: 20px;
  color: var(--iq-white);
  margin-left: 30px;
}

@media (max-width: 575px) {
  .ticket-history__box p {
    margin-left: 0;
    text-align: center;
  }
}

@media (max-width: 479px) {
  .ticket-history__box p {
    font-size: 18px;
  }
}

.ticket-history__box--img {
  max-width: 55px;
}

@media (max-width: 575px) {
  .ticket-history__box--img {
    margin-bottom: 15px;
  }
}

.ticket-history__box--img img {
  max-width: 100%;
}

.ticket-history__box--right {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

@media (max-width: 575px) {
  .ticket-history__box--right {
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
  }
}

.ticket-history__box--right a {
  color: var(--iq-white);
  font-size: 17px;
  letter-spacing: 0.5px;
  width: unset;
  border-radius: 5px;
  background: none;
  border: 1px solid var(--iq-white);
  text-transform: uppercase;
  padding: 9px 21px;
}

@media (max-width: 991px) {
  .ticket-history__box--right a {
    font-size: 17px;
    padding: 4px 15px;
  }
}

.pagination ul {
  padding-left: 0;
  display: -webkit-box;
  display: flex;
  margin-bottom: 0;
}

.pagination ul li {
  list-style-type: none;
  width: 34px;
  height: 34px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 5px;
  position: relative;
}

.pagination ul li:not(:last-child) {
  margin-right: 7px;
}

@media (max-width: 479px) {
  .pagination ul li:not(:last-child) {
    margin-right: 5px !important;
  }
  .pagination ul li {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 350px) {
  .pagination ul li {
    width: 25px;
    height: 25px;
  }
}

.pagination ul li a {
  color: var(--iq-darkblack);
  padding: 11px 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: var(--iq-white);
  border: 1px solid var(--iq-dullgrey);
  font-family: Product-Sans-Regular;
}

@media (max-width: 479px) {
  .pagination ul li a {
    font-size: 13px;
  }
}

@media (max-width: 350px) {
  .pagination ul li a {
    font-size: 12px;
  }
}

.pagination ul li a.current {
  background: rgba(252, 227, 0, 0.3);
  border-color: #fce300;
}

.pagination ul li a i {
  color: var(--iq-darkblack);
  margin-bottom: 5px;
}

.favourite-numbers {
  padding: 32px 40px 40px;
}

@media (max-width: 575px) {
  .favourite-numbers {
    padding: 0;
    border: none;
  }
}

@media (max-width: 575px) {
  .favourite-numbers .myaccount__content--heading h3,
  .favourite-numbers__favourites h4 {
    display: block;
    border-bottom: none;
    background-color: var(--iq-orange);
    padding: 5px;
    font-size: 16px !important;
    color: var(--iq-white);
  }

  .favourite-numbers__favourites h4 {
    margin-bottom: 15px;
  }

  .favourite-numbers__content input:not([type="text"]) {
    height: 35px;
    padding: 10px !important;
    font-size: 14px;
  }
}

.favourite-numbers__content .form form .form-group {
  margin-bottom: 18px;
}

.favourite-numbers__content .form form .form-group__input-row {
  display: -webkit-box;
  display: flex;
}

.favourite-numbers__content .form form .form-group__input-row input {
  width: 85px;
  text-align: center;
  min-width: 1px;
}

.favourite-numbers__content
  .form
  form
  .form-group__input-row
  input:not(:last-child) {
  margin-right: 13px;
}

@media (max-width: 479px) {
  .favourite-numbers__content
    .form
    form
    .form-group__input-row
    input:not(:last-child) {
    margin-right: 6px;
  }
}

.favourite-numbers__content
  .form
  form
  .form-group__input-row
  input::-webkit-input-placeholder {
  color: #ccc;
}

.favourite-numbers__content
  .form
  form
  .form-group__input-row
  input::-moz-placeholder {
  color: #ccc;
}

.favourite-numbers__content
  .form
  form
  .form-group__input-row
  input:-ms-input-placeholder {
  color: #ccc;
}

.favourite-numbers__content
  .form
  form
  .form-group__input-row
  input::-ms-input-placeholder {
  color: #ccc;
}

.favourite-numbers__content
  .form
  form
  .form-group__input-row
  input::placeholder {
  color: #ccc;
}

@media (max-width: 575px) {
  .favourite-numbers__content .form form .form-group__input-row input {
    width: 43px;
    height: 42px;
    padding: 10px 5px 5px !important;
  }
}

.favourite-numbers__content .form form a {
  width: 200px;
  max-width: 100%;
  margin-top: 40px;
}

@media (max-width: 575px) {
  .favourite-numbers__content .form form a {
    margin-top: 20px;
  }
}

@media (max-width: 479px) {
  .favourite-numbers__content .form form a {
    width: 100%;
  }
}

.favourite-numbers__content .form form .error-text p {
  font-size: 14px;
  color: var(--iq-darkred);
  margin-top: 15px;
}

.favourite-numbers__favourites {
  margin-top: 45px;
}

@media (max-width: 575px) {
  .favourite-numbers__favourites {
    margin-top: 28px;
  }

  .favourite-numbers__content .form form .error-text p {
    font-size: 12px;
  }
}

.favourite-numbers__favourites h4 {
  color: #434343;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 24px;
}

.favourite-numbers__favourites--wrapper {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.form_favourite-numbers input {
  border-color: #fdad75;
}

.favourite-numbers__item:nth-child(3),
.favourite-numbers__item:nth-child(6),
.favourite-numbers__item:nth-child(9) {
  margin-right: 0;
}

.favourite-numbers__item {
  max-width: 200px;
  background-color: #f6f6f6;
  padding: 6px;
  border-radius: 8px;
  margin-bottom: 25px;
  border: 1px solid var(--iq-pink);
  margin-right: 8px;
  width: calc(33.33% - 8px);
}

.myaccount__content.favourite-numbers .favourite-numbers__content {
  max-width: 600px;
}

.remodal form .form-group__buttons .btn.disabled,
.form_favourite-numbers .btn.disabled {
  pointer-events: none;
}

.form_favourite-numbers .btn.btn--primary {
  color: var(--iq-white);
  font-family: Product-Sans-Bold;
  margin-top: 40px;
  padding: 0 48px;
  font-size: 18px;
}

.form_favourite-numbers input::-webkit-input-placeholder,
.form_favourite-numbers input::placeholder,
#form_add-favourite input::-webkit-input-placeholder,
#form_add-favourite input::placeholder {
  color: #ccc;
}

@media (max-width: 1199px) {
  .form_favourite-numbers .btn.btn--primary {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .favourite-numbers__item .favourite-numbers__item--numbers span {
    height: 20px;
    width: 20px;
  }

  .form_favourite-numbers .btn.btn--primary {
    font-size: 16px;
  }
}

@media (max-width: 575px) {
  .favourite-numbers__item {
    width: calc(50% - 5px);
    max-width: 45%;
  }

  .favourite-numbers__item:nth-child(odd) {
    margin: 0 auto 20px 0;
  }

  .favourite-numbers__item:nth-child(even) {
    margin: 0 0 20px auto;
  }

  .form_favourite-numbers .btn.btn--primary {
    font-size: 12px;
    margin-top: 20px;
    padding: 7px 15px;
    height: 40px;
    width: 100%;
  }
}

.favourite-numbers__item--heading {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 5px;
}

.favourite-numbers__item--heading h5 {
  margin: 0;
  color: #434343;
  font-size: 15px;
}

.favourite-numbers__item--heading a {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.favourite-numbers__item--heading a:hover {
  opacity: 0.5;
}

.favourite-numbers__item--heading a img {
  max-width: 100%;
}

.favourite-numbers__item--numbers span {
  color: #3d3737;
  border: 1px solid var(--iq-pink);
  padding: 5px;
  border-radius: 50%;
  font-size: 10px;
  display: inline-flex;
  height: 28px;
  width: 28px;
  justify-content: center;
  align-items: center;
  background-color: var(--iq-white);
}

.favourite-numbers__item--numbers span:not(:last-child) {
  margin-right: 4px;
}

.page-rtl .favourite-numbers__item--numbers span:not(:last-child) {
  margin: 0 0 0 4px;
}

.my-games {
  padding: 32px 40px 40px;
}

.my-games .myaccount__content--heading h3 {
  margin-bottom: 0;
}

@media (max-width: 575px) {
  .my-games {
    padding: 20px 15px;
  }

  .my-games--empty {
    padding: 0;
    border: none;
  }

  .favourite-numbers__item--numbers span {
    height: 24px;
    width: 24px;
  }
}

.my-games__content .form form .form-group {
  margin-bottom: 18px;
}

.my-games__content .form form .form-group__input-row {
  display: -webkit-box;
  display: flex;
}

.my-games__content .form form .form-group__input-row input {
  width: 85px;
  text-align: center;
}

.my-games__content .form form .form-group__input-row input:not(:last-child) {
  margin-right: 13px;
}

.my-games__content
  .form
  form
  .form-group__input-row
  input::-webkit-input-placeholder {
  color: #ccc;
}

.my-games__content .form form .form-group__input-row input::-moz-placeholder {
  color: #ccc;
}

.my-games__content
  .form
  form
  .form-group__input-row
  input:-ms-input-placeholder {
  color: #ccc;
}

.my-games__content
  .form
  form
  .form-group__input-row
  input::-ms-input-placeholder {
  color: #ccc;
}

.my-games__content .form form .form-group__input-row input::placeholder {
  color: #ccc;
}

.my-games__content .form form button {
  width: 200px;
  margin-top: 40px;
}

@media (max-width: 575px) {
  .my-games__content--item-wrapper:first-child .my-games__item {
    padding-top: 0;
  }
}

.my-games__item {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 25px 0;
}

@media (max-width: 1199px) {
  .my-games__item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

@media (max-width: 575px) {
  .my-games__item {
    padding: 20px 0;
  }
}

.my-games__item:first-child {
  border-bottom: 1px solid var(--iq-dullgrey);
}

@media (max-width: 575px) {
  .my-games__item:last-child {
    padding-bottom: 0;
  }
}

.my-games__item:not(:last-child) {
  border-bottom: 1px solid var(--iq-dullgrey);
}

.my-games__details {
  display: -webkit-box;
  display: flex;
}

@media (max-width: 1199px) {
  .my-games__details {
    margin-bottom: 20px;
  }
}

.my-games__details--img {
  width: 95px;
  height: 95px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 18px;
}

.my-games__details--img img {
  width: 85px;
}

@media (max-width: 479px) {
  .my-games__details--img img {
    width: 60px;
  }
}

.my-games__details--img img img {
  max-width: 100%;
}

@media (max-width: 479px) {
  .my-games__details--img {
    width: 70px;
    height: 70px;
    margin-right: 15px;
  }
}

.my-games__details--ticket-wrapper h4 {
  color: var(--iq-pink);
  margin-bottom: 15px;
}

.my-games__details--ticket {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.my-games__details--ticket.ticket-id {
  margin: 15px 0;
}

@media (max-width: 350px) {
  .my-games__details--ticket.ticket-id span {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.my-games__details--ticket p {
  color: var(--iq-black);
  margin-bottom: 0;
  margin-right: 5px;
}

@media (max-width: 375px) {
  .my-games__details--ticket p {
    font-size: 13px;
  }
}

.my-games__details--ticket span {
  color: var(--iq-black);
}

@media (max-width: 375px) {
  .my-games__details--ticket span {
    font-size: 13px;
  }
}

.my-games__details--ticket span.win {
  color: #84bd00;
}

.my-games__details--ticket a {
  font-size: 14px;
  color: var(--iq-pink);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 375px) {
  .my-games__details--ticket a {
    font-size: 13px;
  }
}

.my-games__number-wrapper {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 46%;
}

@media (max-width: 1199px) {
  .my-games__number-wrapper {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 1199px) {
  .my-games__numbers {
    margin-bottom: 20px;
  }
}

.my-games__numbers h4 {
  color: var(--iq-black);
  text-align: center;
  margin-bottom: 15px;
}

@media (max-width: 370px) {
  .my-games__numbers h4 {
    font-size: 13px;
  }
}

.my-games__numbers--row {
  display: -webkit-box;
  display: flex;
}

.my-games__numbers--row:not(:last-child) {
  margin-bottom: 20px;
}

.my-games__numbers--row .betNum {
  margin-bottom: 0;
}

.my-games__numbers--row .betNum:not(:last-child) {
  margin-right: 5px;
}

.my-games__raffle {
  text-align: center;
}

@media (max-width: 1199px) {
  .my-games__raffle {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.my-games__raffle h4 {
  color: var(--iq-black);
  margin-bottom: 15px;
}

@media (max-width: 370px) {
  .my-games__raffle h4 {
    font-size: 13px;
  }
}

@media (max-width: 370px) {
  .my-games__raffle--col {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
  }
}

.my-games__raffle--col .my-games__raffle--raffleid {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 4px 10px;
  border: 2px solid var(--iq-white);
  border-radius: 50%;
}

.my-games__raffle--col .my-games__raffle--raffleid:not(:last-child) {
  margin-bottom: 13px;
}

.my-games__raffle--col .my-games__raffle--raffleid.raffleid-win {
  border: 2px solid var(--iq-black);
}

.my-games__raffle--col p {
  color: var(--iq-black);
  letter-spacing: 1px;
  font-size: 18px;
}

@media (max-width: 370px) {
  .my-games__raffle--col p {
    font-size: 17px;
  }
}

.my-games__buttons a {
  width: 156px;
  max-width: 100%;
  margin-bottom: 10px;
}

@media (max-width: 575px) {
  .my-games__buttons a {
    margin-bottom: 20px;
  }
}

@media (max-width: 479px) {
  .my-games__buttons a {
    width: 100%;
  }
}

.my-games__bottom {
  margin-top: 40px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  align-items: flex-start;
}

@media (max-width: 575px) {
  .my-games__bottom {
    margin-top: 15px;
  }
}

@media (max-width: 575px) {
  .my-games__bottom,
  .my-games__bottom--right {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
  }

  .my-games__bottom--right {
    margin-top: 20px;
  }
}

.my-games__bottom--text {
  color: var(--iq-darkgrey);
  margin-bottom: 40px;
}

@media (max-width: 575px) {
  .my-games__bottom--text {
    margin-bottom: 25px;
  }
}

@media (max-width: 479px) {
  .my-games__bottom--text {
    font-size: 13px;
  }
}

.my-games__bottom--button {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.my-games__bottom--button a {
  width: 185px;
}

@media (max-width: 575px) {
  .my-games__bottom--button a {
    width: 100%;
    max-width: 100%;
  }
}
.messages {
  padding: 32px 40px 40px;
}

@media (max-width: 575px) {
  .messages {
    padding: 0;
    border: none;
  }
}

.messages__bottom {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}

@media (max-width: 575px) {
  .messages__bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

@media (max-width: 575px) {
  .messages__bottom--text {
    margin-top: 20px;
  }
}

.messages__bottom--text p {
  color: var(--iq-black);
}

@media (max-width: 575px) {
  .messages__table table tbody tr td {
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
}

@media (min-width: 576px) {
  .messages__table table tbody tr td a {
    text-decoration: none !important;
  }
}

.table-main {
  margin: 20px 0;
}

@media (max-width: 575px) {
  .table-main {
    margin-bottom: 40px;
  }
}

.table-main table {
  width: 100%;
  max-width: 100%;
}

.table-main table thead {
  background-color: var(--iq-orange);
}

@media (max-width: 575px) {
  .table-main table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}

.table-main table thead tr th {
  color: var(--iq-white);
  text-align: left;
  font-size: 0.9rem;
  padding: 11px 10px 11px 8px;
  font-family: Product-Sans-Bold;
}

:lang(ar) .table-main table thead tr th {
  font-family: Swissra-Bold;
}

.table-main table thead tr th:not(:last-child) {
  border-right: 2px solid var(--iq-white);
}

html[lang="ar"] .table-main table thead tr th:not(:last-child) {
  border-left: 2px solid var(--iq-white);
}

.table-main table thead tr th:first-child {
  border-radius: 5px 0 0 0;
}

.table-main table thead tr th:last-child {
  border-radius: 0 5px 0 0;
}

@media (max-width: 575px) {
  .table-main table tbody {
    border: none;
    border-radius: none;
  }
}

.table-main table tbody tr {
  background-color: var(--iq-white);
}

.table-main table tbody tr:nth-child(2n) {
  background-color: #f6f6f6;
}

@media (max-width: 575px) {
  .table-main table tbody tr:nth-child(2n) {
    background-color: inherit;
  }
}

@media (max-width: 575px) {
  .table-main table tbody tr {
    display: block;
    border: 1px solid var(--iq-dullgrey);
  }
}

@media (max-width: 575px) {
  .table-main table tbody tr:not(:last-child) {
    margin-bottom: 18px;
  }
}

.table-main table tbody tr td {
  color: var(--iq-darkblack);
  padding: 12px 14px;
  text-align: left;
}

@media (max-width: 575px) {
  .table-main table tbody tr td {
    display: -webkit-box;
    display: flex;
    padding: 15px 15px 12px;
    position: relative;
  }
}

@media (max-width: 575px) {
  .table-main table tbody tr td:nth-child(odd) {
    background-color: #f7f7f7;
  }
}

.table-main table tbody tr td:before {
  content: attr(data-label);
  color: var(--iq-pink);
  display: none;
}

@media (max-width: 575px) {
  .table-main table tbody tr td:before {
    display: block;
  }
}

.table-main table tbody tr td a,
.balance-summary__mobile_table table tbody tr td a {
  color: var(--iq-pink);
  text-decoration: underline;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.table-main table tbody tr td a:hover,
.balance-summary__mobile_table table tbody tr td a:hover {
  color: var(--iq-pink);
  text-decoration: none;
}

.table-main table tbody tr td.amount-decrease {
  color: var(--iq-darkred);
}

.table-main table tbody tr td.amount-increase {
  color: #84bd00;
}

.balance-summary {
  padding: 35px 12px 5px 12px;
}

@media (max-width: 575px) {
  .balance-summary {
    padding: 0;
    border: none;
  }
}

.balance-summary__table table tbody tr td:first-child {
  color: var(--iq-darkblack);
}

.balance-summary__bottom {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}

@media (max-width: 575px) {
  .balance-summary__bottom {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
}

@media (max-width: 575px) {
  .balance-summary__bottom--text {
    margin-top: 20px;
  }
}

.balance-summary__bottom--text p {
  color: var(--iq-black);
}

@media (max-width: 575px) {
  .balance-summary__table table tbody tr td {
    -webkit-box-pack: end;
    justify-content: flex-end;
  }
}

@media (max-width: 575px) {
  .balance-summary__table table tbody tr td:before {
    position: absolute;
    left: 15px;
  }
}

@media (max-width: 575px) {
  .balance-summary__table table tbody tr td:nth-child(4) {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: end;
    align-items: flex-end;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  .balance-summary__table table tbody tr td:nth-child(4):before {
    top: 30px;
  }
}

@media (max-width: 575px) {
  .balance-summary__table table tbody tr td .balance-summary__currencies {
    text-align: right;
  }
}

.balance-summary__table table tbody tr td .balance-summary__currencies p {
  white-space: nowrap;
  text-align: left;
}

@media (max-width: 575px) {
  .balance-summary__table table tbody tr td a {
    margin-left: 10px;
  }
}

.back-btn {
  width: 175px;
  background-color: var(--iq-orange);
}

.confirm-btn {
  width: 175px !important;
}

.page--total-balance .myaccount--col.mobile {
  padding: 0 !important;
}

.page--total-balance .myaccount__heading h2 {
  margin-bottom: 20px;
}

.total-balance__top {
  background-color: var(--iq-mildgrey);
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 15px;
}

.total-balance__top h3 {
  color: var(--iq-darkblack);
  margin-bottom: 0;
}

.total-balance__top span {
  font-size: 18px;
  color: var(--iq-pink);
}

.total-balance__wrapper {
  padding: 0 15px;
}

.total-balance__balance {
  padding: 20px 0;
}

.total-balance__balance h4 {
  color: var(--iq-darkblack);
}

.total-balance__balance:not(:last-child) {
  border-bottom: 1px solid var(--iq-dullgrey);
}

.total-balance__balance--number {
  background: hsla(0, 0%, 78.4%, 0.25);
  border-radius: 6px;
  padding: 15px 15px 10px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.total-balance__balance--number span {
  color: var(--iq-darkblack);
  font-size: 17px;
}

.total-balance__bottom {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: end;
  align-items: flex-end;
}

.total-balance__bottom a:first-child {
  margin-bottom: 27px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.total-balance__bottom a:last-child {
  width: 100%;
  max-width: 100%;
}

.payment {
  margin: 60px 0 250px;
}

@media (max-width: 575px) {
  .payment {
    margin: 25px 0 100px;
  }
}

.payment__wrapper {
  max-width: 620px;
  margin: 0 auto;
  position: relative;
}

.payment__wrapper h2 {
  color: var(--iq-darkblack);
  margin-bottom: 20px;
  padding-left: 2px;
  line-height: 40px;
}

@media (max-width: 575px) {
  .payment__wrapper h2 {
    font-size: 18px;
  }
}

.payment__wrapper--previous {
  margin-bottom: 20px;
}

.payment__wrapper--previous,
.payment__wrapper--previous a {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.payment__wrapper--previous a {
  width: 33px;
  height: 33px;
  border: 1px solid var(--iq-dullgrey);
  -webkit-box-pack: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.payment__wrapper--previous a i {
  color: var(--iq-darkblack);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.payment__wrapper--previous a:hover {
  background-color: #fce300;
}

.payment__wrapper--previous h2 {
  margin-top: 3px;
  margin-bottom: 0;
}

.payment__cards {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 25px 0 30px;
  border-bottom: 1px solid var(--iq-dullgrey);
}

.payment__cards--wrapper {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  border-bottom: none;
  padding: 0;
}

.payment__cards--img {
  width: 135px;
}

@media (max-width: 479px) {
  .payment__cards--img {
    width: 100px;
  }
}

.payment__cards--img img {
  max-width: 100%;
  height: 28px;
}

@media (max-width: 479px) {
  .payment__cards--img img {
    height: 24px;
  }
}

.payment__cards--right,
.payment__cards--right a {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.payment__cards--right a {
  width: 25px;
  height: 25px;
  background-color: #fce300;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.payment__cards--right a i {
  color: var(--iq-darkblack);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.payment__cards--right a:hover {
  background-color: #fee555;
}

.payment__cards--right a:hover i {
  color: var(--iq-white);
}

.payment__cards--text {
  margin-right: 25px;
  text-align: right;
}

@media (max-width: 575px) {
  .payment__cards--text {
    margin-right: 10px;
  }
}

.payment__cards--text h3 {
  color: var(--iq-darkblack);
  margin-bottom: 0;
}

.payment__cards--text p {
  font-size: 14px;
  color: var(--iq-darkgrey);
}

.payment__card {
  width: 100%;
  max-width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 20px 10px;
  border-bottom: 1px solid var(--iq-dullgrey);
}

@media (max-width: 575px) {
  .payment__card {
    padding: 20px 0;
  }
}

.payment__card--img {
  width: 46px;
  max-width: 100%;
}

.payment__card--img img {
  max-width: 100%;
}

.payment__card--right,
.payment__card--right a {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.payment__card--right a {
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.payment__card--right a i {
  color: #b50023;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 23px;
}

.payment__card--right a:hover i {
  color: var(--iq-darkred);
}

.payment__card--text {
  margin-right: 13px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.payment__card--text p {
  color: var(--iq-black);
}

@media (max-width: 575px) {
  .payment__card--text p {
    font-size: 17px;
  }
}

@media (max-width: 479px) {
  .payment__card--text p {
    font-size: 12px;
  }
}

.payment__card--card,
.payment__card--checkbox {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.payment__card--checkbox {
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
}

.payment__card--checkbox input {
  position: absolute;
  cursor: pointer;
}

.payment__card--checkbox input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 28px;
  height: 28px;
  -webkit-box-flex: 0;
  flex: 0 0 28px;
  border-radius: 0;
  background-color: var(--iq-white);
  border: 1px solid var(--iq-dullgrey);
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 40px;
}

@media (max-width: 479px) {
  .payment__card--checkbox input[type="radio"] {
    margin-right: 10px;
  }
}

.payment__card--checkbox .checkmark {
  position: absolute;
  left: 7px;
  top: 7px;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  background-color: var(--iq-pink);
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.payment__card--checkbox input:checked ~ .checkmark {
  opacity: 1;
}

.payment__card--checkbox.empty {
  width: 28px;
  height: 28px;
  margin-right: 40px;
}

@media (max-width: 479px) {
  .payment__card--checkbox.empty {
    margin-right: 10px;
  }
}

.payment__card--new-card {
  border-bottom: none;
}

.payment__card--new-card .payment__card--right {
  -webkit-box-pack: end;
  justify-content: flex-end;
  width: 100%;
  max-width: 100%;
}

.payment__empty {
  width: 100%;
  max-width: 100%;
  margin-top: 20px;
  padding: 10px;
}

@media (max-width: 479px) {
  .payment__empty {
    margin-top: 0;
    border: 1px solid var(--iq-orange);
    border-radius: 4px;
  }
}

.payment__empty p {
  color: var(--iq-black);
}

.payment__button {
  width: 100%;
  max-width: 100%;
  margin-top: 50px;
}

@media (max-width: 479px) {
  .payment__button {
    margin-top: 30px;
  }
}

.payment__button button {
  height: 35px;
  padding: 8px 20px;
  width: 140px;
  max-width: 100%;
}

.payment__bottom p {
  font-size: 14px;
  color: #797979;
  margin-top: 18px;
}

.payment--box {
  background-color: var(--iq-pink);
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  padding: 10px;
  border-radius: 5px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  align-items: center;
}

.payment--box,
.payment--box p {
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.payment--box p {
  color: var(--iq-darkblack);
  max-width: 100%;
}

@media (max-width: 479px) {
  .payment--box p {
    font-size: 14px;
  }
}

@media (min-width: 480px) {
  .payment--box p br {
    display: none;
  }
}

.payment--box .input-box--currency {
  font-size: 12px !important;
  font-style: italic;
  width: unset;
}

@media (max-width: 479px) {
  .payment--box-text {
    max-width: 100px;
  }
}

.payment #form_credit-cards_amount p,
.payment #form_payment-method_amount p {
  display: -webkit-box;
  display: flex;
}

.payment #form_credit-cards_amount p span,
.payment #form_payment-method_amount p span {
  width: unset;
  height: unset;
  position: unset;
  background-color: unset;
  color: var(--iq-darkblack);
  padding: 0;
  margin-left: 5px;
  margin-top: 2px;
}

.deposit .payment--box {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.deposit .payment--box-text {
  margin-top: 14px;
  width: unset;
}

@media (max-width: 479px) {
  .deposit .payment--box-text {
    margin-top: 5px;
  }
}

.transfer {
  margin: 60px 0 114px;
}

@media (max-width: 575px) {
  .transfer {
    margin: 25px 0 60px;
  }
}

.transfer--iframe {
  margin: 0;
}

.transfer__wrapper {
  max-width: 620px;
  margin: 0 auto;
}

.transfer__wrapper h2 {
  color: var(--iq-darkblack);
}

@media (max-width: 575px) {
  .transfer__wrapper h2 {
    font-size: 18px;
  }
}

.transfer__wrapper h2 span {
  margin-left: 5px;
}

@media (max-width: 575px) {
  .transfer__wrapper h2 span {
    font-size: 18px;
  }
}

.transfer__wrapper > p {
  font-size: 17px;
  color: var(--iq-darkblack);
  margin-bottom: 20px;
}

@media (max-width: 479px) {
  .transfer__wrapper > p {
    font-size: 14px;
  }
}

.transfer__wrapper > p b {
  font-size: 17px;
}

@media (max-width: 479px) {
  .transfer__wrapper > p b {
    font-size: 14px;
  }
}

.transfer #payment_form .error-text {
  margin-bottom: 15px;
}

.transfer__top {
  background-color: var(--iq-mildgrey);
  padding: 18px;
  border-radius: 5px;
  margin-bottom: 12px;
}

@media (max-width: 575px) {
  .transfer__top {
    padding: 18px 10px;
  }
}

.transfer__top--row {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.transfer__top--row:not(:last-child) {
  margin-bottom: 15px;
}

.transfer__top--row h3 {
  color: var(--iq-darkblack);
  margin-bottom: 0;
}

@media (max-width: 479px) {
  .transfer__top--row h3 {
    font-size: 12px;
  }
}

.transfer__top--row p {
  color: var(--iq-darkblack);
}

@media (max-width: 479px) {
  .transfer__top--row p {
    font-size: 12px;
  }
}

.transfer__input-wrapper {
  padding: 30px 18px;
  background-color: #f1f1f1;
  border-radius: 5px;
  margin-bottom: 30px;
}

@media (max-width: 575px) {
  .transfer__input-wrapper {
    margin-bottom: 15px;
  }
}

.transfer__input-wrapper [class*="col-"]:not(:last-child) {
  margin-bottom: 20px;
}

.transfer__button {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  margin-bottom: 30px;
}

@media (max-width: 575px) {
  .transfer__button {
    margin-bottom: 15px;
  }
}

.transfer__button button {
  width: 236px;
}

@media (max-width: 575px) {
  .transfer__button button {
    width: 100%;
    max-width: 100%;
  }
}

.transfer__bottom {
  background-color: var(--iq-orange);
  padding: 18px 25px;
  border-radius: 5px;
  margin-bottom: 5px;
}

@media (max-width: 575px) {
  .transfer__bottom {
    padding: 18px 10px;
  }
}

.transfer__bottom h3 {
  color: var(--iq-black);
  margin-bottom: 0;
}

.transfer__bottom h3:first-child {
  color: var(--iq-white);
}

.transfer__bottom h3:nth-of-type(2) {
  margin-bottom: 10px;
}

.transfer__bottom > div:first-of-type {
  margin: 20px 0;
}

.transfer__bottom > div:nth-of-type(2) {
  margin-bottom: 10px;
}

.transfer__bottom--row {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}

.transfer__bottom--row h3 {
  color: var(--iq-black) !important;
}

.transfer__right {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

@media (max-width: 479px) {
  .transfer__right p {
    font-size: 12px !important;
  }
}

.transfer__right--amount {
  display: -webkit-box;
  display: flex;
}

.transfer__right--currency {
  margin-top: 10px;
  font-size: 12px !important;
  display: none;
  font-style: italic;
}

@media (max-width: 479px) {
  .transfer__right--currency {
    font-size: 12px;
  }
}

.transfer__right--currency.active {
  display: block;
}

.verification {
  margin: 88px 0;
}

@media (max-width: 479px) {
  .verification {
    margin: 36px 0 80px;
  }
}

.verification__wrapper {
  max-width: 1000px;
  margin: 0 auto;
}

.verification__banner {
  width: 100%;
  max-width: 100%;
  position: relative;
  margin-bottom: 20px;
}

@media (max-width: 575px) {
  .verification__banner.desktop {
    display: none;
  }
}

.verification__banner.mobile {
  display: block;
}

@media (min-width: 576px) {
  .verification__banner.mobile {
    display: none;
  }
}

.verification__banner--bg {
  padding-bottom: 277px;
  border-radius: 5px;
}

@media (max-width: 1199px) {
  .verification__banner--bg {
    background-position: 50%;
  }
}

@media (max-width: 575px) {
  .verification__banner--bg {
    padding-bottom: 530px;
    background-position: unset;
  }
}

@media (max-width: 479px) {
  .verification__banner--bg {
    padding-bottom: 470px;
  }
}

@media (max-width: 400px) {
  .verification__banner--bg {
    padding-bottom: 450px;
  }
}

@media (max-width: 350px) {
  .verification__banner--bg {
    padding-bottom: 435px;
  }
}

.verification__banner img {
  max-width: 100%;
}

.verification__banner h2 {
  color: var(--iq-white);
  position: absolute;
  bottom: 60px;
  left: 70px;
}

@media (max-width: 575px) {
  .verification__banner h2 {
    left: 20px;
    top: 20px;
    max-width: 100px;
  }
}

@media (max-width: 350px) {
  .verification__banner h2 {
    font-size: 22px;
  }
}

.verification__banner--text {
  max-width: 655px;
  position: absolute;
  left: 50px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (max-width: 575px) {
  .verification__banner--text {
    -webkit-transform: unset;
    transform: unset;
    top: unset;
    bottom: 20px;
    max-width: 400px;
    left: 20px;
  }
}

@media (max-width: 479px) {
  .verification__banner--text {
    padding-right: 20px;
  }
}

@media (max-width: 400px) {
  .verification__banner--text {
    max-width: 252px;
    padding-right: 0;
  }
}

.verification__banner--text h3 {
  font-size: 23px;
  color: var(--iq-pink);
  margin-bottom: 20px;
}

@media (max-width: 479px) {
  .verification__banner--text h3 {
    font-size: 20px;
  }
}

@media (max-width: 400px) {
  .verification__banner--text h3 {
    margin-bottom: 15px;
  }
}

.verification__banner--text p {
  color: var(--iq-black);
  line-height: 30px;
  margin-bottom: 20px;
}

@media (max-width: 479px) {
  .verification__banner--text p {
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .verification__banner--text p {
    margin-bottom: 15px;
  }
}

.verification__banner--text a {
  max-width: 200px;
}

@media (max-width: 479px) {
  .verification__banner--text a {
    max-width: 100%;
  }
}

.verification__photo {
  padding-bottom: 46px;
  margin-bottom: 25px;
}

@media (max-width: 479px) {
  .verification__photo {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}

.verification__photo h3 {
  color: var(--iq-darkblack);
  margin-bottom: 30px;
}

@media (max-width: 479px) {
  .verification__photo h3 {
    font-size: 17px;
    margin-bottom: 25px;
  }
}

.verification__photo p.text-alert {
  font-size: 13px;
  color: var(--iq-darkred);
  line-height: 29px;
  margin-bottom: 15px;
  max-width: 730px;
}

.verification__photo h4 {
  color: var(--iq-darkblack);
  margin-bottom: 30px;
}

@media (max-width: 479px) {
  .verification__photo h4 {
    line-height: 25px;
    margin-bottom: 25px;
  }
}

.verification__photo--img {
  max-width: 730px;
  margin-bottom: 20px;
}

@media (max-width: 479px) {
  .verification__photo--img {
    margin-bottom: 12px;
  }
}

.verification__photo--img img {
  max-width: 100%;
}

.verification__photo--buttons {
  display: -webkit-box;
  display: flex;
}

@media (max-width: 479px) {
  .verification__photo--buttons {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.verification__photo--buttons a {
  margin-left: 10px;
}

@media (max-width: 479px) {
  .verification__photo--buttons a {
    margin-left: 0;
    margin-top: 10px;
  }
}

@media (max-width: 479px) {
  .verification__photo--buttons input[type="file"] {
    width: 100%;
    max-width: 100%;
  }
}

.verification__progress {
  padding: 30px 70px;
  background-color: #f8f8f8;
  margin-bottom: 40px;
  border-radius: 5px;
}

@media (max-width: 575px) {
  .verification__progress {
    padding: 30px 12px;
    margin-bottom: 25px;
  }
}

.verification__progress ul {
  padding: 0;
  list-style-type: none;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 650px;
  margin: 0 auto;
}

.verification__progress ul li {
  text-align: center;
  min-width: 140px;
  color: var(--iq-darkgrey);
}

@media (max-width: 575px) {
  .verification__progress ul li {
    max-width: 106px;
    min-width: unset;
    font-size: 14px;
    align-self: flex-end;
  }
}

@media (max-width: 479px) {
  .verification__progress ul li {
    max-width: 75px;
  }
}

.verification__progress--lines {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-top: 10px;
}

.verification__progress--lines .line {
  height: 2px;
}

.verification__progress--lines .line--primary {
  width: 18%;
}

@media (max-width: 575px) {
  .verification__progress--lines .line--primary {
    width: 7%;
  }
}

.verification__progress--lines .line--secondary {
  width: 27.1%;
}

@media (max-width: 575px) {
  .verification__progress--lines .line--secondary {
    width: 33.1%;
  }
}

.verification__progress--lines .line.grey {
  background-color: #e4e4e4;
}

.verification__progress--lines .line.blue {
  background-color: var(--iq-lite-orange);
}

.verification__progress--lines .circle {
  width: 27px;
  height: 27px;
  border: 6px solid var(--iq-white);
  border-radius: 50%;
  box-shadow: 0 4px 13px rgba(0, 0, 0, 0.11);
}

.verification__progress--lines .circle--blue {
  background-color: var(--iq-lite-orange);
}

.verification__progress--lines .circle--grey {
  background-color: #e4e4e4;
}

.verification__text {
  padding-bottom: 50px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--iq-light-pink);
}

@media (max-width: 479px) {
  .verification__text {
    padding-bottom: 25px;
    margin-bottom: 15px;
  }
}

.verification__text p {
  color: var(--iq-black);
}

@media (max-width: 479px) {
  .verification__text p strong {
    font-size: 14px;
  }
}

@media (max-width: 479px) {
  .verification__text p {
    font-size: 14px;
    line-height: 27px;
  }
}

.verification__text p:first-child {
  color: var(--iq-darkgrey);
  line-height: 30px;
  margin-bottom: 1.5rem;
}

@media (max-width: 479px) {
  .verification__text p:first-child {
    line-height: 27px;
    margin-bottom: 20px;
  }
}

.verification__text p:nth-child(2) {
  color: var(--iq-darkblack);
  margin-bottom: 15px;
}

.verification__text p:nth-child(3) {
  margin-bottom: 10px;
}

@media (max-width: 479px) {
  .verification__text p:nth-child(3) {
    margin-bottom: 20px;
  }
}

.verification__text p span {
  color: var(--iq-darkred);
}

.verification__bottom {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

@media (max-width: 575px) {
  .verification__bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse;
  }
}

.verification__bottom--text {
  max-width: 638px;
}

@media (max-width: 575px) {
  .verification__bottom--text {
    margin-top: 15px;
  }
}

.verification__bottom--text p {
  font-size: 13px;
  color: var(--iq-darkgrey);
  line-height: 25px;
}

@media (max-width: 479px) {
  .verification__bottom input[type="file"] {
    width: 100%;
    max-width: 100%;
  }
}

.verification__section {
  display: -webkit-box;
  display: flex;
}

@media (max-width: 575px) {
  .verification__section--img {
    display: none;
  }
}

.verification__section--img img {
  max-width: 100%;
}

.verification__box {
  width: 425px;
  max-width: 100%;
  border: 1px solid var(--iq-dullgrey);
  border-radius: 5px;
  padding: 20px 10px;
  margin-left: 15px;
}

@media (max-width: 575px) {
  .verification__box {
    margin-left: 0;
    width: 100%;
  }
}

.verification__box ul {
  padding: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.verification__box ul li {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 100%;
}

@media (max-width: 575px) {
  .verification__box ul li {
    max-width: 100%;
  }
}

.verification__box ul li:not(:last-child) {
  margin-bottom: 10px;
}

.verification__box ul li p {
  color: var(--iq-darkgrey);
}

.verification__box ul li p.text-alert {
  color: var(--iq-darkred);
}

.verification--rejected .verification__progress,
.verification--success .verification__progress {
  margin-bottom: 18px;
}

.verification--rejected .verification__banner .verification__banner--text a {
  max-width: 220px;
}

@media (max-width: 479px) {
  .verification--rejected .verification__banner .verification__banner--text a {
    max-width: 100%;
  }
}

.verification--rejected .verification__banner--rejected {
  margin-bottom: 45px;
}

@media (max-width: 575px) {
  .verification--rejected .verification__banner--rejected {
    margin-bottom: 15px;
  }
}

.verification--rejected .verification__box {
  width: 460px;
}

.verification--rejected .verification__box ul li:last-child {
  max-width: 100%;
}

@media (max-width: 575px) {
  .verification--rejected .verification__box ul li:last-child {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    max-width: 100%;
    background: rgba(213, 0, 50, 0.08);
    border-radius: 5px;
    padding: 15px;
  }
}

.verification--rejected .verification__box ul li:last-child p:last-child {
  max-width: 100%;
  width: 100%;
}

@media (max-width: 575px) {
  .verification--rejected .verification__box ul li:last-child p {
    max-width: 100%;
  }
}

.verification--rejected .verification__box ul li:last-child p:first-child {
  min-width: 150px;
  max-width: 100%;
}

@media (max-width: 575px) {
  .verification--rejected .verification__box ul li:last-child p:first-child {
    margin-bottom: 5px;
  }
}

.verification--rejected
  .verification__box
  ul
  li:not(:last-child)
  p:first-child {
  min-width: 150px;
  max-width: 100%;
}

.verification--rejected .verification__box ul li:not(:last-child) p:last-child {
  width: 100%;
  max-width: 100%;
}

@media (max-width: 575px) {
  .verification--rejected
    .verification__box
    ul
    li:not(:last-child)
    p:last-child {
    min-width: unset;
  }
}

.review .verification__text {
  padding-bottom: 0;
  margin-bottom: 40px;
  border: none;
}

@media (max-width: 479px) {
  .review .verification__text {
    margin-bottom: 25px;
  }
}

.review .verification__text p {
  margin-bottom: 0;
}

.not-found {
  max-width: 891px;
  margin: 115px auto 150px;
}

@media (max-width: 479px) {
  .not-found {
    margin: 55px auto;
  }
}

.not-found__img {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.not-found__img img {
  max-width: 248px;
}

@media (max-width: 479px) {
  .not-found__img img {
    max-width: 200px;
  }
}

@media (max-width: 479px) {
  .not-found__img--desktop {
    display: none;
  }
}

@media (min-width: 480px) {
  .not-found__img--mobile {
    display: none;
  }
}

.not-found__text {
  margin-top: 20px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

@media (max-width: 575px) {
  .not-found__text {
    width: 100%;
    max-width: 100%;
    margin-bottom: 75px;
    padding: 0 15px;
  }
}

.not-found__text h2 {
  color: var(--iq-darkblack);
  margin-bottom: 20px;
  font-size: 30px;
}

@media (max-width: 575px) {
  .not-found__text h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }
}

.not-found__text p {
  color: var(--iq-darkblack);
  font-size: 22px;
  line-height: 32px;
  max-width: 870px;
  text-align: center;
  margin-bottom: 45px;
}

@media (max-width: 575px) {
  .not-found__text p {
    font-size: 17px;
    line-height: 25px;
    margin-bottom: 38px;
  }
}

.not-found__buttons {
  display: -webkit-box;
  display: flex;
}

@media (max-width: 991px) {
  .not-found__buttons {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.not-found__buttons a {
  width: 193px;
  height: 60px;
  max-width: 100%;
}

@media (max-width: 479px) {
  .not-found__buttons a {
    height: 53px;
    max-width: 100%;
  }
}

.not-found__buttons a:not(:last-child) {
  margin-right: 15px;
}

@media (max-width: 991px) {
  .not-found__buttons a:not(:last-child) {
    margin-bottom: 15px;
    margin-right: 0;
  }
}

.maintenance {
  max-width: 570px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 120px auto 142px;
}

@media (max-width: 575px) {
  .maintenance {
    margin: 0;
  }
}

.maintenance__img {
  max-width: 100%;
  width: 127px;
}

@media (max-width: 575px) {
  .maintenance__img {
    width: 93px;
  }
}

.maintenance__img img {
  max-width: 100%;
}

.maintenance__text {
  margin-top: 33px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

@media (max-width: 575px) {
  .maintenance__text {
    margin-top: 23px;
  }
}

@media (max-width: 479px) {
  .maintenance__text {
    padding: 0 15px;
  }
}

.maintenance__text h2 {
  color: var(--iq-darkblack);
  margin-bottom: 38px;
  text-align: center;
}

@media (max-width: 575px) {
  .maintenance__text h2 {
    font-size: 30px;
  }
}

@media (max-width: 479px) {
  .maintenance__text h2 {
    font-size: 26px;
  }
}

.maintenance__text a {
  width: 290px;
  height: 60px;
  position: relative;
  display: none;
}

@media (max-width: 479px) {
  .maintenance__text a {
    width: 100%;
    max-width: 100%;
  }
}

.maintenance__text a i {
  position: absolute;
  right: 20px;
}

.welcome {
  max-width: 940px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 123px auto 131px;
}

@media (max-width: 575px) {
  .welcome {
    margin-top: 106px;
    margin-bottom: 94px;
  }
}

.welcome__img {
  max-width: 100%;
  width: 241px;
}

@media (max-width: 575px) {
  .welcome__img {
    width: 214px;
  }
}

.welcome__img img {
  max-width: 100%;
}

.welcome__text {
  margin-top: 33px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

@media (max-width: 575px) {
  .welcome__text {
    margin-top: 23px;
    padding: 0 15px;
  }
}

.welcome__text h2 {
  color: var(--iq-darkblack);
  margin-bottom: 28px;
  text-align: center;
  line-height: 36px;
}

@media (max-width: 575px) {
  .welcome__text h2 {
    font-size: 30px;
  }
}

@media (max-width: 479px) {
  .welcome__text h2 {
    font-size: 26px;
  }
}

.welcome__text p {
  font-size: 25px;
  text-align: center;
  margin-bottom: 60px;
  max-width: 820px;
  line-height: 40px;
}

@media (max-width: 575px) {
  .welcome__text p {
    font-size: 17px;
    line-height: 30px;
    margin-bottom: 30px;
  }
}

.welcome__text a {
  width: 236px;
  height: 60px;
  position: relative;
  box-shadow: 0 4px 25px rgba(252, 227, 0, 0.69);
}

@media (max-width: 479px) {
  .welcome__text a {
    width: 100%;
    max-width: 100%;
  }
}

.welcome__text a i {
  position: absolute;
  right: 20px;
}

.previous-drawn {
  margin: 50px 0 100px;
}

@media (max-width: 479px) {
  .previous-drawn {
    margin: 20px 0 40px;
  }
}

.previous-drawn__top {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-bottom: 67px;
}

@media (max-width: 575px) {
  .previous-drawn__top {
    margin-bottom: 20px;
  }
}

.previous-drawn__button {
  width: 270px;
  max-width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

@media (max-width: 479px) {
  .previous-drawn__button {
    width: 100%;
  }
}

.previous-drawn__button a {
  width: 100%;
  max-width: 100%;
  height: 60px;
  position: relative;
}

@media (max-width: 479px) {
  .previous-drawn__button a {
    height: 48px;
  }
}

.previous-drawn__button a i {
  position: absolute;
  right: 20px;
}

@media (max-width: 575px) {
  .previous-drawn__button.desktop {
    display: none;
  }
}

.previous-drawn__button.mobile {
  display: block;
}

@media (min-width: 576px) {
  .previous-drawn__button.mobile {
    display: none;
  }
}

.previous-drawn__details,
.previous-drawn__details--img {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.previous-drawn__details--img {
  width: 154px;
  height: 154px;
  border-radius: 50%;
  background-color: #f1f1f1;
  border: 2px solid var(--iq-pink);
  -webkit-box-pack: center;
  justify-content: center;
}

@media (max-width: 575px) {
  .previous-drawn__details--img {
    display: none;
  }
}

.previous-drawn__details--img img {
  width: 74px;
  max-width: 100%;
}

@media (max-width: 575px) {
  .previous-drawn__text {
    margin-left: 0;
  }
}

.previous-drawn__text h2 {
  color: var(--iq-darkblack) !important;
  margin-bottom: 10px;
  font-size: 20px;
}

@media (max-width: 479px) {
  .previous-drawn__text h2 {
    font-size: 17px;
  }
}

.previous-drawn__text p {
  color: var(--iq-black);
  margin-bottom: 15px;
}

@media (max-width: 479px) {
  .previous-drawn__text p {
    font-size: 14px;
  }
}

.previous-drawn__text--number-row {
  display: -webkit-box;
  display: flex;
}

.previous-drawn__text--number-row .betNum {
  width: 55px;
  height: 55px;
  background-repeat: no-repeat;
  background-position: 50%;
  color: var(--iq-black);
  font-size: 21px;
  margin: 0;
  padding: 0;
}

@media (max-width: 479px) {
  .previous-drawn__text--number-row .betNum {
    width: 41px;
    height: 41px;
    border: none;
    font-size: 18px;
  }
}

@media (max-width: 340px) {
  .previous-drawn__text--number-row .betNum {
    height: 35px;
    font-size: 17px;
  }
}

.previous-drawn__text--number-row .betNumMarked {
  background-image: url(../img/ball.svg);
}

.previous-drawn__buttons {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  align-items: flex-end;
  margin-bottom: 50px;
}

@media (max-width: 575px) {
  .previous-drawn__buttons {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse;
  }
}

.previous-drawn__select {
  width: 220px;
  max-width: 100%;
}

@media (max-width: 575px) {
  .previous-drawn__select {
    margin-top: 15px;
  }
}

@media (max-width: 479px) {
  .previous-drawn__select {
    width: 100%;
  }
}

.previous-drawn__select p {
  color: var(--iq-black);
  margin-bottom: 10px;
}

.previous-drawn__select .selectize .selectize-input:after {
  color: var(--iq-orange);
}

@media (max-width: 575px) {
  .previous-drawn .table-main {
    margin-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .previous-drawn .table-main table thead {
    border: initial;
    clip: unset;
    height: auto;
    position: unset;
    margin: 0;
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 479px) {
  .previous-drawn .table-main table thead tr th:first-child {
    padding: 15px 0 10px 15px;
  }
}

@media (max-width: 479px) {
  .previous-drawn .table-main table thead tr th:nth-child(2) {
    padding: 15px 0 10px 30px;
  }
}

@media (max-width: 479px) {
  .previous-drawn .table-main table thead tr th:last-child {
    padding: 15px 0 10px 15px;
  }
}

@media (max-width: 575px) {
  .previous-drawn .table-main table thead tr th:not(:last-child) {
    border-right: none;
  }
}

@media (max-width: 479px) {
  .previous-drawn .table-main table thead tr th {
    font-size: 14px;
  }
}

@media (max-width: 575px) {
  .previous-drawn .table-main table tbody {
    display: table-row-group;
  }
}

@media (max-width: 575px) {
  .previous-drawn .table-main table tbody tr {
    display: table-row;
  }
}

@media (max-width: 575px) {
  .previous-drawn .table-main table tbody tr:nth-child(2n) {
    background-color: #ffeee4;
  }
}

@media (max-width: 575px) {
  .previous-drawn .table-main table tbody tr td {
    display: table-cell;
  }
}

@media (max-width: 575px) {
  .previous-drawn .table-main table tbody tr td:nth-child(odd) {
    background-color: initial;
  }
}

.previous-drawn .table-main table tbody tr td:first-child {
  color: var(--iq-darkblack);
}

.previous-drawn .table-main table tbody tr td:before {
  content: none;
}

@media (max-width: 479px) {
  .previous-drawn .table-main table tbody tr td:nth-child(2) {
    padding: 15px 15px 12px 30px;
  }
}

.previous-drawn__section:nth-child(3) .previous-drawn__details--img img {
  width: 100px;
}

.previous-drawn__section:nth-child(3) .table-main table tbody tr td:last-child {
  color: var(--iq-darkblack);
}

.ticket-page {
  padding: 50px 0;
}

.ticket-page .ticket {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}

@media (max-width: 420px) {
  .ticket-page .ticket {
    background-position: 0, 100%;
    background-repeat: repeat-y, repeat-y;
    background-size: 35px auto, 35px auto;
  }
}

.ticket-page .ticket--wrapper {
  width: 413px;
  max-width: 100%;
  background-position: 0, 100%;
  background-repeat: repeat-y, repeat-y;
  background-size: 35px auto, 35px auto;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}

@media (max-width: 420px) {
  .ticket-page .ticket--wrapper {
    background-image: none;
    width: 240px;
  }
}

.ticket-page .ticket__img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  width: 250px;
  max-width: 100%;
  padding-bottom: 58px;
  margin: 35px 0 20px;
}

@media (max-width: 420px) {
  .ticket-page .ticket__img {
    width: 200px;
  }
}

.ticket-page .ticket__winnings {
  margin-bottom: 15px;
}

.ticket-page .ticket__winnings--win {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  margin-bottom: 10px;
}

.ticket-page .ticket__winnings--win span {
  font-size: 14px;
  color: var(--iq-white);
  padding: 8px 8px 3px;
}

.ticket-page .ticket__winnings p {
  font-size: 13px;
  color: var(--iq-black);
}

.ticket-page .ticket__bottom {
  width: 300px;
  max-width: 100%;
}

@media (max-width: 420px) {
  .ticket-page .ticket__bottom {
    width: 100%;
  }
}

@media (max-width: 350px) {
  .ticket-page .ticket__bottom {
    width: 250px;
  }
}

.ticket-page .ticket__bottom--header {
  border-top: 2px dashed var(--iq-black);
  border-bottom: 2px dashed var(--iq-black);
  padding: 5px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;
}

.ticket-page-side-banners {
  width: 36px;
  max-width: 100%;
}

.ticket-page-logo-img {
  height: auto;
  width: 100%;
  object-fit: contain;
}

.ticket-page .ticket__bottom--header h2 {
  font-size: 14px;
  color: var(--iq-black);
  margin-bottom: 0;
}

.ticket-page .ticket__content {
  width: 300px;
  max-width: 100%;
}

.ticket-page-content {
  width: 325px;
}

@media (max-width: 400px) {
  .ticket-page .ticket__content {
    width: 250px;
  }

  .ticket-page-content {
    width: 230px;
  }
}

.ticket-page .ticket__content h2 {
  font-size: 14px;
  color: var(--iq-black);
  padding: 8px 5px 5px;
  border-top: 2px dashed var(--iq-black);
  border-bottom: 2px dashed var(--iq-black);
  margin-bottom: 15px;
}

.ticket-page .ticket__content ul {
  padding: 0;
  list-style-type: none;
  margin-bottom: 15px;
}

.ticket-page .ticket__content ul li {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.ticket-page .ticket__content ul li:not(:last-child) {
  margin-bottom: 5px;
}

.ticket-page .ticket__content ul li p {
  font-size: 14px;
  color: var(--iq-black);
}

@media (max-width: 420px) {
  .ticket-page .ticket__content ul li p {
    font-size: 12px;
  }
}

.ticket-page .ticket__content ul li span {
  font-size: 14px;
  color: var(--iq-black);
}

@media (max-width: 420px) {
  .ticket-page .ticket__content ul li span {
    font-size: 12px;
    text-align: right;
  }
}

.ticket-page .ticket__numbers {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

@media (max-width: 420px) {
  .ticket-page .ticket__numbers {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
  }
}

.ticket-page .ticket__numbers--row {
  display: -webkit-box;
  display: flex;
  margin-bottom: 5px;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

@media (max-width: 575px) {
  .ticket-page .ticket__numbers--row {
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 8px;
  }
}

.ticket-page .ticket__numbers--row:last-child {
  margin-bottom: 0;
}

.ticket-page .ticket__numbers--number-row {
  display: -webkit-box;
  display: flex;
}

.ticket-page .ticket__numbers--number-row .betNum {
  width: 28px;
  height: 28px;
  margin: 0 5px 0 0;
  border-color: transparent;
  font-size: 14px;
  padding: 0;
  text-align: center;
}

@media (max-width: 575px) {
  .ticket-page .ticket__numbers--number-row .betNum {
    padding-top: 2px;
    font-size: 12px;
    width: 24px;
    height: 24px;
  }
}

.ticket-page .ticket__numbers--number-row .betNumMarked {
  background: none;
  border-color: var(--iq-black);
  border-width: 1px;
}

.subtickets div {
  font-size: 14px;
  color: var(--iq-black) !important;
  line-height: 32px;
}

.ticket-page .ticket__numbers--raffleid {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 4px 10px;
}

.ticket-page .ticket__numbers--raffleid span {
  font-size: 14px;
  color: var(--iq-black);
  letter-spacing: 2px;
}

@media (max-width: 420px) {
  .ticket-page .ticket__numbers--raffleid span {
    padding-top: 3px;
  }
}

@media (max-width: 350px) {
  .ticket-page .ticket__numbers--raffleid span {
    font-size: 12px;
    padding-top: 2px;
  }
}

.ticket-page .ticket__numbers--raffleid.raffleid-win {
  border: 2px solid var(--iq-black);
  border-radius: 50%;
}

.ticket-page .ticket__barcode {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  margin-top: 90px;
}

@media (max-width: 420px) {
  .ticket-page .ticket__barcode {
    margin-top: 50px;
  }
}

.ticket-page .ticket__barcode p {
  color: var(--iq-black);
}

.ticket-page .ticket__barcode--img {
  max-width: 136px;
}

.ticket-page .ticket__barcode--img img {
  max-width: 100%;
  width: auto;
  height: auto;
}

.my-cards {
  padding: 32px 40px 40px;
}

@media (max-width: 575px) {
  .my-cards {
    padding: 0;
    border: none;
  }
}

.my-cards .myaccount__content--heading h3 {
  margin-bottom: 0;
}

@media (max-width: 575px) {
  .my-cards__content .payment__card:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  .subtickets div {
    margin-bottom: 8px !important;
    line-height: 35px;
    font-size: 12px;
  }
}

@media (max-width: 575px) {
  .my-cards__content .payment__card--img {
    margin-right: 10px;
  }
}

html .ui-datepicker .ui-widget-header {
  background: var(--iq-pink);
}

html .ui-datepicker .ui-corner-all {
  cursor: pointer;
  border: 1px solid transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

html .ui-datepicker .ui-corner-all.ui-state-hover {
  border-color: var(--iq-white);
}

html .ui-datepicker .ui-state-default {
  background: var(--iq-mildgrey);
  border-color: var(--iq-pink);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: center;
}

html .ui-datepicker .ui-state-active {
  background: var(--iq-pink);
  color: var(--iq-white);
}

html .ui-datepicker .ui-state-hover {
  background-color: var(--iq-pink);
  color: var(--iq-white);
}

html .ui-datepicker .ui-state-highlight {
  background: #7bb8de;
  color: var(--iq-white);
}

html .ui-widget.ui-widget-content {
  border: 1px solid var(--iq-dullgrey);
}

.cookie-bar {
  background-color: var(--iq-black);
  padding: 20px 0;
  z-index: 6;
  width: 100%;
  max-width: 100%;
  display: none;
  position: fixed;
  bottom: 0;
}

.cookie-bar.active {
  display: block;
}

.cookie-bar__content {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}

@media (max-width: 575px) {
  .cookie-bar__content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: start;
    align-items: flex-start;
  }
}

.cookie-bar__text {
  max-width: 960px;
  padding-right: 50px;
}

@media (max-width: 575px) {
  .cookie-bar__text {
    padding-right: 0;
    margin-bottom: 25px;
  }
}

.cookie-bar__text p {
  font-size: 14px;
  color: var(--iq-white);
  line-height: 19px;
}

.cookie-bar__text p:first-child {
  margin-bottom: 10px;
}

.cookie-bar__text p a {
  color: var(--iq-white);
  text-decoration: underline;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.cookie-bar__text p a:hover {
  color: #fce300;
}

.cookie-bar__buttons {
  display: -webkit-box;
  display: flex;
}

.cookie-bar__buttons a {
  width: 140px;
  font-size: 17px;
}

.new-password {
  margin: 77px 0 73px;
}

@media (max-width: 575px) {
  .new-password {
    margin: 30px 0 70px;
  }
}

.new-password__content {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}

.new-password__content .form {
  width: 621px;
  max-width: 100%;
}

.new-password__content .form h2 {
  text-align: left;
  margin-bottom: 30px;
}

.new-password__content .form .form-group:not(:last-child) {
  margin-bottom: 22px;
}

.new-password__content--button {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  margin-top: 36px;
}

.new-password__content--button button {
  width: 267px;
  max-width: 100%;
  height: 60px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.bottle-picker {
  margin: 90px 0;
}

@media (max-width: 575px) {
  .bottle-picker {
    margin: 40px 0;
  }
}

.bottle-picker .error-text {
  background-color: rgba(213, 0, 50, 0.1);
  border-radius: 5px;
  padding: 15px 20px;
  display: none;
  margin-top: 10px;
}

.bottle-picker .error-text.active {
  display: block;
}

@media (max-width: 479px) {
  .bottle-picker .error-text {
    padding: 15px 12px;
  }
}

.bottle-picker .error-text p {
  font-size: 14px;
  color: var(--iq-darkred);
}

.bottle-picker__content--img {
  height: 440px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  border: 1px solid var(--iq-lgtgrey);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
}

@media (max-width: 575px) {
  .bottle-picker__content--img {
    height: 350px;
  }
}

.bottle-picker__inner {
  padding-top: 25px;
}

.bottle-picker__inner h1 {
  color: var(--iq-pink);
  margin-bottom: 15px;
}

@media (max-width: 479px) {
  .bottle-picker__inner h1 {
    font-size: 21px;
  }
}

.bottle-picker__inner p {
  color: var(--iq-darkblack);
}

.bottle-picker__inner--box {
  background-color: var(--iq-mildgrey);
  margin-top: 30px;
  border-radius: 5px;
}

@media (max-width: 479px) {
  .bottle-picker__inner--box {
    margin-top: 15px;
  }
}

.bottle-picker__inner--box p {
  padding: 20px 18px;
}

@media (max-width: 479px) {
  .bottle-picker__inner--box p {
    font-size: 14px;
  }
}

.bottle-picker__inner--bottom {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 30px;
}

@media (max-width: 575px) {
  .bottle-picker__inner--bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

@media (max-width: 479px) {
  .bottle-picker__inner--bottom {
    margin-top: 15px;
  }
}

@media (max-width: 575px) {
  .bottle-picker__currency {
    margin-bottom: 15px;
  }
}

.bottle-picker__currency span {
  font-size: 25px;
  color: var(--iq-darkblack);
}

@media (max-width: 479px) {
  .bottle-picker__currency span {
    font-size: 21px;
  }
}

.bottle-picker__currency p {
  color: var(--iq-darkblack);
}

.bottle-picker__currency--currency {
  font-size: 12px !important;
  font-style: italic;
  line-height: 11px;
}

.bottle-picker__buttons {
  display: -webkit-box;
  display: flex;
}

@media (max-width: 575px) {
  .bottle-picker__buttons {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.bottle-picker__buttons--value {
  display: -webkit-box;
  display: flex;
  height: 60px;
  margin-right: 10px;
}

@media (max-width: 575px) {
  .bottle-picker__buttons--value {
    width: 100%;
    max-width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    margin-bottom: 15px;
  }
}

.bottle-picker__buttons--value
  input:not([type="checkbox"]):not([type="button"]):not([type="submit"]):not(
    [type="file"]
  ) {
  width: 55px;
  border-radius: 0;
  text-align: center;
  color: var(--iq-darkblack);
  height: 100%;
  padding: 20px 0;
}

@media (max-width: 575px) {
  .bottle-picker__buttons--value
    input:not([type="checkbox"]):not([type="button"]):not([type="submit"]):not(
      [type="file"]
    ) {
    width: 100%;
    max-width: 100%;
  }
}

.bottle-picker__buttons--value .value-button {
  padding: 0 14px;
  background-color: #f8f8f8;
  border: 1px solid var(--iq-dullgrey);
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: var(--iq-darkblack);
  cursor: pointer;
}

.bottle-picker__buttons--value .value-button:first-child {
  border-right: 0;
  border-radius: 4px 0 0 4px;
}

.bottle-picker__buttons--value .value-button:last-child {
  border-left: 0;
  border-radius: 0 4px 4px 0;
}

.bottle-picker__buttons--submit {
  text-align: right;
}

@media (max-width: 575px) {
  .bottle-picker__buttons--submit {
    width: 100%;
    max-width: 100%;
    text-align: left;
  }
}

.bottle-picker__buttons--submit button {
  width: 228px;
  max-width: 100%;
  height: 60px;
  margin-bottom: 15px;
}

@media (max-width: 575px) {
  .bottle-picker__buttons--submit button {
    width: 100%;
    max-width: 100%;
  }
}

.bottle-picker__buttons--submit p {
  color: var(--iq-darkblack);
}

.map {
  width: 100%;
  height: 480px;
  margin: 0;
  max-width: 100%;
}

.gm-style .gm-style-iw-d > div {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.gm-style .gm-style-iw-d > div a {
  text-align: left;
  max-width: 85px;
  color: var(--iq-pink);
  text-decoration: underline;
  margin-top: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.gm-style .gm-style-iw-d > div a:hover {
  color: #7bb8de;
}

.pac-card {
  background-color: var(--iq-white);
  border: 0;
  border-radius: 2px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  margin: 10px;
  font: 400 18px Roboto, Arial, sans-serif;
  overflow: hidden;
  padding: 0;
}

#pac-container {
  padding-bottom: 12px;
  margin-right: 12px;
}

.pac-controls {
  display: inline-block;
  padding: 5px 11px;
}

.pac-controls label {
  font-size: 13px;
}

#pac-input {
  background-color: var(--iq-white);
  font-size: 17px;
  margin-left: 12px;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 400px;
}

#pac-input:focus {
  border-color: #4d90fe;
}

.find__content {
  text-align: center;
  margin: 90px 0 130px;
}

@media (max-width: 767px) {
  .find__content {
    margin: 60px 0 90px;
  }
}

@media (max-width: 575px) {
  .find__content {
    margin: 40px 0 70px;
  }
}

.find__content h1 {
  color: var(--iq-darkblack);
  margin-bottom: 30px;
  font-size: 28px;
}

@media (max-width: 575px) {
  .find__content h1 {
    font-size: 21px;
    margin-bottom: 10px;
  }
}

.find__content p {
  color: var(--iq-darkgrey);
  margin-bottom: 15px;
  line-height: 30px;
}

@media (max-width: 575px) {
  .find__content p {
    line-height: 27px;
    font-size: 14px;
  }
}

.find__content p:nth-child(3) {
  margin-bottom: 50px;
}

.find__content p a {
  color: var(--iq-pink);
  text-decoration: underline;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.find__content p a:hover {
  color: #7bb8de;
  text-decoration: unset;
}

.find__form {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  margin-bottom: 30px;
}

.find__form .form-group {
  width: 100%;
  max-width: 100%;
}

.find__form .form-group .selectize-control .selectize-input {
  height: 50px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  color: var(--iq-darkgrey);
  padding: 8px 30px 8px 8px;
  font-size: 13px !important;
}

.find__form .form-group .selectize-control .selectize-input input {
  font-size: 13px !important;
  color: var(--iq-darkgrey);
}

.find__form
  .form-group
  .selectize-control
  .selectize-input
  .selectize-custom-link {
  height: 30px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.find__form
  .form-group
  .selectize-control
  .selectize-dropdown
  .selectize-dropdown-content {
  text-align: left;
  padding-left: 8px;
}

.find__form
  .form-group
  .selectize-control
  .selectize-dropdown
  .selectize-custom-link
  span,
.find__form
  .form-group
  .selectize-control
  .selectize-dropdown
  .selectize-custom-option
  span {
  font-size: 13px;
  color: var(--iq-darkgrey);
}

.share_tab {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  list-style-type: none;
  margin-bottom: 80px;
  padding-left: 0;
}

@media (max-width: 479px) {
  .share_tab {
    margin-bottom: 40px;
  }
}

.share_tab > span {
  margin-right: 12px;
}

.share_tab li {
  width: 35px;
  height: 35px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.share_tab li:not(:last-child) {
  margin-right: 10px;
}

.share_tab li a {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.register__new .login__content .form {
  width: 100%;
  max-width: 100%;
}

.register__new .login__content .form__content {
  width: 100%;
  max-width: 100%;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.05);
  border: none;
  border-radius: 5px;
}

.register__new .login__content .form__content form {
  border: none;
  border-radius: 0;
  padding: 0;
}

.register__new .login__content .form__content form .information-text.secondary {
  margin-top: 5px;
  margin-bottom: 0;
}

.register__new .login__content .form__content form .form-group .info-icon {
  display: none;
}

@media (max-width: 575px) {
  .register__new .login__content .form__content form .form-group .input-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }
}

@media (max-width: 575px) {
  .register__new
    .login__content
    .form__content
    form
    .form-group
    .input-row
    .flag-picker {
    margin-bottom: 0;
  }
}

@media (max-width: 479px) {
  .register__new
    .login__content
    .form__content
    form
    .form-group
    .input-row
    .flag-picker {
    width: 120px;
    min-width: 120px;
  }
}

.register__new
  .login__content
  .form__content
  form
  .form-group.error
  .info-icon.error {
  display: block;
}

.register__new
  .login__content
  .form__content
  form
  .form-group.error
  .captcha_holder {
  border-radius: 5px;
  border: 1px solid var(--iq-darkred);
}

.register__new
  .login__content
  .form__content
  form
  .form-group.success
  .info-icon.success {
  display: block;
}

.register__new
  .login__content
  .form__content
  form
  .form-group#form_register_confirm_passwd
  .info-icon,
.register__new
  .login__content
  .form__content
  form
  .form-group#form_register_passwd
  .info-icon {
  right: 40px;
}

.register__new
  .login__content
  .form__content
  form
  .form-group#form_register_verify-otp
  .info-icon {
  top: 75px;
}

@media (max-width: 575px) {
  .register__new
    .login__content
    .form__content
    form
    .form-group#form_register_verify-otp
    .info-icon {
    top: 95px;
  }
}

.register__new
  .login__content
  .form__content
  form
  .form-group#form_confirm_phone
  .captcha-wrapper {
  -webkit-box-pack: center;
  justify-content: center;
  margin-top: 50px;
}

@media (max-width: 767px) {
  .register__new
    .login__content
    .form__content
    form
    .form-group#form_confirm_phone
    .captcha-wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
  }
}

@media (max-width: 575px) {
  .register__new
    .login__content
    .form__content
    form
    .form-group#form_confirm_phone
    .captcha-wrapper {
    margin-bottom: 0;
  }
}

@media (max-width: 360px) {
  .register__new
    .login__content
    .form__content
    form
    .form-group#form_confirm_phone
    .captcha-wrapper {
    -webkit-box-pack: start;
    justify-content: flex-start;
  }
}

@media (max-width: 479px) {
  .register__new
    .login__content
    .form__content
    form
    .form-group#form_confirm_phone
    .form__bottom {
    padding: 25px 0;
  }
}

.register__new
  .login__content
  .form__content
  form
  .form-group#form_register_start {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.register__new
  .login__content
  .form__content
  form
  .form-group#form_register_start
  a {
  width: 250px;
}

@media (max-width: 479px) {
  .register__new
    .login__content
    .form__content
    form
    .form-group#form_register_start
    a {
    width: 100%;
    max-width: 100%;
  }
}

.register__new
  .login__content
  .form__content
  form
  .form-group
  .visibility-button {
  position: absolute;
  right: 15px;
  top: 47px;
  cursor: pointer;
}

.register__new .login__content .form__center {
  padding: 0 100px 0px;
}

@media (max-width: 575px) {
  .register__new .login__content .form__center {
    padding: 0px 15px;
  }
}

.register__new .login__content .form__center--firststep {
  max-width: 600px;
  margin: 0 auto;
  display: block;
}

.register__new
  .login__content
  .form__center--firststep
  #form_register_verify-otp {
  display: none;
}

.register__new .login__content .form__center--firststep .form-group {
  margin-bottom: 0;
}

.register__new .login__content .form__center--secondstep,
.register__new .login__content .form__center--thirdstep {
  display: none;
}

.register__new .login__content .form__center--labeltext {
  color: #797979;
  font-size: 12px;
  margin-bottom: 10px;
}

.register__new .login__content .form__bottom {
  padding: 0 100px 20px;
}

@media (max-width: 575px) {
  .register__new .login__content .form__bottom {
    padding: 0px 15px;
  }
}

.register__new .login__content .form__bottom .form-group {
  margin-bottom: 0 !important;
}

.register__new .login__content .form__bottom--continue {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  visibility: hidden;
}

.register__new .login__content .form__bottom--continue.active {
  visibility: visible;
}

.register__new .login__content .form__bottom--continue .form-group {
  width: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: flex;
}

.register__new
  .login__content
  .form__bottom--continue
  .form-group#form_register_continue {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}

.register__new .login__content .form__bottom--continue .form-group button {
  width: 236px;
  height: 60px;
  max-width: 100%;
  -webkit-box-pack: center;
  justify-content: center;
}

@media (max-width: 575px) {
  .register__new .login__content .form__bottom--continue .form-group button {
    height: 45px;
    width: 100%;
  }
}

.register__new .login__content .form__bottom--continue p {
  font-size: 14px;
  color: var(--iq-darkred);
  margin-top: 18px;
}

@media (max-width: 479px) {
  .register__new .login__content .form__bottom--continue p {
    font-size: 12px;
  }
}

.register__new .login__content .form__bottom--create {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  display: none;
  position: relative;
}

.register__new .login__content .form__bottom--create .form-group {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: flex-end;
  align-items: flex-end;
}

@media (max-width: 575px) {
  .register__new .login__content .form__bottom--create .form-group {
    width: 100%;
  }
}

.register__new .login__content .form__bottom--create .form-group button {
  -webkit-box-pack: center;
  justify-content: center;
}

@media (max-width: 575px) {
  .register__new .login__content .form__bottom--create .form-group button {
    height: 45px;
    width: 100%;
    max-width: 100%;
  }
}

.register__new .login__content .form__bottom--back {
  position: absolute;
  left: 0;
  top: 0;
  height: 48px;
  width: 130px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  background-color: var(--iq-orange);
  color: var(--iq-white);
}

@media (max-width: 575px) {
  .register__new .login__content .form__bottom--back {
    position: unset;
    left: unset;
    top: unset;
    width: 100%;
    height: 45px;
    margin-top: 10px;
    -webkit-box-pack: center;
    justify-content: center;
  }
}

@media (max-width: 575px) {
  .register__new .login__content .form__bottom--back i {
    margin-right: 10px;
  }
}

.register__new .login__content .form__top {
  padding: 44px 100px 30px;
}

@media (max-width: 575px) {
  .register__new .login__content .form__top {
    padding: 25px 15px;
  }
}

.register__new .login__content .form__top p {
  color: var(--iq-darkgrey);
  text-align: center;
  font-size: 14px;
}

@media (max-width: 479px) {
  .register__new .login__content .form__top p {
    font-size: 12px;
  }
}

.register__new .login__content .form__top #register-information-primary {
  display: none;
}

.register__new .login__content .form--span {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  margin-top: 13px;
  position: relative;
}

@media (max-width: 575px) {
  .register__new .login__content .form--span {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.register__new .login__content .form--span .error-text,
.register__new .login__content .form--span .success-text {
  position: absolute;
  left: 0;
  top: -10px;
}

@media (max-width: 575px) {
  .register__new .login__content .form--span .error-text,
  .register__new .login__content .form--span .success-text {
    position: unset;
    left: unset;
    top: unset;
  }
}

.register__new .login__content .form--span span {
  position: unset;
  top: unset;
  right: unset;
  padding: 5px 0;
}

@media (max-width: 575px) {
  .register__new .login__content .form--span span {
    width: 100%;
  }
}

.register__new .login__content .form #form_register_verify-otp input {
  width: 100%;
  max-width: 100%;
}

.register__new .login__content .form #form_register_verify-otp span {
  position: unset;
  top: unset;
  right: unset;
  height: 50px;
  margin-left: 13px;
  background-color: var(--iq-white);
  border: 1px solid var(--iq-dullgrey);
  color: var(--iq-dullgrey);
}

.register__new .login__content .form #form_register_verify-otp span:hover {
  border-color: #0077c8;
  color: #0077c8;
}

.register__new .login__content .form__requirements {
  margin-top: 15px;
}

.register__new .login__content .form__requirements p {
  font-size: 13px;
  color: var(--iq-darkgrey);
  margin-bottom: 15px;
}

.register__new .login__content .form__requirements--row {
  display: -webkit-box;
  display: flex;
}

@media (max-width: 1199px) {
  .register__new .login__content .form__requirements--row {
    flex-wrap: wrap;
  }
}

@media (max-width: 991px) {
  .register__new .login__content .form__requirements--row {
    flex-wrap: nowrap;
  }
}

@media (max-width: 575px) {
  .register__new .login__content .form__requirements--row {
    flex-wrap: wrap;
  }
}

.register__new .login__content .form__requirements--row > div:not(:last-child) {
  margin-right: 5px;
}

@media (max-width: 575px) {
  .register__new .login__content .form__requirements--row > div {
    margin-bottom: 5px;
  }
}

.register__new .login__content .form__requirements--req {
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #f2f2f2;
}

@media (max-width: 1199px) {
  .register__new .login__content .form__requirements--req {
    margin-bottom: 5px;
  }
}

@media (max-width: 991px) {
  .register__new .login__content .form__requirements--req {
    margin-bottom: 0;
  }
}

.register__new .login__content .form__requirements--req.active {
  background-color: var(--iq-pink);
}

.register__new .login__content .form__requirements--req.active p {
  color: var(--iq-white);
}

.register__new .login__content .form__requirements--req p {
  font-size: 12px;
  color: var(--iq-black);
  margin-bottom: 0;
}

.register__success h1 {
  color: var(--iq-darkblack);
  text-align: center;
  margin-bottom: 40px;
}

.register__success--img {
  width: 154px;
  min-width: 154px;
  max-width: 100%;
  margin-right: 45px;
}

@media (max-width: 767px) {
  .register__success--img {
    margin-bottom: 10px;
    margin-right: 0;
  }
}

.register__success--img img {
  max-width: 100%;
}

.register__success--box {
  background-color: var(--iq-white);
  max-width: 1080px;
  margin: 0 auto;
  padding: 45px 80px 45px 70px;
  display: -webkit-box;
  display: flex;
  display: none;
}

@media (max-width: 767px) {
  .register__success--box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
  }
}

@media (max-width: 479px) {
  .register__success--box {
    padding: 30px;
  }
}

.register__success--box.active {
  display: -webkit-box;
  display: flex;
}

.register__success--box h2 {
  color: var(--iq-pink);
  font-size: 25px;
  margin-bottom: 10px;
}

@media (max-width: 479px) {
  .register__success--box h2 {
    font-size: 20px;
  }
}

.register__success--box p {
  color: var(--iq-darkblack);
  line-height: 26px;
  /* margin-bottom: 23px; */
}

.register__success--cart a {
  width: 237px;
  max-width: 100%;
}

@media (max-width: 479px) {
  .register__success--cart a {
    width: 100%;
  }
}

.register__success--btnrow {
  display: -webkit-box;
  display: flex;
}

@media (max-width: 479px) {
  .register__success--btnrow {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.register__success--btnrow a {
  width: 160px;
  max-width: 100%;
}

@media (max-width: 479px) {
  .register__success--btnrow a {
    width: 100%;
  }
}

.register__success--btnrow a:first-child {
  margin-right: 10px;
}

@media (max-width: 479px) {
  .register__success--btnrow a:first-child {
    margin-bottom: 10px;
  }
}

.page-rtl .register__new .login__content .form__bottom--create .form-group a {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  left: unset;
  right: 0;
}

.page-rtl
  .register__new
  .login__content
  .form__requirements--row
  > div:not(:last-child) {
  margin-right: 0;
  margin-left: 5px;
}

.page-rtl .register__new .login__content .form .form-group .visibility-button {
  right: unset;
  left: 15px;
}

.page-rtl
  .register__new
  .login__content
  .form
  .form-group#form_register_confirm_passwd
  .info-icon,
.page-rtl
  .register__new
  .login__content
  .form
  .form-group#form_register_passwd
  .info-icon {
  right: unset;
  left: 40px;
}

@media (max-width: 575px) {
  .page-rtl
    .register__new
    .login__content
    .form
    .form-group#form_register_passwd
    .info-icon {
    top: 45px;
  }
}

@media (max-width: 392px) {
  .page-rtl
    .register__new
    .login__content
    .form
    .form-group#form_register_passwd
    .info-icon {
    top: 45px;
  }
}

@media (max-width: 342px) {
  .page-rtl
    .register__new
    .login__content
    .form
    .form-group#form_register_passwd
    .info-icon {
    top: 45px;
  }
}

.page-rtl .register__new .login__content .form--span .error-text,
.page-rtl .register__new .login__content .form--span .success-text {
  position: absolute;
  right: 0;
  top: -10px;
}

@media (max-width: 575px) {
  .page-rtl .register__new .login__content .form--span .error-text,
  .page-rtl .register__new .login__content .form--span .success-text {
    position: unset;
    right: unset;
    top: unset;
  }
}

.page-rtl .register__success--box {
  text-align: right;
}

.page-rtl .register__success--img {
  margin-right: 0;
  margin-left: 45px;
}

.page-rtl .register__success--btnrow a:first-child {
  margin-right: 0;
  margin-left: 10px;
}

@media (max-width: 479px) {
  .page-rtl .flag-picker .selectize-control.rtl .selectize-input:after {
    left: 15px !important;
  }
}

.login__new .form form {
  padding: 0;
  border: none;
  border-radius: 0;
}

@media (max-width: 575px) {
  .login__new .form form .form-group .input-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }
}

@media (max-width: 575px) {
  .login__new .form form .form-group .input-row .flag-picker {
    margin-bottom: 0;
  }
}

@media (max-width: 479px) {
  .login__new .form form .form-group .input-row .flag-picker {
    width: 120px;
    min-width: 120px;
  }
}

.login__new .form__secondchoice {
  display: none;
}

.login__new .form a.forgot-pw {
  color: #0077c8;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.login__new .login__content--link {
  color: #0077c8;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.login__switch {
  height: 55px;
  width: 100%;
  max-width: 100%;
  background: rgba(0, 169, 224, 0.11);
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 3px;
  margin-bottom: 35px;
}

.login__switch,
.login__switch a {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 31px;
}

.login__switch a {
  font-size: 17px;
  color: var(--iq-darkblack);
  width: 50%;
  height: 100%;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 400px) {
  .login__switch a {
    font-size: 13px;
  }
}

@media (min-width: 480px) {
  .login__switch a span:nth-child(2) {
    display: none;
  }
}

@media (max-width: 479px) {
  .login__switch a span:first-child {
    display: none;
  }
}

.login__switch a:hover {
  color: #0077c8;
}

.login__switch a.active {
  background-color: var(--iq-pink);
  color: var(--iq-white);
}

@media (max-width: 575px) {
  .forgotpw__new .forgotpw__content h1 {
    text-align: left;
  }
}

.forgotpw__new .form form {
  padding: 0;
  border: none;
  border-radius: 0;
}

.forgotpw__new .form form .form-group:not(:last-child) {
  margin-bottom: 30px;
}

@media (max-width: 479px) {
  .forgotpw__new .form form .form-group:not(:last-child) {
    margin-bottom: 18px;
  }
}

.forgotpw__new .form form .info-box {
  background: rgba(0, 169, 224, 0.11);
  padding: 15px 20px;
  border-radius: 5px;
  margin-bottom: 40px;
}

@media (max-width: 479px) {
  .forgotpw__new .form form .info-box {
    margin-bottom: 20px;
  }
}

.forgotpw__new .form form .info-box p {
  font-size: 14px;
  line-height: 25px;
  color: #0077c8;
  margin-bottom: 0;
  text-align: left;
}

.forgotpw__new .form .forgotpw__content--btn {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.forgotpw__new .form .forgotpw__content--btn button[type="submit"] {
  margin-top: 0;
}

@media (max-width: 479px) {
  .forgotpw__new .form .forgotpw__content--btn button[type="submit"] {
    height: 45px;
  }
}

@media (max-width: 575px) {
  .page-rtl .forgotpw__new .forgotpw__content h1 {
    text-align: right;
  }
}

.page-rtl .forgotpw__new .form .info-box p {
  text-align: right;
}

.addcredit__wrapper {
  max-width: 855px;
}

.addcredit__box {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid var(--iq-dullgrey);
  position: relative;
}

@media (max-width: 767px) {
  .addcredit__box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: start;
    align-items: flex-start;
  }
}

.addcredit__box--left {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 565px;
  max-width: 100%;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .addcredit__box--left {
    width: 475px;
  }
}

@media (max-width: 767px) {
  .addcredit__box--left {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: start;
    align-items: flex-start;
    width: 100%;
  }
}

.addcredit__box--left h3 {
  font-size: 20px;
  color: var(--iq-darkblack);
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .addcredit__box--left h3 {
    margin-bottom: 35px;
  }
}

@media (max-width: 479px) {
  .addcredit__box--left h3 {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .addcredit__box--text {
    position: absolute;
    top: 22px;
    right: 0;
  }
}

.addcredit__box--text p {
  color: var(--iq-darkgrey);
}

@media (max-width: 479px) {
  .addcredit__box--text p {
    font-size: 12px;
  }
}

.addcredit__box--btn {
  width: 170px;
  max-width: 100%;
}

@media (max-width: 767px) {
  .addcredit__box--btn {
    width: 100%;
    max-width: 100%;
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .addcredit__box--btn a {
    height: 70px;
    font-size: 22px;
  }
}

.addcredit__info {
  padding: 20px 0;
  border-bottom: 1px solid var(--iq-dullgrey);
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.addcredit__info--voucher {
  max-width: 580px;
}

.addcredit__info--voucher.success p {
  display: block;
}

@media (max-width: 767px) {
  .addcredit__info--voucher.desktop p {
    display: none;
  }
}

.addcredit__info--voucher.mobile {
  width: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  justify-content: center;
  margin-top: 20px;
}

@media (min-width: 768px) {
  .addcredit__info--voucher.mobile {
    display: none;
  }
}

.addcredit__info--voucher.mobile p {
  text-align: center;
}

@media (min-width: 768px) {
  .addcredit__info--voucher.mobile p {
    display: none;
  }
}

.addcredit__info--voucher p {
  color: #797979;
  text-align: left;
  display: none;
}

.addcredit__info--text p {
  color: var(--iq-darkblack);
  text-align: right;
}

.addcredit__info--text p:not(:last-child) {
  margin-bottom: 5px;
}

.addcredit__info--text span {
  color: var(--iq-darkblack);
  margin-left: 5px;
}

.addcredit__cards {
  padding: 20px 0;
  border-bottom: 1px solid var(--iq-dullgrey);
}

@media (max-width: 767px) {
  .addcredit__cards--top {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 20px;
  }
}

@media (max-width: 420px) {
  .addcredit__cards--top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse;
    -webkit-box-align: end;
    align-items: flex-end;
  }
}

.addcredit__cards--top p {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .addcredit__cards--top p {
    margin-bottom: 0;
  }
}

@media (max-width: 420px) {
  .addcredit__cards--top p {
    margin-bottom: 5px;
  }
}

@media (min-width: 768px) {
  .addcredit__cards--top img {
    display: none;
  }
}

.addcredit__cards p {
  color: var(--iq-darkblack);
  text-align: right;
}

.addcredit__cards p span {
  color: var(--iq-darkblack);
  margin-left: 5px;
}

.addcredit__cards--bottom {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .addcredit__cards--bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse;
  }
}

.addcredit__cards--left {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.addcredit__cards--left p {
  color: #797979;
}

@media (max-width: 380px) {
  .addcredit__cards--left p {
    font-size: 12px;
  }
}

.addcredit__cards--right {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

@media (max-width: 767px) {
  .addcredit__cards--right {
    margin-bottom: 40px;
  }
}

.addcredit__cards--right img {
  margin-right: 20px;
}

@media (max-width: 767px) {
  .addcredit__cards--right img {
    display: none;
  }
}

.addcredit__cards--img {
  width: 135px;
  margin-right: 10px;
}

.addcredit__cards--img img {
  max-width: 100%;
  height: 28px;
}

.addcredit__cards--btn {
  width: 170px;
}

@media (max-width: 767px) {
  .addcredit__cards--btn {
    width: 100%;
    max-width: 100%;
  }
}

.addcredit__cards--btn button {
  width: 100%;
  max-width: 100%;
}

@media (max-width: 767px) {
  .addcredit__cards--btn button {
    height: 70px;
    font-size: 22px;
  }
}

.addcredit__bottom p {
  font-size: 14px;
  color: #797979;
  margin-top: 10px;
}

.addcredit .form .form-group .input-box {
  width: 300px;
}

@media (max-width: 767px) {
  .addcredit .form .form-group .input-box {
    width: 100%;
    max-width: 100%;
  }
}

.addcredit .form .form-group .input-box input[type="number"],
.addcredit .form .form-group .input-box input[type="text"] {
  border: 1px solid var(--iq-pink);
  text-align: left;
  padding: 20px 20px 15px 95px;
}

.addcredit .form .form-group .input-box--divider {
  content: "";
  display: block;
  width: 1px;
  height: 50px;
  background-color: var(--iq-pink);
  position: absolute;
  top: 0;
  left: 70px;
}

.addcredit .form .form-group .input-box--voucher input[type="text"] {
  padding: 20px 10px 15px;
}

.addcredit
  .form
  .form-group
  .input-box--voucher
  input[type="text"]::-webkit-input-placeholder {
  color: #b2b2b2;
}

.addcredit
  .form
  .form-group
  .input-box--voucher
  input[type="text"]::-moz-placeholder {
  color: #b2b2b2;
}

.addcredit
  .form
  .form-group
  .input-box--voucher
  input[type="text"]:-ms-input-placeholder {
  color: #b2b2b2;
}

.addcredit
  .form
  .form-group
  .input-box--voucher
  input[type="text"]::-ms-input-placeholder {
  color: #b2b2b2;
}

.addcredit
  .form
  .form-group
  .input-box--voucher
  input[type="text"]::placeholder {
  color: #b2b2b2;
}

@media (min-width: 768px) {
  .addcredit .form .form-group .input-box .error-text {
    position: absolute;
    bottom: -21px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    left: 50%;
  }
}

.addcredit .form .form-group .input-box .error-text p {
  position: unset;
  margin-top: 5px;
  text-align: center;
}

.addcredit .form .form-group.error .input-box input {
  border-color: var(--iq-pink) !important;
}

.addcredit .form .form-group.success .addcredit__info--voucher p {
  display: block;
}

.page-rtl .base-timer__svg {
  -webkit-transform: unset;
  transform: unset;
}

.page-rtl .svg-wrapp {
  -webkit-transform: rotate(-270deg);
  transform: rotate(-270deg);
}

.page-rtl .header__inner,
.page-rtl .header__inner--right {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
}

.page-rtl .header__dropdown.primary,
.page-rtl .header__dropdown.secondary {
  left: 0 !important;
}

.page-rtl .header__dropdown.primary ul img {
  right: unset;
  left: 25px;
}

.page-rtl .header__dropdown.primary ul li {
  margin: 0 !important;
}

.page-rtl .header__balance--item {
  text-align: right;
}

.page-rtl .header__nav,
.page-rtl .header__nav--menu {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
}

.page-rtl .header__nav--menu li:not(:last-child) {
  margin-right: 0;
  margin-left: 20px;
}

.page-rtl .header__nav--menu li a {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;

  -webkit-box-align: center;
  align-items: center;
}

.page-rtl .header__nav--menu li a i {
  margin: 0 10px 0 0;
}

.page-rtl .header__nav--dropdown {
  right: 0;
}

.page-rtl .header__nav--dropdown ul li,
.page-rtl .header__nav--dropdown ul li:not(:last-child) {
  margin-left: 0;
}

.page-rtl .header__nav--dropdown ul li a i {
  -webkit-transform: rotate(180deg) !important;
  transform: rotate(180deg) !important;
}

.page-rtl .header__nav--dropdown.secondary {
  left: -234px;
}

.page-rtl .header__login-menu {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
}

.page-rtl .header__login-menu a:not(:last-child) {
  margin-left: 20px;
  margin-right: 0;
}

.page-rtl .header__login-menu--welcome:after {
  right: unset;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(var(--iq-white)),
    to(hsla(0, 0%, 100%, 0))
  );
  background: linear-gradient(90deg, var(--iq-white), hsla(0, 0%, 100%, 0));
  left: 0;
}

.page-rtl .header__login-menu.logged > ul {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
}

.page-rtl .header__login-menu.logged > ul li:not(:last-child) {
  margin-right: 0;
  margin-left: 28px;
}

.page-rtl .header__login-menu.logged > ul li a {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;

  -webkit-box-align: center;
  align-items: center;
}

.page-rtl .header__login-menu.logged > ul li a i,
.page-rtl .header__login-menu.logged > ul li a strong {
  margin-left: 0;
  margin-right: 5px;
}

.page-rtl .header__login-menu.logged ul li a {
  margin-left: 0;
}

.page-rtl .header__login-menu.logged ul li ul li a {
  text-align: right;
}

.page-rtl .header__login-menu--mobile {
  -webkit-transform: translate(-400%);
  transform: translate(-400%);
  right: unset;
}

.page-rtl .header__login-menu--mobile.active {
  right: unset;
  left: 0;
  -webkit-transform: none;
  transform: none;
}

.page-rtl .header__login-menu--mobile .nav-wrapper nav ul li .accordion-top,
.page-rtl
  .header__login-menu--mobile
  .nav-wrapper
  nav
  ul
  li
  .accordion-top.countries
  a {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
}

.page-rtl
  .header__login-menu--mobile
  .nav-wrapper
  nav
  ul
  li
  .accordion-top.countries
  a
  img {
  margin-right: 0;
  margin-left: 10px;
}

.page-rtl .header__login-menu--mobile .nav-wrapper nav ul li a {
  margin-left: 0;
  text-align: right;
  margin-right: 0;
}

.page-rtl .header__login-menu--mobile .nav-wrapper nav ul li p {
  text-align: right;
}

.page-rtl .header__login-menu--mobile .nav-wrapper nav ul ul li a {
  margin-left: 0;
  text-align: right;
}

.page-rtl
  .header__login-menu--mobile
  .nav-wrapper
  nav
  ul
  ul.countries--list
  li
  a {
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
}

.page-rtl
  .header__login-menu--mobile
  .nav-wrapper
  nav
  ul
  ul.countries--list
  li
  img {
  margin-right: 0;
  margin-left: 10px;
}

.page-rtl .header__cart--content {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
}

.page-rtl .header__toggle {
  margin-left: 0;
  margin-right: 22px;
}

.page-rtl .hp-navigation__box {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.page-rtl .hp-navigation__box--btn {
  width: 100%;
  display: -webkit-box;
  display: flex;
}

@media (max-width: 340px) {
  .page-rtl .hp-navigation__box--btn {
    width: unset;
  }
}

.page-rtl .hp-navigation__inner--top .hp-navigation__box--textbg {
  width: 205px;
}

@media (max-width: 479px) {
  .page-rtl .hp-navigation__inner--top .hp-navigation__box--textbg {
    width: 203px;
  }
}

@media (max-width: 375px) {
  .page-rtl .hp-navigation__inner--top .hp-navigation__box--textbg {
    width: 155px;
  }
}

.page-rtl .hp-navigation__inner--top .hp-navigation__box--textbg h2 {
  font-size: 32px;
  max-width: 220px;
}

@media (max-width: 479px) {
  .page-rtl .hp-navigation__inner--top .hp-navigation__box--textbg h2 {
    font-size: 30px;
  }
}

@media (max-width: 375px) {
  .page-rtl .hp-navigation__inner--top .hp-navigation__box--textbg h2 {
    font-size: 23px;
  }
}
.page-rtl .download__platforms {
  -webkit-box-pack: start;
  justify-content: flex-start;
}

@media (max-width: 479px) {
  .page-rtl .download__platforms {
    padding-left: 20px;
    padding-right: 0;
  }
}

@media (max-width: 375px) {
  .page-rtl .download__platforms {
    bottom: 50px;
  }
}

.page-rtl .download__platforms p {
  margin-right: 0;
  margin-left: 20px;
}

@media (max-width: 479px) {
  .page-rtl .download__platforms p {
    margin: 0 0 5px;
    padding-right: 0;
  }
}

.page-rtl .download__buttons a div {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;

  -webkit-box-pack: end;
  justify-content: flex-end;
  width: unset;
}

@media (max-width: 479px) {
  .page-rtl .download__buttons a div {
    width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    text-align: right;
  }
}

@media (max-width: 479px) {
  .page-rtl .download__buttons a div img {
    margin-left: 0;
  }
}

.page-rtl .download__buttons a:first-child {
  margin-right: 0;
  margin-left: 30px;
}

@media (max-width: 479px) {
  .page-rtl .download__buttons a:first-child {
    margin: 0 0 0 15px;
  }
}

@media (max-width: 375px) {
  .page-rtl .download__buttons a:first-child {
    margin: 0 0 5px;
  }
}

@media (max-width: 479px) {
  .page-rtl .download__buttons {
    padding-right: 0;
    padding-left: 20px;
  }
}

.page-rtl .cookie-bar {
  text-align: right;
}

.page-rtl .cookie-bar__buttons a:first-child {
  margin-right: 0;
}

.page-rtl .footer__nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
}

@media (max-width: 575px) {
  .page-rtl .footer__nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.page-rtl .footer__nav--text.desktop {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
}

@media (max-width: 575px) {
  .page-rtl .footer__nav--text.desktop {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.page-rtl .footer__nav--text.desktop ul {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
}

.page-rtl .footer__nav--text.desktop ul li:not(:last-child) {
  margin-right: 0;
  margin-left: 25px;
}

.page-rtl .footer__nav--text.desktop ul li a {
  font-size: 13px;
}

@media (max-width: 575px) {
  .page-rtl .footer__nav--img {
    margin: 0;
  }
}

.page-rtl .footer__socials {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
}

@media (max-width: 575px) {
  .page-rtl .footer__socials {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse;
  }
}

.page-rtl .footer__socials p {
  margin-right: 0;
  margin-left: 20px;
  font-size: 13px;
}

@media (max-width: 575px) {
  .page-rtl .footer__socials p {
    margin-left: 0;
  }
}

.page-rtl .footer__socials--item {
  margin-right: 0;
}
.page-rtl .footer__socials--platforms p:not(:last-child) {
  margin-right: 0;
  margin-left: 15px;
}

@media (max-width: 575px) {
  .page-rtl .shopping-cart__box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.page-rtl .shopping-cart__box--withdraw-success .shopping-cart__box--right {
  text-align: right;
  -webkit-box-align: start;
  align-items: flex-start;
}

@media (max-width: 575px) {
  .page-rtl .shopping-cart__box--withdraw-success .shopping-cart__box--right {
    text-align: center;
    -webkit-box-align: center;
    align-items: center;
  }
}

.page-rtl .shopping-cart__box--buttons a:first-child {
  margin-left: 30px;
}

@media (max-width: 575px) {
  .page-rtl .shopping-cart__box--buttons a:first-child {
    margin-bottom: 15px;
    margin-left: 0;
  }
}

.page-rtl .shopping-cart__content .cart__selectboxes--select:not(:last-child) {
  margin-right: 0;
  margin-left: 5px;
}

@media (max-width: 575px) {
  .page-rtl
    .shopping-cart__content
    .cart__selectboxes--select:not(:last-child) {
    margin-left: 0;
  }
}

.page-rtl .shopping-cart__content .cart__numbers--number-row {
  padding-right: 0;
}

.page-rtl
  .shopping-cart__content
  .cart__summary--voucher
  input::-webkit-input-placeholder {
  font-size: 13px;
  text-align: right;
}

.page-rtl
  .shopping-cart__content
  .cart__summary--voucher
  input::-moz-placeholder {
  font-size: 13px;
  text-align: right;
}

.page-rtl
  .shopping-cart__content
  .cart__summary--voucher
  input:-ms-input-placeholder {
  font-size: 13px;
  text-align: right;
}

.page-rtl
  .shopping-cart__content
  .cart__summary--voucher
  input::-ms-input-placeholder {
  font-size: 13px;
  text-align: right;
}

.page-rtl .shopping-cart__content .cart__summary--voucher input::placeholder {
  font-size: 13px;
  text-align: right;
}

.page-rtl .shopping-cart__content .cart__summary--delete {
  right: unset;
  left: 10px;
}

.page-rtl .previous-drawn__details,
.page-rtl .previous-drawn__top {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
}

.page-rtl .previous-drawn__text {
  text-align: right;
  margin-left: 0;
}

@media (max-width: 575px) {
  .page-rtl .previous-drawn__text {
    margin-right: 0;
  }
}

.page-rtl .previous-drawn__text h1 {
  margin-bottom: 10px;
}

.page-rtl .previous-drawn__text h2 {
  margin-bottom: 7px;
}

.page-rtl .previous-drawn__text p strong {
  margin-left: 5px;
}

.page-rtl .previous-drawn__button a i {
  right: unset;
  left: 20px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.page-rtl .previous-drawn__buttons {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
}

@media (max-width: 575px) {
  .page-rtl .previous-drawn__buttons {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse;
  }
}

.page-rtl .previous-drawn__select p {
  text-align: right;
}

@media (max-width: 575px) {
  .page-rtl .previous-drawn .table-main table thead tr th:first-child {
    padding: 15px 15px 10px 0;
  }
}

@media (max-width: 575px) {
  .page-rtl .previous-drawn .table-main table thead tr th:nth-child(2) {
    padding: 15px 30px 10px 0;
  }
}

@media (max-width: 575px) {
  .page-rtl .previous-drawn .table-main table thead tr th:last-child {
    padding: 15px 15px 10px 0;
  }
}

@media (max-width: 575px) {
  .page-rtl .previous-drawn .table-main table thead tr th:not(:last-child) {
    border-left: 0;
  }
}

@media (max-width: 575px) {
  .page-rtl .previous-drawn .table-main table tbody tr td:nth-child(2) {
    padding: 15px 30px 12px 15px;
  }
}

.page-rtl .myaccount__wrapper .myaccount--col:first-child {
  padding-right: 15px;
  padding-left: 0;
}

.page-rtl .myaccount__wrapper .myaccount--col:last-child {
  padding-left: 15px;
  padding-right: 0;
  border-radius: 4px 0 0 4px;
}

@media (max-width: 575px) {
  .page-rtl .myaccount__wrapper .myaccount--col:last-child {
    padding-right: 15px;
    border-radius: 4px;
  }
}

.page-rtl .myaccount__content {
  border-radius: 4px 0 0 4px;
}

@media (max-width: 575px) {
  .page-rtl .myaccount__content {
    border-radius: 4px;
  }
}

.page-rtl .myaccount__sidebar {
  border-radius: 0 4px 4px 0;
  border-right: 1px solid var(--iq-dullgrey);
  border-left: none;
}

.page-rtl .myaccount__sidebar ul {
  padding-right: 0;
}

.page-rtl .myaccount__sidebar ul li:first-child a {
  border-top-left-radius: 0;
  border-top-right-radius: 4px;
}

.page-rtl .myaccount__sidebar ul li a:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.page-rtl .myaccount__sidebar.mobile {
  border-right: none;
}

.page-rtl .my-profile__nav ul {
  padding-right: 0;
}

.page-rtl .ticket-history__details--img {
  margin-right: 0;
  margin-left: 18px;
}

.page-rtl .ticket-history__details--ticket p {
  margin-right: 0;
  margin-left: 5px;
}

.page-rtl .ticket-history__buttons a:first-child {
  margin-right: 0;
  margin-left: 10px;
}

@media (max-width: 575px) {
  .page-rtl .ticket-history__box,
  .page-rtl .ticket-history__box--left {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.page-rtl .ticket-history__box--left p {
  margin-left: 0;
  margin-right: 20px;
}

.page-rtl
  .favourite-numbers__content
  .form
  form
  .form-group__input-row
  input:not(:last-child) {
  margin: 0 0 0 13px;
}

.page-rtl .favourite-numbers__item {
  margin: 0 0 25px 8px;
}

.page-rtl .form .form-group label,
.page-rtl .form_favourite-numbers .btn.btn--primary {
  font-family: "Swissra-Bold";
}

.page-rtl .favourite-numbers__item:nth-child(3),
.page-rtl .favourite-numbers__item:nth-child(6),
.page-rtl .favourite-numbers__item:nth-child(9) {
  margin-left: 0;
}

.page-rtl .favourite-numbers__content .form form .form-group__input-row input {
  text-align: center !important;
}

.page-rtl .transfer {
  text-align: right;
}

.page-rtl .transfer__button {
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.page-rtl .transfer__right--currency {
  margin-right: 15px;
  margin-left: 0;
}

.page-rtl .pagination ul {
  padding-right: 0;
}

.page-rtl .pagination ul li:not(:last-child) {
  margin-right: 0;
  margin-left: 7px;
}

.page-rtl .my-games__details--img {
  margin-right: 0;
  margin-left: 18px;
}

.page-rtl .my-games__details--ticket p {
  margin-right: 0;
  margin-left: 5px;
}

.page-rtl .payment__card--text {
  margin-right: 0;
  margin-left: 13px;
  direction: ltr;
}

.page-rtl .payment__card--checkbox input {
  margin-right: 0;
  margin-left: 40px;
}

@media (max-width: 575px) {
  .page-rtl .favourite-numbers__item {
    width: calc(50% - 5px);
    max-width: 45%;
  }

  .page-rtl .favourite-numbers__item:nth-child(odd) {
    margin: 0 0 20px auto;
  }

  .page-rtl .favourite-numbers__item:nth-child(even) {
    margin: 0 auto 20px 0;
  }

  .page-rtl .payment__card--checkbox input {
    margin-right: 0;
    margin-left: 10px;
  }

  .favourite-numbers__item--heading h5 {
    font-size: 14px;
  }
}

.page-rtl .payment__card--checkbox.empty {
  margin-left: 40px;
  margin-right: 0;
}

@media (max-width: 479px) {
  .page-rtl .payment__card--checkbox.empty {
    margin-left: 10px;
    margin-right: 0;
  }
}

.page-rtl .payment__card--checkbox .checkmark {
  left: unset;
  right: 7px;
}

.page-rtl .payment__button button {
  width: 150px;
}

.page-rtl .payment__wrapper--previous {
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.page-rtl .payment__wrapper--previous a {
  margin-left: 12px;
  margin-right: 0;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.page-rtl .payment--box span {
  margin-left: 0;
  margin-right: 5px;
}

.page-rtl .verification {
  text-align: right;
}

.page-rtl .verification__banner h2 {
  left: unset;
  right: 70px;
}

@media (max-width: 575px) {
  .page-rtl .verification__banner h2 {
    right: 20px;
    top: 20px;
    max-width: 100px;
  }
}

.page-rtl .verification__banner--text {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: start;
  align-items: flex-start;
  left: unset;
  right: 50px;
}

@media (max-width: 575px) {
  .page-rtl .verification__banner--text {
    right: 20px;
    padding-left: 20px;
    padding-right: 0;
  }
}

@media (max-width: 479px) {
  .page-rtl .verification__banner--text {
    padding-left: 0;
  }
}

.page-rtl .verification__section {
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.page-rtl .verification__photo--buttons a {
  margin-left: 0;
  margin-right: 10px;
}

.page-rtl .verification__box {
  margin-left: 0;
  margin-right: 15px;
}

.page-rtl .maintenance__text a i {
  right: unset;
  left: 20px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.page-rtl .shopping-cart {
  text-align: right;
}

.page-rtl .cart__top--title:last-child h3 {
  text-align: left;
}

.page-rtl .cart__details--img {
  margin-right: 0;
  margin-left: 18px;
}

.page-rtl .cart__details--ticket p {
  margin-right: 0;
  margin-left: 5px;
}

.page-rtl .cart__summary > div > span {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
}

.page-rtl .cart__summary--img {
  left: unset;
  right: 0;
}

@media (max-width: 575px) {
  .page-rtl .cart__summary--topup a {
    text-align: left;
  }
}

.page-rtl .cart__info--img {
  left: unset;
  right: 0;
}

@media (max-width: 575px) {
  .page-rtl .cart__info--topup a {
    text-align: left;
  }
}

.page-rtl .cart__bottom--checkout button {
  margin-left: 0;
  margin-right: 5px;
}

@media (max-width: 575px) {
  .page-rtl .cart__bottom--checkout button {
    margin-right: 0;
  }
}

.page-rtl .number-picker {
  text-align: right;
}

.page-rtl .number-picker__picker {
  margin: 0 0 14px 14px;
}

@media (max-width: 575px) {
  .page-rtl .number-picker__picker {
    margin: 0 0 14px;
  }
}

.page-rtl .number-picker__picker:nth-child(5n) {
  margin-right: 0;
  margin-left: 0;
}

.page-rtl .number-picker__picker--name span {
  margin-right: 5px;
  margin-left: 10px;
}

.page-rtl .number-picker__buttons--submit {
  padding: 4px 15px 4px 4px;
}

.page-rtl .number-picker__buttons--submit p {
  margin-right: 0;
  margin-left: 16px;
}

.page-rtl .number-picker__buttons span {
  margin-right: 0;
  margin-left: 8px;
  font-size: 17px;
}

.page-rtl .number-picker__buttons span img {
  margin-right: 0;
  margin-left: 14px;
}

.page-rtl .number-picker__buttons a {
  margin-right: 0;
  margin-left: 8px;
}
@media (max-width: 991px) {
  .page-rtl .number-picker__buttons span,
  #button_add_to_cart {
    text-align: center;
    font-size: 15px;
  }
  #button_add_to_cart {
    padding: 5px 20px;
  }
}

@media (max-width: 575px) {
  .page-rtl .number-picker__buttons a {
    margin-left: 0;
    margin-bottom: 8px;
  }
}

@media (max-width: 575px) {
  .page-rtl .number-picker__numbers {
    padding: 20px 10px;
  }
}

.page-rtl .number-picker__numbers .betNum {
  margin: 0 0 7px 7px;
}

.page-rtl .number-picker__numbers .betNum:nth-child(5n) {
  margin-left: 0;
  margin-right: 0;
}

@media (max-width: 575px) {
  .page-rtl .number-picker__numbers .betNum:nth-child(5n) {
    margin-right: 0;
    margin-left: 7px;
  }
}

.page-rtl .number-picker__buttons--submit p {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
}

.page-rtl .number-picker__buttons--submit p span {
  background-color: transparent;
  color: initial;
  margin-left: 0;
  margin-right: 5px;
  margin-top: 2px;
}

.page-rtl .total-balance__bottom {
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  align-items: flex-start;
}

.page-rtl .page-numbers.next i,
.page-rtl .page-numbers.previous i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.page-rtl .ticket-page .ticket {
  text-align: right;
}

@media (max-width: 420px) {
  .page-rtl .ticket-page .ticket {
    background-image: url(../img/Iraq-web_ticket-sidestrips-sml.png),
      url(../img/Iraq-web_ticket-sidestrips-sml.png);
  }
}

.page-rtl .ticket-page .ticket--wrapper {
  background-image: url(../img/Iraq-web_ticket-sidestrips.png),
    url(../img/Iraq-web_ticket-sidestrips.png);
}

@media (max-width: 420px) {
  .page-rtl .ticket-page .ticket--wrapper {
    background-image: none;
  }
}

@media (max-width: 420px) {
  .page-rtl .ticket-page .ticket__img {
    width: 200px;
  }
}

.page-rtl .welcome {
  text-align: right;
}

.page-rtl .welcome a i {
  right: unset;
  left: 20px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.page-rtl .login__content .form h2 {
  text-align: center;
}

.page-rtl .login__new .login__switch a {
  font-size: 13px;
}

@media (max-width: 575px) {
  .page-rtl .my-cards__content .payment__card--img {
    margin-right: 0;
    margin-left: 10px;
  }
}

.page-rtl .table-main table thead tr th:first-child {
  border-radius: 0 5px 0 0;
}

.page-rtl .table-main table thead tr th:last-child {
  border-radius: 5px 0 0 0;
}

.page-rtl .table-main table thead tr th:not(:last-child) {
  border-right: none;
  border-left: 1px solid var(--iq-orange);
}

.page-rtl .table-main table tbody tr td {
  padding: 20px 20px 15px 0;
}

@media (max-width: 575px) {
  .page-rtl .table-main table tbody tr td {
    padding: 20px 20px 15px;
  }
}

@media (max-width: 575px) {
  .page-rtl .table-main table tbody tr td:before {
    left: unset;
    right: 15px;
  }
}

.page-rtl .new-password__content .form h2 {
  right: unset;
  left: 10px;
}

.page-rtl .balance-summary--number {
  direction: ltr;
}

.page-rtl .balance-summary--ticketid {
  display: inline-block;
  direction: ltr;
}

.page-rtl .bottle-picker__inner {
  text-align: right;
}

.page-rtl .bottle-picker__buttons--value {
  margin-right: 0;
  margin-left: 10px;
}

.page-rtl .bottle-picker__buttons--value .value-button:first-child {
  border-left: 0;
  border-right: 1px solid var(--iq-dullgrey);
  border-radius: 0 4px 4px 0;
}

.page-rtl .bottle-picker__buttons--value .value-button:last-child {
  border-left: 1px solid var(--iq-dullgrey);
  border-right: 0;
  border-radius: 4px 0 0 4px;
}

.page-rtl .bottle-picker__buttons--value input {
  text-align: center !important;
}

.page-rtl .bottle-picker__buttons--submit p {
  text-align: left;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

@media (max-width: 575px) {
  .page-rtl .bottle-picker__buttons--submit p {
    -webkit-box-pack: start;
    justify-content: flex-start;
  }
}

.page-rtl .bottle-picker__buttons--submit p > span {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
}

.page-rtl .bottle-picker__buttons--submit p > span > span {
  margin-right: 5px;
}

.page-rtl .not-found__buttons a {
  width: 220px;
}

.page-rtl .not-found__buttons a:not(:last-child) {
  margin-right: 0;
  margin-left: 15px;
}

.page-rtl .find__form .form-group .selectize-control .selectize-input {
  padding: 8px 8px 8px 30px;
}

.page-rtl .find__form .form-group .selectize-control .selectize-input:after {
  left: 15px !important;
}

.page-rtl .addcredit__cards--right img {
  margin-right: 0;
  margin-left: 20px;
}

.page-rtl .addcredit__cards--img {
  margin-right: 0;
  margin-left: 10px;
}

.page-rtl .addcredit__box--text {
  right: unset;
  left: 0;
}

.page-rtl .addcredit__info--text {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: end;
  align-items: flex-end;
}

.page-rtl .addcredit__info--text p {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
}

.page-rtl .addcredit__info--text span {
  margin-left: 0;
  margin-right: 5px;
}

.page-rtl .addcredit__cards--top {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

@media (max-width: 991px) {
  .page-rtl .addcredit__cards--top {
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
}

.page-rtl .addcredit__cards--top p {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
}

.page-rtl .addcredit__cards--top span {
  margin-left: 0;
  margin-right: 5px;
}

.page-rtl .addcredit .form .form-group .input-box p {
  left: unset;
  right: 10px;
}

.page-rtl .addcredit .form .form-group .input-box input[type="number"] {
  padding: 20px 95px 15px 20px;
}

.page-rtl .addcredit .form .form-group .input-box--divider {
  left: unset;
  right: 70px;
}

.page-rtl .gm-style .gm-style-iw-d {
  padding: 10px 10px 0 0;
}

.page-rtl .gm-style .gm-style-iw-d > div {
  text-align: right;
}

.page-rtl .gm-style .gm-style-iw-d > div a {
  text-align: right;
  max-width: 100px;
}

.page-rtl .share_tab {
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.page-rtl .share_tab li:not(:last-child) {
  margin-left: 10px;
  margin-right: 0;
}

.page-rtl .share_tab span {
  margin-left: 12px;
  margin-right: 0;
}

.page-rtl .form .alert__img {
  margin-right: 0 !important;
  margin-left: 40px !important;
}

@media (max-width: 575px) {
  .page-rtl .form .alert__img {
    margin-left: 0 !important;
  }
}

.page-rtl .form .form-group__options:first-child {
  margin-right: 0;
  margin-left: 140px;
}

.page-rtl .form .form-group__options ul {
  padding-right: 0;
}

.page-rtl .form .form-group__options--green,
.page-rtl .form .form-group__options--red {
  margin-right: 0;
  margin-left: 6px;
}

.page-rtl .form form,
.page-rtl .form form .form-group label {
  text-align: right;
}

.page-rtl .form form .form-group .info-icon {
  right: unset;
  left: 15px;
}

.page-rtl .form form .form-group span {
  right: unset;
  left: 40px;
}

.page-rtl .form form .form-group .input-row .flag-picker {
  margin-right: 0;
  margin-left: 15px;
}

.page-rtl .form form .form-group .input-box input {
  text-align: right !important;
}

.page-rtl .form form .form-group .input-box p {
  left: 10px;
  right: unset;
}

.page-rtl .form form .form-group .deposit--left {
  -webkit-box-align: end;
  align-items: flex-end;
}

.page-rtl .form form .form-group.error p {
  text-align: right;
}

.page-rtl .form form .form-group.check label input[type="checkbox"] {
  margin-right: 0;
  margin-left: 10px;
}

@media (max-width: 575px) {
  .page-rtl .form form .form-group#form_register_passwd .info-icon {
    top: 95px;
  }
}

@media (max-width: 392px) {
  .page-rtl .form form .form-group#form_register_passwd .info-icon {
    top: 115px;
  }
}

@media (max-width: 342px) {
  .page-rtl .form form .form-group#form_register_passwd .info-icon {
    top: 115px;
  }
}

.page-rtl .form form #form_manage-account_submit input {
  margin-right: 0 !important;
  margin-left: 15px;
}

@media (max-width: 479px) {
  .page-rtl .form form #form_deposit_amount .input-box {
    margin-right: 0;
  }
}

.page-rtl .form form#form_deposit .payment__cards {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
}

.page-rtl .form form#form_deposit .payment__cards--text {
  margin-right: 0;
  margin-left: 25px;
}

@media (max-width: 575px) {
  .page-rtl .form form#form_deposit .payment__cards--text {
    margin-left: 10px;
  }
}

.page-rtl .form form#form_deposit .payment__cards--right a {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.page-rtl .form form#form_payment-method .payment__cards {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
}

.page-rtl .form form#form_payment-method .payment__cards--text {
  margin-right: 0;
  margin-left: 25px;
}

@media (max-width: 575px) {
  .page-rtl .form form#form_payment-method .payment__cards--text {
    margin-left: 10px;
  }
}

.page-rtl .form form#form_payment-method .payment__cards--right a {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.page-rtl .form form#payment_form {
  text-align: left;
  direction: ltr;
}

.page-rtl .form form button i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.page-rtl .form .form-group .captcha-wrapper .send_otp {
  margin-left: 0;
}

@media (max-width: 768px) {
  .page-rtl .form .form-group .captcha-wrapper .send_otp {
    margin-right: 0;
  }
}

.page-rtl .register__new .login__content .form__requirements--row {
  flex-wrap: wrap;
}

.page-rtl .register__new .login__content .form__requirements--req {
  margin-bottom: 5px;
}

.page-rtl .draw_name span {
  display: inline-block;
}

.page-rtl
  input:not([type="checkbox"]):not([type="button"]):not([type="submit"]):not(
    [type="file"]
  ) {
  text-align: right;
}

.page-rtl input[type="checkbox"] {
  margin-right: 0;
  margin-left: 10px;
}

.page-rtl .remodal form .form-group__buttons .btn:first-child {
  margin-right: 0;
  margin-left: 10px;
}

.page-rtl .remodal__numberlist--number {
  margin-right: 0;
  margin-left: 7px;
}

.page-rtl .remodal ul li {
  text-align: right;
}

@media (min-width: 1200px) {
  .page-rtl .remodal.modal--change-fourth {
    padding: 26px 40px 65px;
  }
}

.page-rtl .remodal.modal--change-fourth ul {
  padding-left: 0;
  padding-right: 17px;
}

@media (min-width: 1200px) {
  .page-rtl .remodal.modal--change-fourth .hp-navigation__box--content h3 {
    margin-bottom: 93px;
  }
}

.page-rtl .remodal.modal--change-fourth .form-group__buttons p {
  text-align: right;
}

.page-rtl
  .modal--numberpicker
  .modal__numberpicker--numberpicker
  .number-picker__numbers
  .betNum:nth-child(5n) {
  margin-left: 7px;
  margin-right: 0;
}

.page-rtl
  .modal--numberpicker
  .modal__numberpicker--numberpicker
  .number-picker__numbers
  .betNum:nth-child(7n) {
  margin-left: 0;
}

@media (max-width: 380px) {
  .page-rtl
    .modal--numberpicker
    .modal__numberpicker--numberpicker
    .number-picker__numbers
    .betNum:nth-child(7n) {
    margin: 0 0 7px 7px;
  }
}

.page-rtl .modal--numberpicker .modal__selectboxes--select:not(:last-child) {
  margin-right: 0;
  margin-left: 10px;
}

@media (max-width: 366px) {
  .page-rtl .captcha-wrapper .g-recaptcha {
    -webkit-transform-origin: right;
    transform-origin: right;
  }
}

.page-rtl.logged_out .shopping-cart__content .cart__summary--voucher {
  width: 240px;
}

@media (max-width: 991px) {
  .page-rtl.logged_out .shopping-cart__content .cart__summary--voucher {
    width: 100%;
    max-width: 100%;
  }
}

.page-rtl.logged_out
  .shopping-cart__content
  .cart__summary--voucher
  input::-webkit-input-placeholder {
  font-size: 10px;
  text-align: right;
}

.page-rtl.logged_out
  .shopping-cart__content
  .cart__summary--voucher
  input::-moz-placeholder {
  font-size: 10px;
  text-align: right;
}

.page-rtl.logged_out
  .shopping-cart__content
  .cart__summary--voucher
  input:-ms-input-placeholder {
  font-size: 10px;
  text-align: right;
}

.page-rtl.logged_out
  .shopping-cart__content
  .cart__summary--voucher
  input::-ms-input-placeholder {
  font-size: 10px;
  text-align: right;
}

.page-rtl.logged_out
  .shopping-cart__content
  .cart__summary--voucher
  input::placeholder {
  font-size: 10px;
  text-align: right;
}

.page-rtl .selectize .subflex {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.page-rtl .downloadbanner__buttons {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
}

.skin_449 .droplink {
  line-height: 1;
}

@media (max-width: 992px) {
  .skin_449 .footer__content {
    padding-bottom: 75px;
  }
}

.skin_449 .priceproduct1 {
  max-width: 175px;
}

@media (max-width: 575px) {
  .skin_449 .priceproduct1 {
    max-width: 90px;
  }
}

.skin_449 .hp-navigation__box {
  padding-bottom: 0;
  border-radius: 0;
}

.skin_449 .hp-navigation__box--sum {
  padding: 10px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 15px;
  width: 300px;
  max-width: 100%;
  -webkit-box-pack: center;
  justify-content: center;
}

@media (max-width: 479px) {
  .skin_449 .hp-navigation__box--sum {
    width: 160px;
    max-width: 100%;
    border-radius: 5px;
    padding: 10px 5px;
  }
}

@media (max-width: 415px) {
  .skin_449 .hp-navigation__box--sum {
    width: 145px;
  }
}

@media (max-width: 385px) {
  .skin_449 .hp-navigation__box--sum {
    width: 115px;
    padding: 5px;
  }
}

.skin_449 .hp-navigation__box--sum p {
  color: #0078f5;
  font-size: 32px;
  height: 40px;
}

@media (max-width: 1199px) {
  .skin_449 .hp-navigation__box--sum p {
    font-size: 33px;
    height: 37px;
  }
}

@media (max-width: 575px) {
  .skin_449 .hp-navigation__box--sum p {
    font-size: 40px;
    height: 50px;
  }
}

@media (max-width: 479px) {
  .skin_449 .hp-navigation__box--sum p {
    font-size: 18px;
    height: 22px;
  }
}

@media (max-width: 415px) {
  .skin_449 .hp-navigation__box--sum p {
    font-size: 17px;
    height: 20px;
  }
}

@media (max-width: 385px) {
  .skin_449 .hp-navigation__box--sum p {
    font-size: 13px;
    height: 17px;
  }
}

.skin_449 .hp-navigation__box--sum.sum-grand {
  background-color: var(--iq-white);
}

.skin_449 .hp-navigation__box--sum.sum-raffle {
  background-color: #ffe765;
  margin-bottom: 5px;
}
@media (min-width: 1200px) {
  .skin_449 .hp-navigation__info .row [class*="col-"]:not(:last-child) {
    margin-bottom: 5px;
  }
}

.skin_449 .hp-navigation__info .row [class*="col-"]:last-child {
  margin-bottom: 0;
}

.skin_449 .hp-navigation__date {
  margin-bottom: 10px;
}

@media (max-width: 575px) {
  .skin_449 .hp-navigation__date {
    margin-bottom: 25px;
  }
}

@media (max-width: 479px) {
  .skin_449 .hp-navigation__date {
    margin-bottom: 0;
  }
}

@media (max-width: 385px) {
  .skin_449 .hp-navigation__date {
    margin-bottom: 5px;
  }
}

.skin_449 .hp-navigation__date p {
  color: #0078f5;
  font-size: 14px;
  text-align: center;
}

@media (max-width: 1199px) {
  .skin_449 .hp-navigation__date p {
    font-size: 17px;
  }
}

@media (max-width: 479px) {
  .skin_449 .hp-navigation__date p {
    font-size: 30px;
    line-height: 27px;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    text-align: left;
    color: var(--iq-white);
  }
}

@media (max-width: 415px) {
  .skin_449 .hp-navigation__date p {
    font-size: 19px;
    line-height: 21px;
  }
}

@media (max-width: 385px) {
  .skin_449 .hp-navigation__date p {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }
}

@media (max-width: 415px) {
  .skin_449 .hp-navigation__date p span {
    font-size: 11px;
  }
}
.skin_449
  .hp-navigation__inner
  > .row
  > [class*="col-"]:nth-child(2)
  > [class*="col-"] {
  padding: 0;
  margin-bottom: 10px;
}

@media (max-width: 991px) {
  .skin_449 .hp-navigation__inner > .row > [class*="col-"]:nth-child(2) {
    -webkit-box-ordinal-group: 2;
    order: 1;
    margin-bottom: 20px;
  }

  .skin_449
    .hp-navigation__inner
    > .row
    > [class*="col-"]:nth-child(2)
    > [class*="col-"] {
    margin-bottom: 5px;
  }

  .skin_449 .hp-navigation__inner > .row > [class*="col-"]:nth-child(3) {
    -webkit-box-ordinal-group: 5;
    order: 5;
    margin-bottom: 20px;
  }

  .skin_449 .hp-navigation__inner > .row > [class*="col-"]:nth-child(4) {
    -webkit-box-ordinal-group: 4;
    order: 3;
    margin-bottom: 20px;
  }

  .skin_449 .hp-navigation__inner > .row > [class*="col-"]:nth-child(5) {
    -webkit-box-ordinal-group: 6;
    order: 4;
    margin-bottom: 20px;
  }
}

.skin_449 #buy_now_header_desktop {
  color: var(--iq-white);
  border: 1px solid;
  background-color: var(--iq-pink);
  padding: 2px 10px;
  border-radius: 7px;
}

.skin_449 .number-picker {
  margin-bottom: 20px;
}

.skin_449 .number-picker.homepage {
  margin-bottom: 0;
}

.skin_449 .number-picker.homepage .number-picker--btn {
  margin-bottom: 13px;
}

@media (max-width: 575px) {
  .skin_449 .number-picker.homepage .number-picker__picker {
    margin: 0 15px 0 0;
    border-radius: 10px;
  }

  .skin_449.page-rtl .number-picker.homepage .number-picker__picker {
    margin: 0 0 0 15px;
  }

  .skin_449 .number-picker.homepage .number-picker--btn {
    padding-top: 2px;
  }

  .skin_449 .number-picker__numbers .betNum:nth-child(7n),
  .skin_449 .number-picker.homepage .number-picker__picker:last-child {
    margin-right: 0;
  }
}

.skin_449
  .number-picker__bottle--value
  input:not([type="checkbox"]):not([type="button"]):not([type="submit"]):not(
    [type="file"]
  ) {
  padding: 0;
  border: 0;
  width: 30px;
  text-align: center;
  height: unset;
  background-color: transparent;
  height: 100%;
  font-size: 19px;
  margin: 0 10px;
}

@media (max-width: 1199px) {
  .skin_449
    .number-picker__bottle--value
    input:not([type="checkbox"]):not([type="button"]):not([type="submit"]):not(
      [type="file"]
    ) {
    font-size: 17px;
  }
}

@media (max-width: 575px) {
  .skin_449 .number-picker__content {
    overflow-x: auto;
    justify-content: flex-start;
  }

  .skin_449 .number-picker.play .number-picker__content {
    flex-wrap: nowrap !important;
  }
}

.skin_449 .number-picker__picker--divider {
  width: 1px;
  background-color: #fff;
  margin: 3px 10px 0;
  height: 25px;
}

.skin_449 .number-picker__picker--favourite-numbers img,
.skin_449 .number-picker__picker--favourites img {
  width: 13px !important;
  height: 13px !important;
}

.skin_449 .number-picker__top--text h2 span span {
  font-size: 70px;
  position: absolute;
  top: -26px;
  right: -65px;
  color: #7ad1ff;
  position: unset;
  line-height: 43px;
  margin-left: 5px;
}

@media (max-width: 1199px) {
  .skin_449 .number-picker__top--text h2 span span {
    font-size: 40px;
    top: -14px;
    right: -37px;
  }
}

@media (max-width: 575px) {
  .skin_449 .number-picker__top--text h2 span span {
    font-size: 70px;
    position: unset;
    top: -40px;
    right: -65px;
    color: #94d4ff;
    line-height: 10px;
  }
}

@media (max-width: 415px) {
  .skin_449 .number-picker__top--text h2 span span {
    font-size: 60px;
    top: -35px;
    right: -55px;
  }
}

@media (max-width: 385px) {
  .skin_449 .number-picker__top--text h2 span span {
    font-size: 55px;
    top: -32px;
    right: -55px;
  }
}

.skin_449 .number-picker--btn {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}

.skin_449 .number-picker--btn button {
  text-transform: uppercase;
  font-size: 22px;
  width: 100%;
  max-width: 100%;
  height: 60px;
  background-color: var(--iq-yellow);
  border: none;
  border-radius: 15px;
  padding: 10px 15px;
  color: var(--iq-black);
  margin-top: 20px;
}

.skin_449 .number-picker--btn button:disabled {
  background-color: var(--iq-yellow);
  opacity: 0.5;
  color: var(--iq-black);
}

@media (max-width: 1199px) {
  .skin_449 .number-picker--btn button {
    font-size: 20px;
  }
}

@media (max-width: 576px) {
  .skin_449 .number-picker--btn button {
    width: 100%;
    height: 35px;
    font-size: 14px;
    margin-top: 12px;
    border-radius: 8px;
  }
}
.skin_449 .number-picker.play .number-picker__top {
  padding: 0 10px;
}

@media (max-width: 1199px) {
  .skin_449 .number-picker.play .number-picker__top {
    padding: 0px 10px;
  }
}

@media (max-width: 991px) {
  .skin_449 .number-picker.play .number-picker__top {
    margin-right: 0;
  }
}

@media (max-width: 479px) {
  .skin_449 .number-picker.play .number-picker__top {
    padding: 10px;
  }
}

.skin_449 .number-picker.play .number-picker__top--text {
  width: 100%;
  max-width: 100%;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

@media (max-width: 575px) {
  .skin_449 .number-picker.play .number-picker__top--text {
    flex-direction: row;
  }
}

@media (max-width: 479px) {
  .skin_449 .number-picker.play .number-picker__top--img {
    right: 12px;
  }
}

@media (max-width: 479px) {
  .skin_449 .number-picker.play .number-picker__bottle {
    position: unset;
    right: unset;
    bottom: unset;
  }
}

.skin_449 .number-picker.play .number-picker__picker {
  margin: 0 14px 14px 0;
  width: 298px;
}

@media (max-width: 575px) {
  .skin_449 .number-picker.play .number-picker__picker {
    width: 100%;
  }
}

@media (min-width: 1199px) {
  .skin_449 .number-picker.play .number-picker__picker:nth-child(4n) {
    margin-right: 0;
  }
}

.skin_449 .number-picker.play .number-picker__picker--name {
  width: 100%;
  padding: 15px 0 10px 10px;
}

.skin_449 .number-picker.play .number-picker__picker--name p,
.skin_449 .number-picker.play .number-picker__picker--name span {
  font-size: 26px;
  line-height: 20px;
}

.skin_449 .number-picker.play .number-picker__picker--bottom a,
.skin_449 .number-picker.play .number-picker__picker--bottom div,
.skin_449 .number-picker.play .number-picker__picker--name div {
  width: 32px;
  height: 32px;
}

.skin_449 .number-picker.play .number-picker__picker--divider {
  margin: 0 12px;
}

.skin_449 .number-picker.play .number-picker__content {
  flex-wrap: wrap;
}

@media (min-width: 480px) {
  .skin_449 .number-picker.play .number-picker__content {
    margin-bottom: 26px;
  }
}

.skin_449 .number-picker.play .number-picker__numbers {
  padding: 20px 10px 15px;
}

.skin_449 .number-picker__select .selectize .selectize-input {
  border-radius: 10px;
  border-color: var(--iq-darkgrey);
  padding: 10px 20px;
}

.skin_449 .number-picker__select .selectize .selectize-input:after {
  right: 40px !important;
}

.skin_449 .number-picker__buttons span {
  padding: 5px 20px;
  text-transform: uppercase;
  border-radius: 5px;
}

.skin_449 .number-picker__buttons span:first-child {
  background-color: var(--iq-orange);
  color: var(--iq-white);
}

@media (max-width: 991px) {
  .skin_449 .number-picker__buttons span:first-child {
    -webkit-box-flex: 0;
  }
}

@media (max-width: 767px) {
  .skin_449 .number-picker__buttons span:first-child {
    -webkit-box-flex: 0;
    flex: 0 0 49%;
    width: 49%;
  }
}
@media (max-width: 575px) {
  .skin_449 .number-picker__buttons span:first-child,
  .skin_449 .number-picker__buttons span:nth-child(2) {
    margin: 0 0 10px 0;
  }
}

.skin_449 .number-picker__buttons span:nth-child(2) {
  background-color: var(--iq-orange);
  color: var(--iq-white);
}

@media (max-width: 991px) {
  .skin_449 .number-picker__buttons span:nth-child(2) {
    -webkit-box-flex: 0;
  }
}

@media (max-width: 767px) {
  .skin_449 .number-picker__buttons span:nth-child(2) {
    -webkit-box-flex: 0;
    flex: 0 0 49%;
    width: 49%;
    margin-right: 0;
  }
}

.skin_449 .number-picker__buttons span:nth-child(2) img {
  width: 8px;
}

.skin_449 .number-picker__buttons span:nth-child(3) {
  background-color: #dedede;
  color: var(--iq-black);
}

@media (max-width: 991px) {
  .skin_449 .number-picker__buttons span:nth-child(3) {
    -webkit-box-flex: 0;
    flex: 0 0 31%;
    width: 31%;
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .skin_449 .number-picker__buttons span:nth-child(3) {
    -webkit-box-flex: 0;
    flex: 0 0 49%;
    width: 49%;
    margin-right: 8px;
    margin-bottom: 0;
  }
}

.skin_449 .number-picker__buttons button {
  padding: 4px 20px;
  text-transform: uppercase;
  border-radius: 5px;
  color: var(--iq-black);
}

.skin_449 .number-picker__buttons button.disabled {
  padding: 7px 20px;
}

.skin_449 .number-picker .blue-box {
  background-color: transparent;
  margin-bottom: 0;
  padding: 0;
  border-radius: 0;
}

@media (max-width: 479px) {
  .skin_449 .number-picker .blue-box {
    padding: 0;
    text-align: center;
    margin-bottom: 20px;
  }
}

.skin_449 .number-picker .blue-box p {
  color: var(--iq-brown);
  font-size: 14px;
}

@media (max-width: 479px) {
  .skin_449 .number-picker .blue-box p {
    font-size: 12px;
  }
}

.skin_449
  input:not([type="checkbox"]):not([type="button"]):not([type="submit"]):not(
    [type="file"]
  ):not([type="radio"]) {
  text-transform: inherit;
  color: var(--iq-black);
}

@media (max-width: 576px) {
  .col-lg-12 > .bg-white {
    padding: 8px 0 !important;
    border-radius: 10px 10px 0 0 !important;
  }
}

.skin_449 .countdown .base-timer {
  width: 80px;
  height: 80px;
  background-color: var(--iq-orange);
  border-radius: 50%;
  border: 1px solid white;
}

@media (max-width: 1199px) {
  .skin_449 .countdown .base-timer {
    width: 65px;
    height: 65px;
  }
}

@media (max-width: 575px) {
  .skin_449 .countdown .base-timer {
    width: 60px;
    height: 60px;
  }
}

.skin_449 .shopping-cart__content .cart__top--title:nth-child(2) {
  width: 60%;
}

.skin_449 .shopping-cart__content .cart__details {
  width: 10%;
}

.skin_449 .shopping-cart__content .cart__details--ticket-wrapper {
  width: 186px;
}

@media (max-width: 991px) {
  .skin_449 .shopping-cart__content .cart__details {
    width: 100%;
    max-width: 100%;
  }

  .skin_449 .shopping-cart__content .cart__details--ticket-wrapper {
    width: 100%;
    max-width: 100%;
    margin-bottom: 15px;
  }
}

.skin_449
  .shopping-cart__content
  .cart__details--ticket-wrapper
  .cart__details--ticket {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: start;
  align-items: flex-start;
}

@media (max-width: 991px) {
  .skin_449
    .shopping-cart__content
    .cart__details--ticket-wrapper
    .cart__details--ticket {
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
  }
}

@media (max-width: 991px) {
  .skin_449 .shopping-cart__content .cart__details--wrapper {
    width: 100%;
    max-width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
  }
}

@media (max-width: 991px) {
  .skin_449 .shopping-cart__content .cart__details--img {
    width: 100px;
    height: 100px;
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .skin_449 .shopping-cart__content .cart__details--img img {
    width: 40px;
  }
}

.skin_449 .shopping-cart__content .cart__numbers {
  width: 84%;
  display: -webkit-box;
  display: flex;
}

@media (max-width: 991px) {
  .skin_449 .shopping-cart__content .cart__numbers {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
  }
}

.skin_449 .shopping-cart__content .cart__numbers--number-row {
  padding-right: 0;
}

@media (max-width: 991px) {
  .skin_449 .shopping-cart__content .cart__numbers--row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
  }
}

@media (max-width: 991px) {
  .skin_449 .shopping-cart__content .cart__numbers--heading {
    -webkit-box-pack: center;
    justify-content: center;
  }
}

.skin_449 .shopping-cart__content .cart__edit {
  width: 16%;
}

@media (max-width: 991px) {
  .skin_449 .shopping-cart__content .cart__edit {
    width: 100%;
    max-width: 100%;
    display: none;
  }
}

.skin_449 .shopping-cart__content .cart__edit--empty {
  width: 15px;
  height: 15px;
  padding: 1px 6px;
}

.skin_449 .shopping-cart__content .cart__edit.mobile {
  display: none;
}

@media (max-width: 991px) {
  .skin_449 .shopping-cart__content .cart__edit.mobile {
    display: -webkit-box;
    display: flex;
  }
}

.skin_449 .shopping-cart__content .cart__selectboxes {
  margin-top: 0;
}

@media (max-width: 991px) {
  .skin_449 .shopping-cart__content .cart__selectboxes {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 575px) {
  .skin_449 .shopping-cart__content .cart__selectboxes--select:last-child {
    padding-bottom: 30px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--iq-dullgrey);
  }
}

.skin_449 .shopping-cart__content .cart__content--wrapper {
  width: 90%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 991px) {
  .skin_449 .shopping-cart__content .cart__content--wrapper {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 991px) {
  .skin_449
    .shopping-cart__content
    .cart__content--wrapper
    .cart__content--row:not(:last-child) {
    margin-bottom: 20px;
  }
}

@media (max-width: 991px) {
  .skin_449
    .shopping-cart__content
    .cart__content--wrapper
    .cart__content--row:first-child {
    padding-bottom: 0;
  }
}

.skin_449
  .shopping-cart__content
  .cart__content--wrapper
  .cart__content--row:first-child:after {
  content: "";
  display: block;
  width: 73%;
  height: 1px;
  background-color: var(--iq-dullgrey);
  position: absolute;
  bottom: 0;
}

@media (max-width: 991px) {
  .skin_449
    .shopping-cart__content
    .cart__content--wrapper
    .cart__content--row:first-child:after {
    display: none;
  }
}

.skin_449 .shopping-cart__content .cart__content--row {
  display: -webkit-box;
  display: flex;
  width: 100%;
  max-width: 100%;
  position: relative;
}

@media (max-width: 991px) {
  .skin_449 .shopping-cart__content .cart__content--row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

@media (max-width: 991px) {
  .skin_449
    .shopping-cart__content
    .cart__content--friday
    .cart__numbers--heading {
    display: none;
  }
}

.skin_449 .shopping-cart__content .cart__purchase {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 73%;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .skin_449 .shopping-cart__content .cart__purchase {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    padding: 0 18px;
    position: relative;
  }
}

@media (max-width: 991px) {
  .skin_449 .shopping-cart__content .cart__purchase--check {
    position: absolute;
    top: 0;
    right: 18px;
  }
}

.skin_449 .shopping-cart__content .cart__purchase--title {
  position: relative;
  display: -webkit-box;
  display: flex;
}

@media (max-width: 991px) {
  .skin_449 .shopping-cart__content .cart__purchase--title {
    width: 100%;
    -webkit-box-pack: start;
    justify-content: flex-start;
    height: 28px;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 15px;
  }
}

.skin_449 .shopping-cart__content .cart__purchase--title h3 {
  color: #797979;
  margin-bottom: 0;
}

.skin_449 .shopping-cart__content .cart__purchase--title span {
  color: #797979;
  margin-bottom: 0;
  width: 15px;
  height: 15px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border: 1px solid #797979;
  border-radius: 50%;
  font-size: 11px;
  padding-top: 4px;
  margin-left: 5px;
  cursor: pointer;
}

.skin_449
  .shopping-cart__content
  .cart__purchase--title
  span:hover
  + .cart__purchase--titlehover {
  display: block;
}

.skin_449 .shopping-cart__content .cart__purchase--titlehover {
  position: absolute;
  background-color: var(--iq-white);
  width: 310px;
  text-align: center;
  z-index: 2;
  border: 1px solid #797979;
  border-radius: 5px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  top: 22px;
  left: -144px;
  display: none;
}

.skin_449 .shopping-cart__content .cart__purchase--titlehover p {
  color: #797979;
  line-height: 20px;
  padding: 10px 0;
}

.skin_449 .modal--purchase-details .modal__purchase--bottom {
  padding-top: 15px;
  border-top: 1px solid var(--iq-dullgrey);
}

.skin_449 .modal--purchase-details .modal__purchase--bottom p {
  font-size: 17px;
}

@media (max-width: 479px) {
  .skin_449 .modal--purchase-details .modal__purchase--bottom p {
    font-size: 13px;
    max-width: 245px;
  }
}

.skin_449 .modal--purchase-details .modal__purchase--button {
  width: 100%;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 0 15px;
}

@media (max-width: 425px) {
  .skin_449 .modal--purchase-details .modal__purchase--button {
    padding: 0;
  }
}

.skin_449 .modal--purchase-details .modal__purchase--button a.btn,
.skin_449 .modal--purchase-details .modal__purchase--button button.btn {
  width: 130px;
  height: 30px;
}

.skin_449 .modal--numberpicker {
  border-width: 3px;
}

.skin_449 .modal--numberpicker .modal__selectboxes {
  display: -webkit-box;
  display: flex;
  margin-top: 10px;
}

@media (max-width: 575px) {
  .skin_449 .modal--numberpicker .modal__selectboxes {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.skin_449 .modal--numberpicker .modal__selectboxes--select {
  width: 190px;
}

@media (max-width: 575px) {
  .skin_449 .modal--numberpicker .modal__selectboxes--select {
    width: 100%;
    max-width: 100%;
  }
}

.skin_449
  .modal--numberpicker
  .modal__selectboxes--select.error
  .selectize
  .selectize-input {
  border-color: var(--iq-darkred);
}

.skin_449 .modal--numberpicker .modal__selectboxes--select .selectize-control {
  height: 30px;
}

.skin_449
  .modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-input {
  font-size: 12px !important;
  height: 30px;
  border: 1px solid var(--iq-pink);
  color: var(--iq-pink) !important;
}

.skin_449
  .modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-input.input-active {
  display: -webkit-box;
  display: flex;
}

.skin_449
  .modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-input
  input::-webkit-input-placeholder {
  color: #00a9e0 !important;
}

.skin_449
  .modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-input
  input::-moz-placeholder {
  color: #00a9e0 !important;
}

.skin_449
  .modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-input
  input:-ms-input-placeholder {
  color: #00a9e0 !important;
}

.skin_449
  .modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-input
  input::-ms-input-placeholder {
  color: #00a9e0 !important;
}

.skin_449
  .modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-input
  input::placeholder {
  color: #00a9e0 !important;
}

.skin_449
  .modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-input:after {
  line-height: 30px;
  color: var(--iq-pink);
  content: "\f0d7" !important;
  font-size: 22px;
}

.skin_449
  .modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-input.dropdown-active
  input {
  top: 0;
}

.skin_449
  .modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-input.dropdown-active:after {
  margin-top: -3px;
}

.skin_449
  .modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-input.disabled {
  border-color: var(--iq-darkgrey);
}

.skin_449
  .modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-input.disabled
  input::-webkit-input-placeholder {
  color: var(--iq-darkgrey) !important;
}

.skin_449
  .modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-input.disabled
  input::-moz-placeholder {
  color: var(--iq-darkgrey) !important;
}

.skin_449
  .modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-input.disabled
  input:-ms-input-placeholder {
  color: var(--iq-darkgrey) !important;
}

.skin_449
  .modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-input.disabled
  input::-ms-input-placeholder {
  color: var(--iq-darkgrey) !important;
}

.skin_449
  .modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-input.disabled
  input::placeholder {
  color: var(--iq-darkgrey) !important;
}

.skin_449
  .modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-input.disabled
  .item,
.skin_449
  .modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-input.disabled:after {
  color: var(--iq-darkgrey);
}

.skin_449
  .modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-dropdown {
  font-size: 12px !important;
  color: var(--iq-pink);
}

.skin_449
  .modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-control {
  height: 30px;
}

.skin_449 .modal--numberpicker .modal__selectboxes--select .selectize input,
.skin_449
  .modal--numberpicker
  .modal__selectboxes--select
  .selectize
  input::-webkit-input-placeholder {
  font-size: 12px !important;
}

.skin_449 .modal--numberpicker .modal__selectboxes--select:not(:last-child) {
  margin-right: 5px;
}

@media (max-width: 575px) {
  .skin_449 .modal--numberpicker .modal__selectboxes--select:not(:last-child) {
    margin-right: 0;
    margin-bottom: 5px;
  }
}

.skin_449 .modal--numberpicker-secondary .modal__selectboxes {
  margin-bottom: 10px;
}

.skin_449 .modal--numberpicker-secondary .modal__numberpicker--text {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}

.skin_449 .modal--numberpicker-secondary .modal__numberpicker--text p {
  color: #3f3f3f;
  margin-bottom: 20px;
  max-width: 465px;
}

@media (max-width: 479px) {
  .skin_449 .modal--numberpicker-secondary .modal__numberpicker--text p {
    max-width: 390px;
    font-size: 13px;
  }
}

.skin_449 .modal--numberpicker .number-picker__picker {
  width: 340px;
}

.skin_449 .modal--344 {
  border: 2px solid #2ba8dd;
  padding-top: 70px;
  border-radius: 15px;
}

.skin_449 .modal--344 .remodal-close {
  border-radius: 50%;
  background-color: #d2d5db;
  color: var(--iq-white);
  right: -22px;
  top: -22px;
  position: absolute;
}

@media (max-width: 575px) {
  .skin_449 .modal--344 .remodal-close {
    right: -10px;
    top: -18px;
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 575px) {
  .skin_449 .modal--344 .remodal-close i {
    font-size: 17px;
  }
}

.skin_449 .modal--344 .fa-times {
  color: var(--iq-white);
}

.skin_449 .modal--344 img {
  height: 130px;
  position: absolute;
  top: -66px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  left: 50%;
}

.skin_449 .modal--344 .modal__main {
  color: #0076f2;
}

.skin_449 .modal--344 h2 {
  margin-bottom: 5px;
  font-size: 25px;
  margin-top: 14px;
  color: #0076f2;
}

.skin_449 .modal--344 .win-number {
  border-radius: 10px;
  font-size: 35px;

  padding-top: 5px;
}

@media (max-width: 575px) {
  .skin_449 .modal--344 .win-number {
    font-size: 27px;
  }
}

.skin_449 .modal--344 .y-bg {
  background-color: #ffed68;
}

.skin_449 .modal--344 .b-bg {
  background-color: #94d4ff;
}

.skin_449 .modal--344 p {
  color: #0076f2;
  font-size: 17px;
  margin: 13px 0 20px;
}

@media (max-width: 575px) {
  .skin_449 .modal--344 p {
    font-size: 14px;
    margin: 13px 0;
  }
}

.skin_449 .modal--344 .modal__footer {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 25px;
}

.skin_449 .modal--344 .modal__footer .btn {
  font-size: 20px;
  text-decoration: none;
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 575px) {
  .skin_449 .modal--344 .modal__footer {
    position: unset;
    padding: 0;
    bottom: 20px;
  }

  .skin_449 .modal--344 .modal__footer p {
    margin: 0;
  }
}

.skin_449 .modal--344-secondary {
  padding-bottom: 20px;
}

.skin_449 .modal--344-secondary h2 {
  background-color: #0078f5;
  text-transform: uppercase;
  color: var(--iq-white);
  font-size: 32px;
  border-radius: 10px;
  padding: 11px 5px 5px;
  margin-bottom: 20px;
}

@media (max-width: 575px) {
  .skin_449 .modal--344-secondary h2 {
    font-size: 24px;
  }
}

@media (max-width: 375px) {
  .skin_449 .modal--344-secondary h2 {
    font-size: 18px;
  }
}

.skin_449 .modal--344-secondary p {
  margin-bottom: 8px;
}

.skin_449 .modal--344-secondary .modal__footer {
  position: unset;
  -webkit-box-pack: center;
  justify-content: center;
  margin-top: 23px;
}

.skin_449 .modal--344-secondary .btn {
  background-color: #7bd1ff;
  color: #0078f5;
  width: 150px;
}

.skin_449 .modal--344-rp {
  border: 2px solid #7bd1ff;
  padding: 130px 30px 20px;
  max-width: 365px;
  border-radius: 10px;
}

.skin_449 .modal--344-rp .remodal-close {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #d2d5db;
  color: var(--iq-white);
  right: -18px;
  top: -18px;
  position: absolute;
}

@media (max-width: 575px) {
  .skin_449 .modal--344-rp .remodal-close {
    right: -10px;
    top: -18px;
    width: 35px;
    height: 35px;
  }
}

.skin_449 .modal--344-rp .remodal-close i {
  font-size: 17px;
}

.skin_449 .modal--344-rp .modal__stamp {
  position: absolute;
  top: -40px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  left: 50%;
  width: 140px;
  height: 140px;
}

.skin_449 .modal--344-rp .modal__stamp svg {
  position: relative;
  z-index: 3;
  -webkit-transform: rotate(-155deg);
  transform: rotate(-155deg);
  overflow: unset;
}

.skin_449 .modal--344-rp .modal__stamp svg circle {
  stroke: #7bd1ff;
}

.skin_449 .modal--344-rp .modal__stamp img {
  height: 140px;
  max-width: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  left: 50%;
  padding: 15px;
  background-color: var(--iq-white);
  z-index: 1;
}

.skin_449 .modal--344-rp .modal__stamp--wrapper {
  position: relative;
}

.skin_449 .modal--344-rp .modal__stamp--border {
  width: 140px;
  height: 140px;
  border-color: #0078f5 transparent transparent #0078f5;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 2;
  position: relative;
}

.skin_449 .modal--344-rp .modal__main--inner {
  display: -webkit-box;
  display: flex;
  margin-bottom: 20px;
}

.skin_449 .modal--344-rp .modal__main--inner img {
  max-width: 100px;
}

@media (max-width: 370px) {
  .skin_449 .modal--344-rp .modal__main--inner img {
    max-width: 80px;
  }
}

.skin_449 .modal--344-rp .modal__main--text {
  max-width: 187px;
}

.skin_449 .modal--344-rp .modal__main--text p {
  color: #0078f5;
}

@media (max-width: 370px) {
  .skin_449 .modal--344-rp .modal__main--text p {
    font-size: 13px;
  }
}

.skin_449 .modal--344-rp .modal__footer a {
  text-decoration: underline;
  color: #0078f5;
  font-size: 19px;
}

@media (max-width: 370px) {
  .skin_449 .modal--344-rp .modal__footer a {
    font-size: 17px;
  }
}

.skin_449 .modal--344-rp h1 {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  color: #0078f5;
  font-size: 22px;
  margin-bottom: 20px;
}

@media (max-width: 370px) {
  .skin_449 .modal--344-rp h1 {
    font-size: 17px;
  }
}

.skin_449 .modal--344-rp h1 span {
  color: var(--iq-white);
  background-color: #0078f5;
  padding: 6px 10px 2px;
  border-radius: 5px;
}

.skin_449 .modal--344-winner {
  padding: 0;
  border-radius: 15px;
  border: 2px solid #7bd1ff;
  max-width: 480px;
}

.skin_449 .modal--344-winner .remodal-close {
  border-radius: 50%;
  background-color: #d2d5db;
  color: var(--iq-white);
  right: -22px;
  top: -22px;
  position: absolute;
}

@media (max-width: 575px) {
  .skin_449 .modal--344-winner .remodal-close {
    right: -10px;
    top: -18px;
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 575px) {
  .skin_449 .modal--344-winner .remodal-close i {
    font-size: 17px;
  }
}

.skin_449 .modal--344-winner .modal__stamp {
  position: absolute;
  top: -40px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  left: 50%;
  width: 140px;
  height: 140px;
}

.skin_449 .modal--344-winner .modal__stamp svg {
  position: relative;
  z-index: 3;
  -webkit-transform: rotate(-155deg);
  transform: rotate(-155deg);
  overflow: unset;
}

.skin_449 .modal--344-winner .modal__stamp svg circle {
  stroke: #7bd1ff;
}

.skin_449 .modal--344-winner .modal__stamp img {
  height: 140px;
  max-width: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  left: 50%;
  padding: 15px;
  background-color: #ffed63;
  z-index: 1;
}

.skin_449 .modal--344-winner .modal__stamp--wrapper {
  position: relative;
}

.skin_449 .modal--344-winner .modal__stamp--border {
  width: 140px;
  height: 140px;
  border-color: #0078f5 transparent transparent #0078f5;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 2;
  position: relative;
}

.skin_449 .modal--344-winner .modal__main {
  padding-top: 100px;
  background-color: #ffed63;
  border-radius: 15px 15px 0 0;
}

.skin_449 .modal--344-winner .modal__main--inner {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 25px 15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}

.skin_449 .modal--344-winner .modal__main--inner img {
  width: 129px;
  position: relative;
  z-index: 2;
  bottom: -10px;
}

@media (max-width: 479px) {
  .skin_449 .modal--344-winner .modal__main--inner img {
    width: 110px;
  }
}

@media (max-width: 355px) {
  .skin_449 .modal--344-winner .modal__main--inner img {
    width: 91px;
  }
}

.skin_449 .modal--344-winner .modal__main--inner h2 {
  margin-bottom: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  font-size: 53px;
  color: #0078f5;
  text-align: left;
  margin-left: 5px;
  z-index: 2;
  position: relative;
}

@media (max-width: 479px) {
  .skin_449 .modal--344-winner .modal__main--inner h2 {
    font-size: 40px;
  }
}

@media (max-width: 400px) {
  .skin_449 .modal--344-winner .modal__main--inner h2 {
    font-size: 30px;
  }
}

@media (max-width: 355px) {
  .skin_449 .modal--344-winner .modal__main--inner h2 {
    font-size: 28px;
  }
}

.skin_449 .modal--344-winner .modal__main--inner h2 span {
  font-size: 100px;
  line-height: 30px;
  margin-left: 15px;
  color: #7bd1ff;
}

@media (max-width: 479px) {
  .skin_449 .modal--344-winner .modal__main--inner h2 span {
    font-size: 81px;
    margin-left: 10px;
  }
}

@media (max-width: 400px) {
  .skin_449 .modal--344-winner .modal__main--inner h2 span {
    font-size: 60px;
  }
}

@media (max-width: 355px) {
  .skin_449 .modal--344-winner .modal__main--inner h2 span {
    font-size: 55px;
  }
}

.skin_449 .modal--344-winner .modal__main--textwrapper {
  position: relative;
}

.skin_449 .modal--344-winner .modal__main--textwrapper img:first-child {
  width: 60px;
  position: absolute;
  left: -20px;
  z-index: 1;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  bottom: -30px;
}

@media (max-width: 479px) {
  .skin_449 .modal--344-winner .modal__main--textwrapper img:first-child {
    width: 45px;
    left: -15px;
    bottom: -25px;
  }
}

@media (max-width: 355px) {
  .skin_449 .modal--344-winner .modal__main--textwrapper img:first-child {
    width: 40px;
    left: -10px;
    bottom: -20px;
  }
}

.skin_449 .modal--344-winner .modal__main--textwrapper img:last-child {
  width: 45px;
  position: absolute;
  right: 10px;
  top: -10px;
  z-index: 1;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

@media (max-width: 479px) {
  .skin_449 .modal--344-winner .modal__main--textwrapper img:last-child {
    width: 35px;
    top: -12px;
  }
}

.skin_449 .modal--344-winner .modal__footer {
  padding: 40px 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.skin_449 .modal--344-winner .modal__footer p {
  color: #0078f5;
  font-size: 25px;
}

.skin_449 .modal--344-winner .modal__footer p:nth-child(2) {
  max-width: 300px;
  margin-bottom: 20px;
}

@media (max-width: 400px) {
  .skin_449 .modal--344-winner .modal__footer p {
    font-size: 20px;
  }
}

.skin_449 .modal--344-winner .modal__footer a {
  color: #0078f5;
  text-decoration: underline;
  font-size: 25px;
}

@media (max-width: 400px) {
  .skin_449 .modal--344-winner .modal__footer a {
    font-size: 20px;
  }
}

.skin_449 .my-games__item,
.skin_449 .ticket-history__item {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

@media (max-width: 1199px) {
  .skin_449 .my-games__item > div:first-child,
  .skin_449 .ticket-history__item > div:first-child {
    border-bottom: 1px solid var(--iq-dullgrey);
  }
}

.skin_449 .my-games__item--category,
.skin_449 .ticket-history__item--category {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 25px 0;
}

@media (max-width: 1199px) {
  .skin_449 .my-games__item--category,
  .skin_449 .ticket-history__item--category {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.skin_449 .my-games__details--ticket-wrapper h4,
.skin_449 .ticket-history__details--ticket-wrapper h4 {
  margin-bottom: 0;
  color: var(--iq-black);
}

.skin_449 .my-games__details--ticket.ticket-id,
.skin_449 .ticket-history__details--ticket.ticket-id {
  margin: 0;
}

@media (min-width: 992px) {
  .skin_449 .my-games__numbers,
  .skin_449 .ticket-history__numbers {
    min-width: 224px;
  }
}

.skin_449 .my-games__numbers--row,
.skin_449 .ticket-history__numbers--row {
  -webkit-box-pack: center;
  justify-content: center;
}

@media (max-width: 575px) {
  .skin_449 .my-games {
    padding: 0;
    border: none;
  }
}

@media (max-width: 575px) {
  .skin_449 .my-games__numbers {
    margin-bottom: 0;
  }
}

@media (max-width: 575px) {
  .skin_449 .my-games__content--item-wrapper {
    padding: 20px 12px;
    border: 1px solid var(--iq-dullgrey);
    border-radius: 10px;
  }
}

@media (max-width: 575px) {
  .skin_449 .my-games__content--item-wrapper:not(:last-child) {
    margin-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .skin_449 .my-games__item:first-child {
    border: none;
  }
}

@media (max-width: 575px) {
  .skin_449 .my-games__item {
    padding-top: 0;
  }
}

.skin_449 .my-games__item--category {
  position: relative;
}

@media (max-width: 575px) {
  .skin_449 .my-games__item--category:first-child {
    padding-top: 0;
  }
}

@media (max-width: 575px) {
  .skin_449 .my-games__item--friday {
    padding-bottom: 0;
  }
}

@media (max-width: 575px) {
  .skin_449 .ticket-history {
    padding: 0;
    border: none;
  }
}

@media (max-width: 575px) {
  .skin_449 .ticket-history__content--item-wrapper {
    padding: 20px 12px;
    border: 1px solid var(--iq-dullgrey);
    border-radius: 10px;
  }
}

@media (max-width: 575px) {
  .skin_449 .ticket-history__content--item-wrapper:not(:last-child) {
    margin-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .skin_449 .ticket-history__item:first-child {
    border: none;
  }
}

@media (max-width: 575px) {
  .skin_449 .ticket-history__item {
    padding-top: 0;
  }
}

.skin_449 .ticket-history__item--category {
  position: relative;
}

@media (max-width: 575px) {
  .skin_449 .ticket-history__item--category:first-child {
    padding-top: 0;
  }
}

.skin_449 .ticket-history__item--btn {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  bottom: 10%;
  left: 26.5%;
}

@media (max-width: 1199px) {
  .skin_449 .ticket-history__item--btn {
    bottom: -30px;
    width: 100%;
    max-width: 100%;
    left: 50%;
  }
}

.skin_449 .ticket-history__item--btn a {
  width: 224px;
  max-width: 100%;
  height: 40px;
  padding: 10px 20px 8px;
  font-size: 17px;
  color: var(--iq-white);
}

@media (max-width: 1199px) {
  .skin_449 .ticket-history__item--btn a {
    width: 100%;
    max-width: 100%;
  }
}

.skin_449 .modal--numberpicker .betNum,
.skin_449 .modal__purchase .betNum,
.skin_449 .myaccount .betNum,
.skin_449 .previous-drawn .betNum,
.skin_449 .shopping-cart .betNum,
.skin_449 .ticket-history .betNum {
  width: 32px;
  height: 32px;
  line-height: 32px;
  background-color: transparent;
  border: 1px solid #55a0fa;
  background-image: none;
  border-radius: 50%;
  color: #55a0fa;
  padding-top: 0 !important;
}

.skin_449 .modal--numberpicker .betNum:hover,
.skin_449 .modal__purchase .betNum:hover,
.skin_449 .myaccount .betNum:hover,
.skin_449 .previous-drawn .betNum:hover,
.skin_449 .shopping-cart .betNum:hover,
.skin_449 .ticket-history .betNum:hover {
  border-color: var(--iq-pink);
}

.skin_449 .modal--numberpicker .betNumMarked,
.skin_449 .modal__purchase .betNumMarked,
.skin_449 .myaccount .betNumMarked,
.skin_449 .previous-drawn .betNumMarked,
.skin_449 .shopping-cart .betNumMarked,
.skin_449 .ticket-history .betNumMarked {
  background-image: none;
  background-color: var(--iq-pink);
  color: var(--iq-white);
  border-radius: 50%;
  border: 1px solid var(--iq-pink);
}

@media (max-width: 575px) {
  .skin_449 .modal--numberpicker .betNumMarked,
  .skin_449 .modal__purchase .betNumMarked,
  .skin_449 .myaccount .betNumMarked,
  .skin_449 .previous-drawn .betNumMarked,
  .skin_449 .shopping-cart .betNumMarked,
  .skin_449 .ticket-history .betNumMarked {
    border-radius: 50% !important;
  }
}

.skin_449 .previous-drawn .table-main table tbody tr:nth-child(2n) {
  background-color: transparent;
}

@media (max-width: 575px) {
  .skin_449 .previous-drawn .table-main table tbody tr td {
    font-size: 13px;
  }
}

.skin_449 .previous-drawn .table-main table tbody tr td,
.skin_449 .previous-drawn .table-main table tbody tr td:first-child,
.skin_449 .previous-drawn .table-main table tbody tr td:last-child {
  color: var(--iq-black);
  padding: 11px 15px 12px 21px;
}

.skin_449 .previous-drawn .table-main table tbody tr td span {
  color: var(--iq-black);
}

.skin_449 .header__wrapper--nav.desktop nav ul li .accordion-top i {
  margin-bottom: 0;
}

.skin_449 .previous-drawn__button {
  width: unset;
}

.skin_449 .previous-drawn__button a {
  background-color: var(--iq-pink);
  color: var(--iq-white);
  text-transform: uppercase;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  font-size: 21px;
  padding: 0 40px;
  height: 47px;
}

.skin_449 .previous-drawn__button a i {
  display: none;
}

@media (max-width: 575px) {
  .skin_449 .previous-drawn__button.mobile {
    display: -webkit-box;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 0 auto;
  }
}

@media (max-width: 575px) {
  .skin_449 .previous-drawn__select {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: start;
    align-items: flex-start;
    width: 100%;
  }
}

.skin_449 .previous-drawn .table-main table thead tr {
  border: 2px solid var(--iq-orange);
}

.skin_449 .previous-drawn .table-main table thead tr th {
  color: var(--iq-white);
  font-size: 17px;
  text-transform: uppercase;
  padding: 10px 0 10px 19px;
  border-right: 2px solid var(--iq-orange);
  background-color: var(--iq-orange);
  text-align: center;
}

@media (max-width: 575px) {
  .skin_449 .previous-drawn .table-main table thead tr th {
    border: 2px solid var(--iq-orange);
    padding: 10px 0 10px 15px;
  }
}

@media (max-width: 479px) {
  .skin_449 .previous-drawn .table-main table thead tr th {
    border: 2px solid var(--iq-orange);
    padding: 10px 17px 10px 15px;
  }
}

@media (max-width: 575px) {
  .skin_449 .previous-drawn .table-main table thead tr th:nth-child(2) {
    text-align: left;
  }
}

@media (max-width: 479px) {
  .skin_449 .previous-drawn .table-main table thead tr th:nth-child(2) {
    line-height: 20px;
  }
}

.skin_449 .previous-drawn .table-main table tbody tr td:nth-child(2) {
  padding: 11px 15px 12px 21px;
}

@media (max-width: 575px) {
  .skin_449 .previous-drawn .table-main table tbody tr td:nth-child(2) {
    padding: 15px 15px 12px 16px;
  }
}

@media (max-width: 575px) {
  .skin_449 .previous-drawn__table .prize-type {
    display: none;
  }
}

.skin_449 .previous-drawn__top {
  margin-bottom: 10px;
}

.skin_449 .previous-drawn__select .selectize-input {
  border-width: 2px;
  border-color: var(--iq-orange);
}

.skin_449 .previous-drawn .betNum {
  width: 55px;
  height: 55px;
  line-height: 55px;
  font-size: 20px;
}

@media (max-width: 479px) {
  .skin_449 .previous-drawn .betNum {
    border-radius: 5px;
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
}

@media (max-width: 390px) {
  .skin_449 .previous-drawn .betNum {
    width: 33px;
    height: 33px;
    border-radius: 5px;
    font-size: 17px;
  }
}

.skin_449 .previous-drawn__text h1,
.skin_449 .previous-drawn__text h2 {
  text-transform: uppercase;
}

.skin_449 .previous-drawn__text h2.heading_sec {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.skin_449 .previous-drawn__text h1 {
  margin-bottom: 5px;
  font-size: 20px;
}

.skin_449 .previous-drawn__text--number-row div:not(:last-child) {
  margin-right: 32px;
}

.skin_449
  .previous-drawn__section--friday
  .table-main
  table
  thead
  tr
  th:not(:last-child) {
  border-right: 1px solid var(--iq-white);
}

@media (max-width: 479px) {
  .skin_449
    .previous-drawn__section--friday
    .table-main
    table
    thead
    tr
    th:not(:last-child) {
    border-right: 0;
  }
}

.skin_449
  .previous-drawn__section--friday
  .table-main
  table
  tbody
  tr:nth-child(2n) {
  background-color: rgba(0, 119, 200, 0.1);
}

.skin_449 .verification__banner h2 {
  font-size: 40px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 50%;
  bottom: unset;
  margin-bottom: 0;
  color: #ffe765;
}

@media (max-width: 575px) {
  .skin_449 .verification__banner h2 {
    position: unset;
    -webkit-transform: none;
    transform: none;
    padding: 15px;
    background-color: #28a8ff;
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 479px) {
  .skin_449 .verification__banner img {
    max-height: 500px;
    width: 100%;
  }
}

.skin_449 .verification__banner--bg-rejected {
  background-image: url(../img/kyc-rejected.jpg);
}

@media (max-width: 575px) {
  .skin_449 .verification__banner--bg-rejected {
    background-image: unset;
    padding-bottom: 0;
    border-radius: 0;
  }
}

@media (max-width: 479px) {
  .skin_449 .verification__banner--bg-rejected {
    padding-bottom: 0;
  }
}

.skin_449 .verification__banner--bg-success {
  background-image: url(../img/kyc-verified.jpg);
}

@media (max-width: 575px) {
  .skin_449 .verification__banner--bg-success {
    background-image: unset;
    padding-bottom: 0;
    border-radius: 0;
  }
}

@media (max-width: 479px) {
  .skin_449 .verification__banner--bg-success {
    padding-bottom: 0;
  }
}

@media (max-width: 575px) {
  .skin_449 .verification__banner--text {
    background-color: #28a8ff;
    position: unset;
    width: 100%;
    max-width: 100%;
    padding: 25px;
  }
}

.skin_449 .verification__banner--text h3 {
  color: var(--iq-white);
  font-size: 40px;
}

@media (max-width: 479px) {
  .skin_449 .verification__banner--text h3 {
    font-size: 27px;
  }
}

@media (max-width: 415px) {
  .skin_449 .verification__banner--text h3 {
    font-size: 21px;
  }
}

.skin_449 .verification__banner--text p {
  color: var(--iq-white);
  line-height: 22px;
}

.skin_449 .verification__banner--text a {
  height: 40px;
  border-radius: 10px;
}

@media (max-width: 479px) {
  .skin_449 .verification__banner--text a {
    width: unset;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
}

.skin_449 .verification__banner--icon {
  margin-bottom: 20px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
}

@media (min-width: 576px) {
  .skin_449 .verification__banner--icon {
    display: none;
  }
}

.skin_449 .verification__banner--icon img {
  width: 200px;
  max-width: 100%;
}

@media (max-width: 575px) {
  .skin_449
    .verification--success
    .verification__banner
    .verification__banner--text {
    background-color: #ffe765;
  }
}

.skin_449
  .verification--success
  .verification__banner
  .verification__banner--text
  h3,
.skin_449
  .verification--success
  .verification__banner
  .verification__banner--text
  p {
  color: #28a8ff;
}

@media (max-width: 479px) {
  .skin_449
    .verification--rejected
    .verification__banner
    .verification__banner--text
    a {
    width: unset;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
}

.skin_449.page-rtl .price-wrapp {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
}

.skin_449.page-rtl .footer__nav--text.desktop ul li a,
.skin_449.page-rtl .footer__socials p {
  font-size: 16px;
}

@media (max-width: 575px) {
  .skin_449.page-rtl .priceproduct1 {
    max-width: 90px !important;
  }
}

.skin_449.page-rtl .verification__banner--bg-rejected {
  background-image: url(../img/kyc-rejected-ar.jpg);
}

@media (max-width: 575px) {
  .skin_449.page-rtl .verification__banner--bg-rejected {
    background-image: unset;
  }
}

.skin_449.page-rtl .verification__banner--bg-success {
  background-image: url(../img/kyc-verified-ar.jpg);
}

@media (max-width: 575px) {
  .skin_449.page-rtl .verification__banner--bg-success {
    background-image: unset;
  }
}

.skin_449.page-rtl .modal--344 .remodal-close {
  right: unset;
  left: -22px;
}

@media (max-width: 575px) {
  .skin_449.page-rtl .modal--344 .remodal-close {
    left: -10px;
  }
}

.skin_449.page-rtl .modal--344-rp .remodal-close {
  right: unset;
  left: -18px;
}

@media (max-width: 575px) {
  .skin_449.page-rtl .modal--344-rp .remodal-close {
    left: -10px;
  }
}

.skin_449.page-rtl .modal--344-rp h1 span {
  margin-top: 10px;
  padding: 7px 10px;
}

.skin_449.page-rtl .modal--344-rp .modal__main--text {
  max-width: 100%;
}

.skin_449.page-rtl .modal--purchase .modal__purchase--numbers {
  margin-right: 0;
  margin-left: 10px;
}

.skin_449.page-rtl .modal--purchase .modal__purchase--text {
  text-align: right;
}

.skin_449.page-rtl .modal--purchase .betNum {
  margin: 0 0 7px 7px;
}

.skin_449.page-rtl .modal--344-winner .modal__main--inner {
  -webkit-box-align: start;
  align-items: flex-start;
}

.skin_449.page-rtl .modal--344-winner .modal__main--inner img {
  bottom: unset;
}

@media (max-width: 479px) {
  .skin_449.page-rtl .modal--344-winner .modal__main--inner img {
    bottom: 10px;
  }
}

.skin_449.page-rtl
  .modal--344-winner
  .modal__main--textwrapper
  img:first-child {
  -webkit-transform: unset;
  transform: unset;
  left: unset;
  right: -20px;
  bottom: -70px;
}

@media (max-width: 479px) {
  .skin_449.page-rtl
    .modal--344-winner
    .modal__main--textwrapper
    img:first-child {
    bottom: -45px;
  }
}

@media (max-width: 400px) {
  .skin_449.page-rtl
    .modal--344-winner
    .modal__main--textwrapper
    img:first-child {
    bottom: -50px;
  }
}

.skin_449.page-rtl .modal--344-winner .modal__main--textwrapper img:last-child {
  right: unset;
  left: 0;
  -webkit-transform: unset;
  transform: unset;
  top: 0;
}

.skin_449.page-rtl .modal--344-winner .modal__main--textwrapper h2 {
  text-align: right;
  font-size: 53px;
  margin-left: 0;
}

@media (max-width: 479px) {
  .skin_449.page-rtl .modal--344-winner .modal__main--textwrapper h2 {
    font-size: 45px;
  }
}

@media (max-width: 355px) {
  .skin_449.page-rtl .modal--344-winner .modal__main--textwrapper h2 {
    font-size: 35px;
  }
}

.skin_449.page-rtl .modal--344-winner .modal__main--textwrapper h2 span {
  font-size: 75px;
  margin-left: 0;
}

@media (max-width: 479px) {
  .skin_449.page-rtl .modal--344-winner .modal__main--textwrapper h2 span {
    font-size: 55px;
  }
}

@media (max-width: 400px) {
  .skin_449.page-rtl .modal--344-winner .modal__main--textwrapper h2 span {
    line-height: 10px;
    font-size: 50px;
  }
}

@media (max-width: 355px) {
  .skin_449.page-rtl .modal--344-winner .modal__main--textwrapper h2 span {
    font-size: 42px;
  }
}

@media (max-width: 355px) {
  .skin_449.page-rtl .modal--344-winner .modal__footer a,
  .skin_449.page-rtl .modal--344-winner .modal__footer p {
    font-size: 17px;
  }
}

.skin_449.page-rtl .hp-navigation__box,
.skin_449.page-rtl .hp-navigation__box--content {
  -webkit-transform: unset;
  transform: unset;
}

.skin_449.page-rtl .hp-navigation__box--content {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: unset;
  align-items: unset;
}
.skin_449.page-rtl .hp-navigation__box.box-results h3 {
  margin-bottom: 0;
  padding: 5px 0;
}

.skin_449.page-rtl .hp-navigation__box.box-total {
  padding: 9px 16px;
}

@media (max-width: 767px) {
  .skin_449.page-rtl
    .hp-navigation__box.box-total
    .hp-navigation__box--content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.skin_449.page-rtl
  .hp-navigation__box.box-total
  .hp-navigation__box--content
  h2 {
  font-size: 20px;
  -webkit-box-align: start;
  align-items: flex-start;
}

@media (max-width: 1199px) {
  .skin_449.page-rtl
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2 {
    font-size: 16px;
  }
}

@media (max-width: 575px) {
  .skin_449.page-rtl
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2 {
    text-align: right;
  }
}

.skin_449.page-rtl
  .hp-navigation__box.box-total
  .hp-navigation__box--content
  h2
  span {
  font-size: 50px;
  line-height: 8px;
}

@media (max-width: 1199px) {
  .skin_449.page-rtl
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    span {
    font-size: 50px;
  }
}

@media (max-width: 575px) {
  .skin_449.page-rtl
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    span {
    font-size: 26px;
    line-height: 33px;
  }
}

.skin_449.page-rtl
  .hp-navigation__box.box-total
  .hp-navigation__box--content
  h2
  small {
  font-size: 17px;
}

@media (max-width: 1199px) {
  .skin_449.page-rtl
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    small {
    font-size: 18px;
  }
}

@media (max-width: 575px) {
  .skin_449.page-rtl
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h3 {
    font-size: 50px;
    width: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: left;
  }
}

@media (max-width: 1199px) {
  .skin_449.page-rtl .hp-navigation__box--sum {
    width: 334px;
  }
}

@media (max-width: 479px) {
  .skin_449.page-rtl .hp-navigation__box--sum {
    width: 160px;
    max-width: 100%;
    border-radius: 5px;
    padding: 10px 5px;
  }
}

@media (max-width: 415px) {
  .skin_449.page-rtl .hp-navigation__box--sum {
    width: 145px;
  }
}

@media (max-width: 385px) {
  .skin_449.page-rtl .hp-navigation__box--sum {
    width: 115px;
    padding: 5px;
  }
}

.skin_449.page-rtl .hp-navigation__box--sum p {
  font-size: 31px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
}

@media (max-width: 1199px) {
  .skin_449.page-rtl .hp-navigation__box--sum p {
    font-size: 36px;
    line-height: 31px;
    height: 37px;
  }
}

@media (max-width: 479px) {
  .skin_449.page-rtl .hp-navigation__box--sum p {
    font-size: 18px;
    height: 22px;
    line-height: normal;
  }
}

@media (max-width: 415px) {
  .skin_449.page-rtl .hp-navigation__box--sum p {
    font-size: 17px;
    height: 20px;
  }
}

@media (max-width: 385px) {
  .skin_449.page-rtl .hp-navigation__box--sum p {
    font-size: 13px;
    height: 17px;
  }
}

.skin_449.page-rtl .hp-navigation__box--sum p span {
  margin-right: 5px;
}

@media (max-width: 479px) {
  .skin_449.page-rtl .hp-navigation__date p {
    text-align: right;
  }
}

@media (max-width: 479px) {
  .skin_449.page-rtl .hp-navigation__draws > div {
    margin-right: 0;
    margin-left: 10px;
  }

  .skin_449.page-rtl .hp-navigation__draws--number span {
    padding-top: 0;
  }
}

.skin_449.page-rtl .previous-drawn__text--number-row div:not(:last-child) {
  margin-right: 0;
  margin-left: 32px;
}

.skin_449.page-rtl .hp-navigation__box--heading h2 {
  max-width: 339px;
}

@media (min-width: 480px) {
  .skin_449.page-ml .verification--rejected .verification__banner--text a {
    max-width: 355px;
  }
}

.skin_449.page-ml
  .modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-input {
  font-size: 9px !important;
}

@media (max-width: 479px) {
  .skin_449.page-ml .modal--344 .modal__footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse;
    -webkit-box-align: end;
    align-items: flex-end;
  }
}

@media (max-width: 479px) {
  .skin_449.page-ml .modal--344 .modal__footer p {
    width: 100%;
    text-align: left;
    max-width: 100%;
    margin: 13px 0 0;
  }
}

.skin_449.page-ml .modal--344-rp h1 {
  font-size: 17px;
}

@media (max-width: 370px) {
  .skin_449.page-ml .modal--344-rp h1 {
    font-size: 14px;
  }
}

.skin_449.page-ml .modal--344-rp h1 span {
  margin-top: 10px;
}

.skin_449.page-ml .modal--344-rp .modal__main--text p {
  font-size: 13px;
}

@media (max-width: 370px) {
  .skin_449.page-ml .modal--344-rp .modal__main--text p {
    font-size: 11px;
  }
}

.skin_449.page-ml .modal--344-rp .modal__footer a {
  font-size: 13px;
}

@media (max-width: 370px) {
  .skin_449.page-ml .modal--344-rp .modal__footer a {
    font-size: 11px;
  }
}

.skin_449.page-ml .modal--344-winner .modal__main--inner h2 {
  font-size: 45px;
}

@media (max-width: 479px) {
  .skin_449.page-ml .modal--344-winner .modal__main--inner h2 {
    font-size: 30px;
  }
}

@media (max-width: 400px) {
  .skin_449.page-ml .modal--344-winner .modal__main--inner h2 {
    font-size: 25px;
  }
}

@media (max-width: 355px) {
  .skin_449.page-ml .modal--344-winner .modal__main--inner h2 {
    font-size: 22px;
  }
}

.skin_449.page-ml .modal--344-winner .modal__main--inner h2 span {
  margin-left: 0;
  font-size: 38px;
}

@media (max-width: 479px) {
  .skin_449.page-ml .modal--344-winner .modal__main--inner h2 span {
    font-size: 30px;
  }
}

@media (max-width: 400px) {
  .skin_449.page-ml .modal--344-winner .modal__main--inner h2 span {
    font-size: 25px;
  }
}

@media (max-width: 355px) {
  .skin_449.page-ml .modal--344-winner .modal__main--inner h2 span {
    font-size: 22px;
  }
}

.skin_449.page-ml .modal--344-winner .modal__main--textwrapper img:first-child {
  bottom: -35px;
}

@media (max-width: 479px) {
  .skin_449.page-ml
    .modal--344-winner
    .modal__main--textwrapper
    img:first-child {
    bottom: -25px;
  }
}

.skin_449.page-ml .modal--344-winner .modal__footer a,
.skin_449.page-ml .modal--344-winner .modal__footer p {
  font-size: 20px;
}

.skin_449.page-ml .number-picker__picker--disabled span {
  margin-right: 5px;
}

.skin_449.page-ml .number-picker__picker--disabled span span {
  margin-right: 3px;
}

.skin_449.page-ml .number-picker.play .number-picker__picker--name p,
.skin_449.page-ml .number-picker.play .number-picker__picker--name span {
  font-size: 20px;
}

@media (min-width: 576px) {
  .skin_449.page-ml .number-picker--btn button {
    font-size: 14px;
  }
}

@media (min-width: 576px) {
  .skin_449.page-ml .hp-navigation__box.box-total {
    padding: 36px 16px;
  }
}

.skin_449.page-ml
  .hp-navigation__box.box-total
  .hp-navigation__box--content
  h2 {
  font-size: 20px;
}

@media (max-width: 355px) {
  .skin_449.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2 {
    font-size: 28px;
  }
}

.skin_449.page-ml
  .hp-navigation__box.box-total
  .hp-navigation__box--content
  h2
  span {
  font-size: 21px;
  line-height: 30px;
  margin-bottom: 0;
}

@media (max-width: 355px) {
  .skin_449.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    span {
    font-size: 40px;
  }
}

@media (min-width: 576px) {
  .skin_449.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    small {
    font-size: 19px;
  }
}

@media (min-width: 480px) {
  .skin_449.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h3 {
    font-size: 60px;
  }
}
@media (max-width: 375px) {
  .skin_449.page-ml .header__button a {
    font-size: 10px;
  }
}

.skin_449.windows .hp-navigation__draws--number span {
  top: -1px;
}

.skin_449.linux.page-rtl .betNum span,
.skin_449.ubuntu.page-rtl .betNum span,
.skin_449.windows.page-rtl .betNum span {
  position: relative;
  top: 0;
}

@media (min-width: 992px) {
  .skin_449.linux.page-rtl
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    span,
  .skin_449.ubuntu.page-rtl
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    span,
  .skin_449.windows.page-rtl
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    span {
    font-size: 70px;
    line-height: 50px;
    margin-bottom: 2px;
  }
}
.skin_449.linux.page-rtl .hp-navigation__draws--number span,
.skin_449.ubuntu.page-rtl .hp-navigation__draws--number span,
.skin_449.windows.page-rtl .hp-navigation__draws--number span {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (min-width: 480px) {
  .skin_449.page-ur .verification--rejected .verification__banner--text a {
    max-width: 250px;
  }
}

.skin_449.page-ur .modal--344-winner .modal__main--textwrapper h2 {
  font-size: 40px;
}

.skin_449.page-ur .modal--344-winner .modal__main--textwrapper h2 span {
  line-height: 90px;
  font-size: 45px;
}

.skin_449.page-ur .modal--344-winner .modal__main--textwrapper img:first-child {
  right: -30px;
  bottom: -25px;
}

.skin_449.page-ur .number-picker__picker--disabled span span {
  margin-right: 3px;
  margin-left: 0;
}

@media (min-width: 576px) {
  .skin_449.page-ur .hp-navigation__box.box-total {
    padding: 8px 38px;
  }
}

@media (min-width: 992px) {
  .skin_449.page-ur
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    span {
    margin-bottom: 10px;
    line-height: 66px;
    font-size: 40px;
  }
}

@media (max-width: 991px) {
  .skin_449.page-ur
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    span {
    line-height: 60px;
    font-size: 35px;
    margin-bottom: 7px;
  }
}

@media (min-width: 992px) {
  .skin_449.linux .number-picker__top--text h2 span span,
  .skin_449.ubuntu .number-picker__top--text h2 span span,
  .skin_449.windows .number-picker__top--text h2 span span {
    top: -18px;
  }
}

.skin_449.linux .hp-navigation__box--btn a,
.skin_449.ubuntu .hp-navigation__box--btn a,
.skin_449.windows .hp-navigation__box--btn a {
  padding: 5px 22px 10px;
}

.skin_449.linux .skin_449 .number-picker .betNum,
.skin_449.ubuntu .skin_449 .number-picker .betNum,
.skin_449.windows .skin_449 .number-picker .betNum {
  line-height: 33px;
}
.skin_449.linux.page-rtl
  .modal--344-winner
  .modal__main--textwrapper
  img:first-child,
.skin_449.ubuntu.page-rtl
  .modal--344-winner
  .modal__main--textwrapper
  img:first-child,
.skin_449.windows.page-rtl
  .modal--344-winner
  .modal__main--textwrapper
  img:first-child {
  bottom: -30px;
}

@media (max-width: 479px) {
  .skin_449.linux.page-rtl
    .modal--344-winner
    .modal__main--textwrapper
    img:first-child,
  .skin_449.ubuntu.page-rtl
    .modal--344-winner
    .modal__main--textwrapper
    img:first-child,
  .skin_449.windows.page-rtl
    .modal--344-winner
    .modal__main--textwrapper
    img:first-child {
    bottom: -50px;
  }
}

@media (min-width: 480px) {
  .skin_449.linux.page-rtl .modal--344-winner .modal__main--textwrapper h2 span,
  .skin_449.ubuntu.page-rtl
    .modal--344-winner
    .modal__main--textwrapper
    h2
    span,
  .skin_449.windows.page-rtl
    .modal--344-winner
    .modal__main--textwrapper
    h2
    span {
    line-height: 60px;
  }
}

@media (min-width: 992px) {
  .skin_449.linux.page-ur
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    span,
  .skin_449.ubuntu.page-ur
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    span,
  .skin_449.windows.page-ur
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    span {
    margin-bottom: 10px;
    line-height: 65px;
    font-size: 35px;
  }
}

.skin_449.linux.page-ur
  .hp-navigation__box.box-stories
  .hp-navigation__box--heading
  h2
  span,
.skin_449.ubuntu.page-ur
  .hp-navigation__box.box-stories
  .hp-navigation__box--heading
  h2
  span,
.skin_449.windows.page-ur
  .hp-navigation__box.box-stories
  .hp-navigation__box--heading
  h2
  span {
  bottom: 8px;
}

.skin_449.linux.page-ur .modal--344-winner .modal__main--textwrapper h2 span,
.skin_449.ubuntu.page-ur .modal--344-winner .modal__main--textwrapper h2 span,
.skin_449.windows.page-ur .modal--344-winner .modal__main--textwrapper h2 span {
  line-height: 90px;
  font-size: 45px;
}

.skin_449.linux.page-ur
  .modal--344-winner
  .modal__main--textwrapper
  img:first-child,
.skin_449.ubuntu.page-ur
  .modal--344-winner
  .modal__main--textwrapper
  img:first-child,
.skin_449.windows.page-ur
  .modal--344-winner
  .modal__main--textwrapper
  img:first-child {
  right: -30px;
  bottom: -25px;
}

@media (max-width: 479px) {
  .skin_449.linux.page-ur
    .modal--344-winner
    .modal__main--textwrapper
    img:first-child,
  .skin_449.ubuntu.page-ur
    .modal--344-winner
    .modal__main--textwrapper
    img:first-child,
  .skin_449.windows.page-ur
    .modal--344-winner
    .modal__main--textwrapper
    img:first-child {
    bottom: -15px;
  }
}

@media (max-width: 479px) {
  .skin_449.linux.page-ml .header__button a,
  .skin_449.ubuntu.page-ml .header__button a,
  .skin_449.windows.page-ml .header__button a {
    font-size: 13px;
  }
}

@media (max-width: 375px) {
  .skin_449.linux.page-ml .header__button a,
  .skin_449.ubuntu.page-ml .header__button a,
  .skin_449.windows.page-ml .header__button a {
    font-size: 7px;
  }
}

.skin_449.linux.page-ml .verification__banner--text h3,
.skin_449.linux.page-ml .verification__banner h2,
.skin_449.ubuntu.page-ml .verification__banner--text h3,
.skin_449.ubuntu.page-ml .verification__banner h2,
.skin_449.windows.page-ml .verification__banner--text h3,
.skin_449.windows.page-ml .verification__banner h2 {
  font-size: 23px;
}

.skin_449.linux.page-ml .verification__banner--text p,
.skin_449.ubuntu.page-ml .verification__banner--text p,
.skin_449.windows.page-ml .verification__banner--text p {
  font-size: 13px;
}

.skin_449.linux.page-ml .verification__banner--text a,
.skin_449.ubuntu.page-ml .verification__banner--text a,
.skin_449.windows.page-ml .verification__banner--text a {
  max-width: 300px;
}

@media (max-width: 479px) {
  .skin_449.linux.page-ml .verification__banner--text a,
  .skin_449.ubuntu.page-ml .verification__banner--text a,
  .skin_449.windows.page-ml .verification__banner--text a {
    font-size: 13px;
  }
}

.skin_449.linux.page-ml .verification--rejected .verification__banner--text a,
.skin_449.ubuntu.page-ml .verification--rejected .verification__banner--text a,
.skin_449.windows.page-ml
  .verification--rejected
  .verification__banner--text
  a {
  font-size: 10px;
}

@media (min-width: 576px) {
  .skin_449.linux.page-ml .number-picker__top,
  .skin_449.ubuntu.page-ml .number-picker__top,
  .skin_449.windows.page-ml .number-picker__top {
    padding: 23px 0 34px 34px;
  }
}

.skin_449.linux.page-ml .number-picker__top--text h2,
.skin_449.ubuntu.page-ml .number-picker__top--text h2,
.skin_449.windows.page-ml .number-picker__top--text h2 {
  font-size: 25px;
}

@media (max-width: 479px) {
  .skin_449.linux.page-ml .number-picker__top--text h2,
  .skin_449.ubuntu.page-ml .number-picker__top--text h2,
  .skin_449.windows.page-ml .number-picker__top--text h2 {
    font-size: 19px;
  }
}

@media (max-width: 385px) {
  .skin_449.linux.page-ml .number-picker__top--text h2,
  .skin_449.ubuntu.page-ml .number-picker__top--text h2,
  .skin_449.windows.page-ml .number-picker__top--text h2 {
    font-size: 17px;
  }
}

.skin_449.linux.page-ml .number-picker__top--text h2 small,
.skin_449.ubuntu.page-ml .number-picker__top--text h2 small,
.skin_449.windows.page-ml .number-picker__top--text h2 small {
  font-size: 25px;
}

@media (max-width: 479px) {
  .skin_449.linux.page-ml .number-picker__top--text h2 small,
  .skin_449.ubuntu.page-ml .number-picker__top--text h2 small,
  .skin_449.windows.page-ml .number-picker__top--text h2 small {
    font-size: 19px;
  }
}

@media (max-width: 385px) {
  .skin_449.linux.page-ml .number-picker__top--text h2 small,
  .skin_449.ubuntu.page-ml .number-picker__top--text h2 small,
  .skin_449.windows.page-ml .number-picker__top--text h2 small {
    font-size: 17px;
  }
}

@media (min-width: 992px) {
  .skin_449.linux.page-ml .number-picker__top--text h2 span span,
  .skin_449.ubuntu.page-ml .number-picker__top--text h2 span span,
  .skin_449.windows.page-ml .number-picker__top--text h2 span span {
    font-size: 55px;
    line-height: 47px;
  }
}

@media (max-width: 575px) {
  .skin_449.linux.page-ml .number-picker__top--text h2 span span,
  .skin_449.ubuntu.page-ml .number-picker__top--text h2 span span,
  .skin_449.windows.page-ml .number-picker__top--text h2 span span {
    top: -27px;
  }
}

@media (max-width: 479px) {
  .skin_449.linux.page-ml .number-picker__top--text h2 span span,
  .skin_449.ubuntu.page-ml .number-picker__top--text h2 span span,
  .skin_449.windows.page-ml .number-picker__top--text h2 span span {
    top: -24px;
    font-size: 45px;
    right: -42px;
  }
}

@media (max-width: 415px) {
  .skin_449.linux.page-ml .number-picker__top--text h2 span span,
  .skin_449.ubuntu.page-ml .number-picker__top--text h2 span span,
  .skin_449.windows.page-ml .number-picker__top--text h2 span span {
    right: -42px;
  }
}

@media (max-width: 385px) {
  .skin_449.linux.page-ml .number-picker__top--text h2 span span,
  .skin_449.ubuntu.page-ml .number-picker__top--text h2 span span,
  .skin_449.windows.page-ml .number-picker__top--text h2 span span {
    font-size: 35px;
    right: -33px;
    top: -18px;
  }
}

@media (min-width: 576px) {
  .skin_449.linux.page-ml .number-picker__top--img,
  .skin_449.ubuntu.page-ml .number-picker__top--img,
  .skin_449.windows.page-ml .number-picker__top--img {
    bottom: -93px;
  }
}

.skin_449.linux.page-ml .number-picker__picker--name p,
.skin_449.ubuntu.page-ml .number-picker__picker--name p,
.skin_449.windows.page-ml .number-picker__picker--name p {
  font-size: 17px;
}

@media (max-width: 385px) {
  .skin_449.linux.page-ml .number-picker__picker--name p,
  .skin_449.ubuntu.page-ml .number-picker__picker--name p,
  .skin_449.windows.page-ml .number-picker__picker--name p {
    font-size: 12px;
  }
}

.skin_449.linux.page-ml .number-picker__picker--name span,
.skin_449.ubuntu.page-ml .number-picker__picker--name span,
.skin_449.windows.page-ml .number-picker__picker--name span {
  font-size: 17px;
}

@media (max-width: 385px) {
  .skin_449.linux.page-ml .number-picker__picker--name span,
  .skin_449.ubuntu.page-ml .number-picker__picker--name span,
  .skin_449.windows.page-ml .number-picker__picker--name span {
    font-size: 12px;
  }
}

.skin_449.linux.page-ml .number-picker.play .number-picker__bottle,
.skin_449.ubuntu.page-ml .number-picker.play .number-picker__bottle,
.skin_449.windows.page-ml .number-picker.play .number-picker__bottle {
  right: 120px;
}

.skin_449.linux.page-ml .number-picker.play .number-picker__picker--name p,
.skin_449.ubuntu.page-ml .number-picker.play .number-picker__picker--name p,
.skin_449.windows.page-ml .number-picker.play .number-picker__picker--name p {
  font-size: 13px;
}

@media (min-width: 992px) {
  .skin_449.linux.page-ml .hp-navigation__box.box-total,
  .skin_449.ubuntu.page-ml .hp-navigation__box.box-total,
  .skin_449.windows.page-ml .hp-navigation__box.box-total {
    padding: 37px 16px;
  }
}

.skin_449.linux.page-ml
  .hp-navigation__box.box-total
  .hp-navigation__box--content
  h2,
.skin_449.ubuntu.page-ml
  .hp-navigation__box.box-total
  .hp-navigation__box--content
  h2,
.skin_449.windows.page-ml
  .hp-navigation__box.box-total
  .hp-navigation__box--content
  h2 {
  font-size: 25px;
}

@media (min-width: 576px) {
  .skin_449.linux.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2,
  .skin_449.ubuntu.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2,
  .skin_449.windows.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2 {
    font-size: 13px;
  }
}

@media (max-width: 385px) {
  .skin_449.linux.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2,
  .skin_449.ubuntu.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2,
  .skin_449.windows.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2 {
    font-size: 25px;
  }
}

.skin_449.linux.page-ml
  .hp-navigation__box.box-total
  .hp-navigation__box--content
  h2
  span,
.skin_449.ubuntu.page-ml
  .hp-navigation__box.box-total
  .hp-navigation__box--content
  h2
  span,
.skin_449.windows.page-ml
  .hp-navigation__box.box-total
  .hp-navigation__box--content
  h2
  span {
  font-size: 35px;
  line-height: 62px;
  margin-bottom: -14px;
}

@media (min-width: 576px) {
  .skin_449.linux.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    span,
  .skin_449.ubuntu.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    span,
  .skin_449.windows.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    span {
    font-size: 14px;
    line-height: 55px;
    margin-bottom: -12px;
  }
}

@media (max-width: 385px) {
  .skin_449.linux.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    span,
  .skin_449.ubuntu.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    span,
  .skin_449.windows.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    span {
    font-size: 30px;
  }
}

.skin_449.linux.page-ml
  .hp-navigation__box.box-total
  .hp-navigation__box--content
  h2
  small,
.skin_449.ubuntu.page-ml
  .hp-navigation__box.box-total
  .hp-navigation__box--content
  h2
  small,
.skin_449.windows.page-ml
  .hp-navigation__box.box-total
  .hp-navigation__box--content
  h2
  small {
  font-size: 21px;
}

@media (min-width: 576px) {
  .skin_449.linux.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    small,
  .skin_449.ubuntu.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    small,
  .skin_449.windows.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    small {
    font-size: 14px;
  }
}

@media (max-width: 479px) {
  .skin_449.linux.page-ml
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading,
  .skin_449.ubuntu.page-ml
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading,
  .skin_449.windows.page-ml
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading {
    margin-bottom: 0;
  }
}

.skin_449.linux.page-ml
  .hp-navigation__box.box-stories
  .hp-navigation__box--heading
  h2,
.skin_449.ubuntu.page-ml
  .hp-navigation__box.box-stories
  .hp-navigation__box--heading
  h2,
.skin_449.windows.page-ml
  .hp-navigation__box.box-stories
  .hp-navigation__box--heading
  h2 {
  font-size: 20px;
}

.skin_449.linux.page-ml
  .hp-navigation__box.box-stories
  .hp-navigation__box--heading
  h2
  span,
.skin_449.ubuntu.page-ml
  .hp-navigation__box.box-stories
  .hp-navigation__box--heading
  h2
  span,
.skin_449.windows.page-ml
  .hp-navigation__box.box-stories
  .hp-navigation__box--heading
  h2
  span {
  font-size: 30px;
  right: 38px;
  bottom: -8px;
}

@media (max-width: 479px) {
  .skin_449.linux.page-ml
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading
    h2
    span,
  .skin_449.ubuntu.page-ml
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading
    h2
    span,
  .skin_449.windows.page-ml
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading
    h2
    span {
    line-height: 53px;
  }
}

@media (min-width: 576px) {
  .skin_449.linux.page-ml
    .hp-navigation__box.box-stories
    .hp-navigation__box--video,
  .skin_449.ubuntu.page-ml
    .hp-navigation__box.box-stories
    .hp-navigation__box--video,
  .skin_449.windows.page-ml
    .hp-navigation__box.box-stories
    .hp-navigation__box--video {
    margin-bottom: 6px;
  }
}
.skin_449.linux.page-ml .hp-navigation__box.box-results h3,
.skin_449.ubuntu.page-ml .hp-navigation__box.box-results h3,
.skin_449.windows.page-ml .hp-navigation__box.box-results h3 {
  font-size: 30px;
}

@media (max-width: 385px) {
  .skin_449.linux.page-ml .hp-navigation__box.box-results h3,
  .skin_449.ubuntu.page-ml .hp-navigation__box.box-results h3,
  .skin_449.windows.page-ml .hp-navigation__box.box-results h3 {
    font-size: 25px;
  }
}

.skin_449.linux.page-ml .hp-navigation__date p,
.skin_449.ubuntu.page-ml .hp-navigation__date p,
.skin_449.windows.page-ml .hp-navigation__date p {
  font-size: 17px;
}

@media (max-width: 385px) {
  .skin_449.linux.page-ml .hp-navigation__date p,
  .skin_449.ubuntu.page-ml .hp-navigation__date p,
  .skin_449.windows.page-ml .hp-navigation__date p {
    font-size: 11px;
  }
}
.skin_449.linux.page-ml
  .modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-input,
.skin_449.ubuntu.page-ml
  .modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-input,
.skin_449.windows.page-ml
  .modal--numberpicker
  .modal__selectboxes--select
  .selectize
  .selectize-input {
  font-size: 7px !important;
}

.skin_449.linux.page-ml
  .modal--344-winner
  .modal__main--textwrapper
  img:first-child,
.skin_449.ubuntu.page-ml
  .modal--344-winner
  .modal__main--textwrapper
  img:first-child,
.skin_449.windows.page-ml
  .modal--344-winner
  .modal__main--textwrapper
  img:first-child {
  bottom: -25px;
}

@media (min-width: 480px) {
  .skin_449.linux.page-ml .modal--344-winner .modal__main--inner h2,
  .skin_449.ubuntu.page-ml .modal--344-winner .modal__main--inner h2,
  .skin_449.windows.page-ml .modal--344-winner .modal__main--inner h2 {
    font-size: 40px;
  }
}

.skin_449.linux.page-ml .modal--344-winner .modal__main--inner h2 span,
.skin_449.ubuntu.page-ml .modal--344-winner .modal__main--inner h2 span,
.skin_449.windows.page-ml .modal--344-winner .modal__main--inner h2 span {
  font-size: 30px;
}

@media (max-width: 479px) {
  .skin_449.linux.page-ml .modal--344-winner .modal__main--inner h2 span,
  .skin_449.ubuntu.page-ml .modal--344-winner .modal__main--inner h2 span,
  .skin_449.windows.page-ml .modal--344-winner .modal__main--inner h2 span {
    font-size: 23px;
  }
}

@media (max-width: 400px) {
  .skin_449.linux.page-ml .modal--344-winner .modal__main--inner h2 span,
  .skin_449.ubuntu.page-ml .modal--344-winner .modal__main--inner h2 span,
  .skin_449.windows.page-ml .modal--344-winner .modal__main--inner h2 span {
    font-size: 20px;
  }
}

.skin_449.linux.page-ml .modal--344-winner .modal__footer a,
.skin_449.linux.page-ml .modal--344-winner .modal__footer p,
.skin_449.ubuntu.page-ml .modal--344-winner .modal__footer a,
.skin_449.ubuntu.page-ml .modal--344-winner .modal__footer p,
.skin_449.windows.page-ml .modal--344-winner .modal__footer a,
.skin_449.windows.page-ml .modal--344-winner .modal__footer p {
  font-size: 17px;
}

.skin_449.linux.page-ml .modal--344-rp h1,
.skin_449.ubuntu.page-ml .modal--344-rp h1,
.skin_449.windows.page-ml .modal--344-rp h1 {
  font-size: 12px;
}

@media (max-width: 370px) {
  .skin_449.linux.page-ml .modal--344-rp h1,
  .skin_449.ubuntu.page-ml .modal--344-rp h1,
  .skin_449.windows.page-ml .modal--344-rp h1 {
    font-size: 10px;
  }
}

.skin_449.linux.page-ml .modal--344-rp h1 span,
.skin_449.ubuntu.page-ml .modal--344-rp h1 span,
.skin_449.windows.page-ml .modal--344-rp h1 span {
  margin-top: 10px;
  padding: 10px 10px 5px;
}

.skin_449.linux.page-ml .modal--344-rp .modal__main--text p,
.skin_449.ubuntu.page-ml .modal--344-rp .modal__main--text p,
.skin_449.windows.page-ml .modal--344-rp .modal__main--text p {
  font-size: 11px;
}

@media (max-width: 370px) {
  .skin_449.linux.page-ml .modal--344-rp .modal__main--text p,
  .skin_449.ubuntu.page-ml .modal--344-rp .modal__main--text p,
  .skin_449.windows.page-ml .modal--344-rp .modal__main--text p {
    font-size: 9px;
  }
}

.skin_449.linux.page-ml .modal--344-rp .modal__footer a,
.skin_449.ubuntu.page-ml .modal--344-rp .modal__footer a,
.skin_449.windows.page-ml .modal--344-rp .modal__footer a {
  font-size: 10px;
}

@media (max-width: 370px) {
  .skin_449.linux.page-ml .modal--344-rp .modal__footer a,
  .skin_449.ubuntu.page-ml .modal--344-rp .modal__footer a,
  .skin_449.windows.page-ml .modal--344-rp .modal__footer a {
    font-size: 8px;
  }
}

@media (min-width: 480px) {
  .skin_449.linux .modal--344-winner .modal__main--inner h2 span,
  .skin_449.ubuntu .modal--344-winner .modal__main--inner h2 span,
  .skin_449.windows .modal--344-winner .modal__main--inner h2 span {
    line-height: 40px;
  }
}

.skin_449.android.page-rtl
  .hp-navigation__box.box-stories
  .hp-navigation__box--heading
  h2
  span,
.skin_449.linux.page-rtl
  .hp-navigation__box.box-stories
  .hp-navigation__box--heading
  h2
  span {
  bottom: -5px;
}

.skin_449.page-rtl .priceproduct1 {
  max-width: 175px;
}

.skin_449.page-rtl .hp-navigation__box.box-total {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  flex-wrap: wrap;
}

.skin_449.page-rtl .previous-drawn__text h2 {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.skin_449.page-rtl .previous-drawn__text h2 span {
  margin-left: 10px;
}

.skin_449.page-rtl .previous-drawn__text .heading_sec {
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.skin_449.page-rtl .hp-navigation__info--box.small p:nth-child(2),
.skin_449.page-rtl .previous-drawn__text .heading_sec {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  display: -webkit-box;
  display: flex;
}

.skin_449.page-rtl .hp-navigation__info--box.small p:nth-child(2) span {
  margin-right: 7px;
  margin-left: 0;
}

.skin_449.page-rtl .hp-navigation__info--box .mtch {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.skin_449.page-rtl .number-picker.homepage .number-picker--btn {
  padding-top: 3px;
}

.skin_449.page-rtl .previous-drawn .table-main table tr th {
  padding: 10px 18px 10px 19px;
}

@media (max-width: 380px) {
  .skin_449.page-rtl .previous-drawn .table-main table thead tr th {
    font-size: 14px;
  }
}

.skin_449.page-rtl .previous-drawn .table-main table thead tr th:nth-child(2) {
  text-align: right;
  padding: 15px 13px 10px 0;
}

@media (max-width: 420px) {
  .skin_449.page-rtl .ticket-page .ticket__img--img {
    width: 210px;
  }
}

.skin_449 .ticket-page .ticket__img {
  background-image: none;
  width: unset;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}

.skin_449 .ticket-page .ticket__img--img {
  background-image: url(../img/Iraq-web_ticket-logo.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  width: 300px;
  max-width: 100%;
  padding-bottom: 58px;
  margin: 35px 0 0;
}

@media (max-width: 420px) {
  .skin_449 .ticket-page .ticket__img--img {
    width: 210px;
  }
}

.skin_449 .ticket-page .ticket__img h1 {
  color: var(--iq-pink);
  font-size: 27px;
}

@media (max-width: 420px) {
  .skin_449 .ticket-page .ticket__img h1 {
    font-size: 22px;
  }
}

.skin_449 .ticket-page .ticket--wrapper {
  background-repeat: no-repeat;
}

.m-b-0 {
  margin-bottom: 0;
}

.page-heading {
  font-size: 19px !important;
}

.draw-mobile-data-div {
  border: 1px solid var(--iq-orange);
  border-top: none;
}

.draw-mobile-heading-div {
  border: 1px solid var(--iq-orange);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px 0px;
  text-align: center;
}

.draw-mobile-heading-div-font {
  font-size: 17px !important;
}

.active-div {
  background-color: var(--iq-orange);
}

@media (max-width: 767px) {
  .draw-mobile-heading-div-font {
    font-size: 14px !important;
  }

  .draw-mobile-data-div .flex.space-x-2 span {
    height: 28px !important;
    width: 28px !important;
    line-height: 21px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 2px !important;
  }
}

@media (max-width: 575px) {
  #form_add-favourite input,
  #form_add-favourite input::placeholder {
    font-size: 13px;
    height: 40px;
  }
  .draw-mobile-data-div .row.row_padding p,
  .draw-mobile-data-div .row.row_padding a,
  .draw-mobile-data-div .row.row_padding span {
    font-size: 13px !important;
  }

  .draw-mobile-data-div #upcomingDraw .col-5.space-y-2 {
    max-width: 200px !important;
  }
  .draw-mobile-data-div #upcomingDraw .col-5.space-y-2 p {
    line-height: 28px;
  }

  #form_add-favourite input {
    padding: 10px 15px;
  }

  .error-text p,
  .remodal form .form-group__buttons .btn {
    font-size: 12px;
  }

  .remodal form .form-group__buttons .btn {
    height: 35px;
    padding: 10px;
  }

  .header__inner {
    padding: 15px 0;
  }

  .page-heading {
    font-size: 16px !important;
  }
}
@media (max-width: 400px) {
  .draw-mobile-data-div .row.row_padding p,
  .draw-mobile-data-div .row.row_padding a,
  .draw-mobile-data-div .row.row_padding span {
    font-size: 12px !important;
  }
}
